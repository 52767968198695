import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Card,
  CardContent,
  IconButton,
  Select,
  FormControl,
  TextField,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {  useNavigate } from "react-router-dom";
import {
  AddCircleOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  addSolutionBenefit,
  addSolutionKeyFeature,
  removeSolutionBenefit,
  removeSolutionKeyFeature,
  resetProfile,
  setOverview,
  setShortOverview,
  setTrialAvailability,
  updateSolutionBenefit,
  updateSolutionKeyFeature,
} from "../../redux/features/SolutionProvider/editSolutionSlice";
import shortid from "shortid";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { setRefetchMySolutionList } from "../../redux/features/refetch";
import { useUpdateSolutionDetailMutation } from "../../redux/api/SolutionProvider/MySolution/updateSolution";

const EditSolutionSecondStep = ({ prevStep, showToastMessage }) => {
  const userId = localStorage.getItem("userId");
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    shortOverview,
    overview,
    trailAvailability,
    solutionName,
    solutionTagline,
    solutionWebUrl,
    headquarter,
    industry,
    tech,
    useCase,
    logo,
    selectedAvatar,
    priceRange,
  } = useSelector((state) => state.editSolution);

  const solutionBenefits = useSelector(
    (state) => state.editSolution.solutionBenefits || [] // Ensure it's always an array
  );
  const solutionKeyFeatures = useSelector(
    (state) => state.editSolution.solutionKeyFeatures || [] // Ensure it's always an array
  );
  const { selectedSolutionId } = useSelector((state) => state.editSolution);
  // validation state
  const [errors, setErrors] = useState({
    shortOverview: false,
    overview: false,
    trailAvailability: false,
    solutionBenefits: false,
    solutionKeyFeatures: false,
  });

  //   api's

  const [
    editSolution,
    { isLoading: editSolutionLoading, isError: editSolutionError },
  ] = useUpdateSolutionDetailMutation();

  // Function to handle short overview
  const handleShortOverview = (event) => {
    const inputValue = event.target.value;
    dispatch(setShortOverview(inputValue));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, shortOverview: false }));
    }
  };
  // Function to handle  overview
  const handleOverview = (event) => {
    const inputValue = event.target.value;
    dispatch(setOverview(inputValue));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, overview: false }));
    }
  };

  //  Function to handle trial availability
  const handleTrialAvailability = (event) => {
    const inputValue = event.target.value;
    dispatch(setTrialAvailability(inputValue));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, trailAvailability: false }));
    }
  };


  const handleAddBenefit = () => {
    dispatch(addSolutionBenefit()); // Add a new empty benefit field
  };
  const handleRemoveBenefit = (id) => {
    dispatch(removeSolutionBenefit(id));
  };

  const handleBenefitChange = (id, event) => {
    dispatch(updateSolutionBenefit({ id, value: event.target.value }));
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, solutionBenefits: false }));
    }
  };

  const handleAddKeyFeature = () => {
    dispatch(addSolutionKeyFeature());
  };

  const handleRemoveKeyFeature = (id) => {
    dispatch(removeSolutionKeyFeature(id));
  };

  const handleKeyFeatureChange = (id, event) => {
    dispatch(updateSolutionKeyFeature({ id, value: event.target.value })); // Update a key feature's value
    if (event.target.value !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        solutionKeyFeatures: false,
      }));
    }
  };

  const goBackToMySolutionList = () => {
    dispatch(resetProfile());
    navigate("/my-solutions");
  };

  const editExistingSolution = async () => {
    const newErrors = {
      shortOverview: shortOverview.trim() === "",
      overview: overview.trim() === "",
      trailAvailability: trailAvailability.trim() === "",
      solutionBenefits:
        solutionBenefits.length === 0 ||
        solutionBenefits.every((item) => item.value.trim() === ""),
      solutionKeyFeatures:
        solutionKeyFeatures.length === 0 ||
        solutionKeyFeatures.every((item) => item.value.trim() === ""),
    };

    setErrors(newErrors);

    // Check if there are any errors before proceeding
    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    const formData = new FormData();
    // Append other user details to FormData

    industry.forEach((item, index) => {
      formData.append(`industry[${index}]`, item);
    });

    industry.forEach((item, index) => {
      formData.append(`focus_industry[${index}]`, item);
    });
    industry.forEach((item, index) => {
      formData.append(`industry_ids[${index}]`, item);
    });
    useCase.forEach((item, index) => {
      formData.append(`use_case_ids[${index}]`, item);
    });

    solutionBenefits.forEach((item, index) => {
      formData.append(`benefits[${index}]`, item.value);
    });

    solutionKeyFeatures.forEach((item, index) => {
      formData.append(`key_features[${index}]`, item.value);
    });

    tech.forEach((item, index) => {
      formData.append(`tech_ids[${index}]`, item);
    });
    if (logo && (logo instanceof File || logo instanceof Blob)) {
      formData.append("logo", logo);
    }
    formData.append("solution_name", solutionName);
    formData.append("tagline", solutionTagline);
    //  formData.append("focus_industry", industry);
    //  formData.append("user_case", useCase);
    formData.append("head_quarters", headquarter);
    formData.append("short_overview", shortOverview);
    formData.append("overview", overview);
    //  formData.append("benefits", solutionBenefits);
    formData.append("price_range", priceRange);
    formData.append("trial", trailAvailability);
    formData.append("source_link", solutionWebUrl);

    try {
      const response = await editSolution({
        userId,
        selectedSolutionId,
        formData,
      }).unwrap();
      const successMessage = response?.message || "Solution edited";
      showToastMessage(successMessage, "success");
      dispatch(setRefetchMySolutionList(true));
      if (response?.statusCode === 500) {
        showToastMessage(successMessage, "error");
      }
    } catch (editSolutionError) {
      const errorMessage = editSolutionError?.message || "Failed to edit";
      showToastMessage(errorMessage, "error");
    } finally {
      goBackToMySolutionList();
    }
  };

  useEffect(() => {
    if (editSolutionLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [editSolutionLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            py: 0,
            px: 0,
            borderBottom: "solid 1px #DBEBF8",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={prevStep}
            sx={{
              marginRight: "5px",
              display: "inline-flex",
              p: 0,
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "32px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "20px",
                },
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: "1.4rem",
              fontWeight: "700",
              pr: 0.7,
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            Edit Solution
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#A2C2F1",
              fontSize: "1rem",
              fontWeight: "600",
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            (2/2)
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        //
        columnSpacing={2}
        sx={{
          // p:2,
          mt: 1,
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up("xl")]: {
            // p: 10,
            mt: 8,
          },
        }}
      >
        <Grid item xs={12} md={11} lg={11}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              mb: 3,
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                p: 4,
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
              }}
              style={{
                paddingBottom: "0",
              }}
            >
              <Box
                component="form"
                sx={{
                  mt: 0,
                  mb: 0,
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={5}
                  sx={{
                    mb: 1,
                  }}
                >
                  {/* Parent Grid for Solution Benefits and Key Features */}
                  <Grid container item xs={12} spacing={4}>
                    {/* Solution Benefit Column */}
                    <Grid item xs={12} md={6}>
                      {solutionBenefits.map((benefit, index) => (
                        <Grid
                          item
                          xs={12}
                          key={benefit.id}
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "secondary.main",
                              fontWeight: "bold",
                              width: "100%",
                              mb: 0.5,
                            }}
                          >
                            Solution Benefit {index + 1}*
                          </Typography>
                          <TextField
                            hiddenLabel
                            variant="filled"
                            name={`solutionBenefit-${index}`}
                            value={benefit.value}
                            onChange={(e) => handleBenefitChange(benefit.id, e)}
                            InputProps={{
                              endAdornment:
                                index === 0 ? (
                                  <IconButton onClick={handleAddBenefit}>
                                    <AddCircleOutline
                                      sx={{ color: "secondary.main" }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveBenefit(benefit.id)
                                    }
                                    color="error"
                                  >
                                    <RemoveCircleOutline />
                                  </IconButton>
                                ),
                            }}
                            style={{
                              margin: 0,
                              width: "100%",
                            }}
                            sx={{
                              width: "100%",
                              borderRadius: "4px",
                              backgroundColor: "extraLTheme.main",
                              border: "solid 1px #DBEBF8",
                              m: 0,
                              "& .MuiInputBase-root": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "0.9rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                pt: 1.5,
                                pb: 1.5,
                                backgroundColor: "extraLTheme.main",
                              },
                              "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                                {
                                  border: "none",
                                },
                              "& .MuiInputBase-root:hover:before": {
                                border: "none",
                              },
                              "&:hover": {
                                borderColor: "#DBEBF8",
                                backgroundColor: "extraLTheme.main",
                              },
                              "& .MuiInputBase-root:before": {
                                border: "none",
                              },
                              "& .MuiInputBase-root:after": {
                                border: "none",
                              },
                            }}
                          />
                          {errors.solutionBenefits && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{ fontSize: "12px", mt: 0.5 }}
                            >
                              This field is required.
                            </Typography>
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    {/* Key Feature Column */}
                    <Grid item xs={12} md={6}>
                      {solutionKeyFeatures.map((feature, index) => (
                        <Grid
                          item
                          xs={12}
                          key={feature.id}
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "secondary.main",
                              fontWeight: "bold",
                              width: "100%",
                              mb: 0.5,
                            }}
                          >
                            Key Feature {index + 1}*
                          </Typography>
                          <TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            variant="filled"
                            name={`keyFeature-${index}`}
                            value={feature.value}
                            onChange={(e) =>
                              handleKeyFeatureChange(feature.id, e)
                            }
                            style={{
                              margin: 0,
                              width: "100%",
                            }}
                            InputProps={{
                              endAdornment:
                                index === 0 ? (
                                  <IconButton onClick={handleAddKeyFeature}>
                                    <AddCircleOutline
                                      sx={{ color: "secondary.main" }}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveKeyFeature(feature.id)
                                    }
                                    color="error"
                                  >
                                    <RemoveCircleOutline />
                                  </IconButton>
                                ),
                            }}
                            sx={{
                              width: "100%",
                              borderRadius: "4px",
                              backgroundColor: "extraLTheme.main",
                              border: "solid 1px #DBEBF8",
                              m: 0,
                              "& .MuiInputBase-root": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "0.9rem",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                pt: 1.5,
                                pb: 1.5,
                                backgroundColor: "extraLTheme.main",
                              },
                              "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                                {
                                  border: "none",
                                },
                              "& .MuiInputBase-root:hover:before": {
                                border: "none",
                              },
                              "&:hover": {
                                borderColor: "#DBEBF8",
                                backgroundColor: "extraLTheme.main",
                              },
                              "& .MuiInputBase-root:before": {
                                border: "none",
                              },
                              "& .MuiInputBase-root:after": {
                                border: "none",
                              },
                            }}
                          />
                          {errors.solutionKeyFeatures && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{ fontSize: "12px", mt: 0.5 }}
                            >
                              This field is required.
                            </Typography>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {/* Short Overview */}
                  <Grid item xs={12} md={6} lg={12} xl={12}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Short Overview*
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={shortOverview}
                      onChange={handleShortOverview}
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                    />
                    {errors.shortOverview && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 0.5 }}
                      >
                        This field is required.
                      </Typography>
                    )}
                  </Grid>
                  {/* Overview  */}
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Overview*
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={overview}
                      onChange={handleOverview}
                      required
                      multiline
                      rows={3}
                      maxRows={3}
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiFilledInput-root": {
                          backgroundColor: "extraLTheme.main", // Set main background color
                          borderRadius: "4px", // Ensure corners are aligned
                          "&:hover": {
                            backgroundColor: "extraLTheme.main",
                          },
                          "&::before, &::after": {
                            borderBottom: "none", // Remove underline completely
                          },
                          "&.Mui-focused": {
                            backgroundColor: "extraLTheme.main", // Ensure consistency when focused
                            border: "1px solid transparent", // Retain border
                          },
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          // pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                    />
                    {errors.overview && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 0.5 }}
                      >
                        This field is required.
                      </Typography>
                    )}
                  </Grid>
                  {/* Trial Availability */}
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Trial Availability*
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={trailAvailability}
                        onChange={handleTrialAvailability}
                        displayEmpty
                        IconComponent={KeyboardArrowDownOutlinedIcon}
                        sx={{
                          background: "#ECF6FF",
                          "& .MuiSelect-select": {
                            minHeight: "20px",
                            padding: "11px 14px",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "0.9rem",
                            border: "solid 1px #DBEBF8",
                          },
                          "& .MuiSelect-icon": {
                            color: "lightSecondary.main",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: "300px",
                              overflowY: "auto",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#9C9C9C",
                                borderRadius: "4px",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.trailAvailability && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: "12px", mt: 0.5 }}
                      >
                        This field is required.
                      </Typography>
                    )}
                  </Grid>

                  {/* Submit Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      textAlign: "center",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={editExistingSolution}
                      sx={{
                        textTransform: "inherit",
                        p: "10px 20px",
                        boxShadow: "none",
                        fontWeight: "600",
                        width: "200px",
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EditSolutionSecondStep;
