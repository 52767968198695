import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { store } from '../../store/store';

const baseUrl = process.env.REACT_APP_base_URL;

export const subscriptionBaseApis = createApi({
    reducerPath: 'subscriptionBaseApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            const token = store.getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
         // ─── GET ───

        // pricingTableDetail
        fetchPricingTableDetail: builder.query({
            query: () => ({
                url: `user/pricing-table-detail`,
                method: 'GET',
            }),
            transformResponse: (response) => {
                if (!response || !response.products) return [];

                const transformPlans = (userType) => {
                    const plans = response.products.filter(
                        (product) => product.metadata.user_type === userType
                    ).map((product) => {
                        const monthlyPrice = product.prices.find(
                            (price) => price.recurring.interval === 'month'
                        )?.unit_amount;
                        
                        const yearlyPrice = product.prices.find(
                            (price) => price.recurring.interval === 'year'
                        )?.unit_amount;

                        return {
                            productId: product.id,
                            planName: product.name,
                            title: product.name,
                            price: {
                                monthly: monthlyPrice ? (monthlyPrice / 100).toFixed(2) : '0',
                                yearly: yearlyPrice ? (yearlyPrice / 100).toFixed(2) : '0',
                            },
                            buttonText: "Continue",
                            features: product.marketing_features.map(feature => feature.name),
                            trialPeriod: product.metadata.trial_period_days,
                        };
                    });

                    return {
                        monthly: plans.map(plan => ({
                            ...plan,
                            price: plan.price.monthly,
                            tenure: "month"
                        })),
                        yearly: plans.map(plan => ({
                            ...plan,
                            price: plan.price.yearly,
                            tenure: "year"
                        }))
                    };
                };

                return {
                    businessUserPlans: transformPlans('business_user'),
                    solutionProviderPlans: transformPlans('solution_provider')
                };
            },
            transformErrorResponse: (response) => {
                return response.data ? response.data : [];
            },
            refetchOnWindowFocus: true,
            onError: (error) => {
                console.error('Fetch pricing table details error:', error);
            },
        }),
        

         // ─── POST ───
        // manageAllSubscriptionMethod
        manageAllSubscriptionPlan: builder.mutation({
            query: ({  customerId  }) => ({
                url: `user/create-new-customer-portal`,
                method: 'POST',
                body: {
                    customerId: customerId,
                }
            }),
            transformResponse: (response) => {
            return response
            },
        }),
        
        // manageSubscriptionForJustUpdate
        manageSubscriptionPlan: builder.mutation({
            query: ({  customerId , userType  }) => ({
                url: `user/create-customer-portal`,
                method: 'POST',
                body: {
                    customerId: customerId,
                }
            }),
            transformResponse: (response) => {
            return response
            },
        }),

        // upgradePlan
        upgradeSubscriptionPlan: builder.mutation({
            query: ({   productId ,planType , planPrize , isTrial }) => ({
                url: `user/create-checkout-session`,
                method: 'POST',
                body: {
                    product_id: productId,
                    plan_type: planType,
                    plan_prize: planPrize,
                    is_trial: isTrial
                }
            }),
            transformResponse: (response) => {
            return response
            },
        }),
    })
})

export const { useFetchPricingTableDetailQuery, useManageAllSubscriptionPlanMutation, useManageSubscriptionPlanMutation, useUpgradeSubscriptionPlanMutation } = subscriptionBaseApis;
