import React from "react";
import { Skeleton, Stack, Typography, ListItemButton, ListItemAvatar } from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { useSelector } from "react-redux";

const RecentResponsesSkull = () => {
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <ListItemButton
          key={index}
          sx={{
            minHeight: isDrawerOpen ? '82px' : '68px',
            justifyContent: "space-between",
            alignItems: "center",
            background: "#FFFFFF",
            borderRadius: "10px",
            marginTop: "5px",
          }}
        >
          <Stack
            sx={{
              flexDirection: "inherit",
              alignItems: "center",
              width: "60%",
            }}
          >
            <ListItemAvatar
              sx={{
                display: "inline-block",
                marginRight: "7px",
              }}
            >
              <Skeleton variant="circular" width={40} height={40} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
            </ListItemAvatar>
            <Skeleton variant="text" width="80%" height={20} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
          </Stack>
          <Skeleton variant="text" width="10%" height={20} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
          {/* <Skeleton variant="rectangular" width={20} height={20} /> */}
          <ArrowForwardIosOutlinedIcon
            sx={{
              fill: "grey.500",
              width: "18px",
              height: "18px",
              opacity: 0.5,
            }}
          />
        </ListItemButton>
      ))}
    </>
  );
};

export default RecentResponsesSkull;
