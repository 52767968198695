import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  Typography,
  styled,
  InputBase,
  Stack,
  IconButton,
  Avatar,
  Tooltip,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getImages } from "../const";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { useFetchRequirementBoardDetailsQuery } from "../../redux/api/Requirement Boards/getRequirementBoardDetail";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { resetRefetchTeamMemberList } from "../../redux/features/refetch";
import { useFetchTeamMemberListQuery } from "../../redux/api/Requirement Boards/getInviteListsDetail";
import { useRemoveUserMutation } from "../../redux/api/SolutionProvider/Inbox/removeUser";
import { useAddUserMutation } from "../../redux/api/SolutionProvider/Inbox/addUser";

const ManageUsers = ({showToastMessage , groupData, handleClose , refetch, chatId}) => {
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));
  // const status = "Selected"
  // Searching logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const {
    data: ListData,
    error: MemberListError,
    isLoading: TeamMemberLoading,
    refetch: refetchTeamMemberList,
  } = useFetchTeamMemberListQuery({
    searchParams: searchValue,
  });
  const refetchTeamMembers = useSelector(
    (state) => state.refetch.refetchTeamMemberList
  );
  useEffect(() => {
    if (refetchTeamMembers) {
      refetchTeamMemberList().finally(() => {
        dispatch(resetRefetchTeamMemberList());
      });
    }
  }, [refetchTeamMembers, refetchTeamMemberList, dispatch]);

  const handleTeamMemberSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetchTeamMemberList();
        return;
      }

      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        // Start loading
        await refetchTeamMemberList(); 
        if (MemberListError || ListData.statusCode === 404) {
          const errorMessage = "No result found";
          showToastMessage(errorMessage, "error");
        }
      } catch (MemberListError) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetchTeamMemberList(); // Refetch all data
  };

//   const selectedSolutionId = (solutionId, solutionName) => {
//     localStorage.setItem("selectedSolutionId", solutionId);
//     dispatch(getSolutionName(solutionName));
//   };

const [addUser, { isLoading:addUserLoading, isError: addUserError }] = useAddUserMutation();
const addUsers = async (user_id) => {
  try {
    const response = await addUser({chatId , user_id}).unwrap();
    const successMessage = response?.message || 'User Removed';
    showToastMessage(successMessage, 'success');
    refetch();
    handleClose();
  } catch (error) {
    const errorMessage = error.message || "Failed to delete item";
    showToastMessage(errorMessage, "error");
  }
};

const [removeUser, { isLoading, isError: deleteError }] = useRemoveUserMutation();
const deleteUser = async (user_id) => {
  try {
    const response = await removeUser({chatId , user_id}).unwrap();
    const successMessage = response?.message || 'User Removed';
    showToastMessage(successMessage, 'success');
    refetch();
    handleClose();
  } catch (error) {
    const errorMessage = error.message || "Failed to delete item";
    showToastMessage(errorMessage, "error");
  }
};



//   useEffect(() => {
//     if (boardDetailLoading) {
//       dispatch(showLoader());
//     } else {
//       dispatch(hideLoader());
//     }
//   }, [boardDetailLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: '1.3rem',
            color: 'secondary.main',
            fontWeight: '700',
            maxWidth: '100%', 
            whiteSpace: 'nowrap', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
          }}
        >
          Manage Users
        </Typography>
      </Box>
      <Search>
        <StyledInputBase
          placeholder="Search Users"
          inputProps={{ "aria-label": "search" }}
          value={searchParams}
          onChange={handleInputChange}
          onKeyDown={handleTeamMemberSearch}
          autoFocus
        />
        {searchParams ? (
          <IconButton
            onClick={handleClearSearch}
            sx={{ position: "absolute", right: 0 }}
          >
            <CancelIcon sx={{ color: "lightSecondary.main" }} />
          </IconButton>
        ) : (
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
          </SearchIconWrapper>
        )}
      </Search>
      {/* <Typography
        variant="h6"
        color="textSecondary"
        sx={{
          fontSize: "1.1rem",
          fontWeight: "700",
          color: "secondary.main",
          marginBottom: "0rem",
        }}
      >
       Add or Remove Collaborator
      </Typography> */}

      <List
        component="nav"
        sx={{
          px: 0,
          py: 0,
          mb: 3,
          "& .MuiListItemButton-root": {
            py: 0.5,
          },
          overflowY: "auto",
          maxHeight: "280px",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
        }}
      >
        {!ListData ||
        ListData.length > 0 === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1" 
              sx={{
                  color:"black",
                  fontWeight: 500,  
                  marginTop:'20px'
                }}
            >
              No Users Available 
            </Typography>
          </Box>
        ) : (
          ListData.map((user, index) => {
            const isUserInGroup = groupData?.users?.some(
              (existingUser) => existingUser._id === user.user_id
            );
    
            return(
            <Box
              key={user._id}
              sx={{
                minHeight: "50px",
                display:'flex',
                justifyContent: "space-between",
                alignItems: "center",
                background: "#FFFFFF",
                borderRadius: "5px",
                marginTop: "5px",
                "&:hover": {
                  backgroundColor: "transparent",
                  cursor: 'default',
                },
              }}
            >
              <Stack
                sx={{
                  flexDirection: "inherit",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ListItemAvatar
                  sx={{
                    display: "inline-block",
                    marginRight: "7px",
                  }}
                >
                   {user?.avatar ? (
                    <Avatar
                      src={user?.avatar }
                      alt={`${user?.first_name || ""} logo`}
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                        width: '50px',
                        height: '50px'
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        color: '#fff',
                        bgcolor: 'lightSecondary.main',
                        width: '50px',
                        height: '50px'
                      }}
                    >
                       {`${user?.first_name
                        .charAt(0)
                        .toUpperCase()}${user?.last_name
                        .charAt(0)
                        .toUpperCase()}`}
                    </Avatar>
                  )}
                </ListItemAvatar>
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{
                    fontSize: "1.1rem",
                    color: "lightSecondary.main",
                    fontWeight: "600",
                    lineHeight: "20px",
                    maxWidth: "260px",
                  }}
                >
                 <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
                    {user?.first_name} {user?.last_name}
                </Tooltip>
                </Typography>
              </Stack>
              <Box
            display="flex"
            gap="12px"
            justifyContent="flex-end"
          >
            {isUserInGroup ? (
              <Button
              color="error" 
              variant="contained"  
                sx={{
                  color: "white",
                  minWidth: "70px",
                  maxWidth: "70px",
                  borderRadius: '15px',
                  padding: '4px',
                  textTransform: "none",
                }}
                onClick={() => deleteUser(user.user_id)} 
              >
                Remove
              </Button>
            ) : (
              <Button
               variant="contained"  
                sx={{
                  color: "white",
                  minWidth: "70px",
                  maxWidth: "70px",
                  borderRadius: '15px',
                  padding: '4px',
                  textTransform: "none",
                }}
                onClick={() => addUsers(user.user_id)} // Trigger add user function
              >
                Add
              </Button>
            )}
          </Box>
            </Box>
          )})
        )}
      </List>
    </>
  );
};

export default ManageUsers;
