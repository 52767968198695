import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MainCard from "../MainCard";
import { useTheme } from "@emotion/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Flag from "react-world-flags";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import {
  useFetchBusinessUserDetailsQuery,
  useFetchCityListByIdQuery,
  useFetchCountryListQuery,
  useFetchMasterDetailsQuery,
  useUpdateSolutionProviderProfileMutation,
  useFetchIndustryListQuery,
  useFetchUseCaseListQuery
} from "../../redux/api/Users";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const useStyles = makeStyles({
  datePicker: {
    "& > div.MuiInputBase-root > input": {
      padding: "10px 0 10px 14px !important",
      borderRadius: "4px",
      backgroundColor: "#ECF6FF",
      border: "solid 1px #DBEBF8",
      width: "100%",
      color: "#2A589",
    },
  },
});

const UpdateSolutionProviderProfile = ({ nextStep, showToastMessage }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const storedUserType = localStorage.getItem("userType");
  const [selectedFoundedDate, setSelectedFoundedDate] = useState(dayjs());
  const open = useSelector((state) => state.drawer.open);
  // If storedUserType is null or "null", set businessUserType to "business_user"
  const userId = localStorage.getItem("userId");
  const {
    data: userProfileData,
    isLoading: userLoading,
    isError: userError,
    refetch,
  } = useFetchBusinessUserDetailsQuery(userId);

  const { data } = userProfileData || {};
  const user = data?.user_id || {};
  // businessName
  const [businessName, setBusinessName] = useState(data?.business_name || "");
  const handleBusinessName = (event) => {
    const inputValue = event.target.value.trim();
    setBusinessName(inputValue);
  };
  // businessUrl
  const [businessUrl, setBusinessUrl] = useState(data?.business_url || "");
  const handleBusinessUrl = (event) => {
    const inputValue = event.target.value.trim();
    setBusinessUrl(inputValue);
  };
  // LinkedinUrl
  const [linkedinUrl, setLinkedinUrl] = useState(data?.linkedin_url || "");
  const handleLinkedinUrl = (event) => {
    const inputValue = event.target.value.trim();
    setLinkedinUrl(inputValue);
  };

  // countryCode
  const [countryCode, setCountryCode] = useState(
    data?.country_id?.phone_code || ""
  );
  const handleCountryCode = (event) => {
    const inputValue = event.target.value.trim();
    setCountryCode(inputValue);
  };
  // phoneNumber
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number || "");
  const handlePhoneNumber = (event) => {
    const inputValue = event.target.value.trim();
    setPhoneNumber(inputValue);
  };
  // Role
  const [role, setRole] = useState(data?.company_role || "");
  const handleRole = (event) => {
    const inputValue = event.target.value.trim();
    setRole(inputValue);
  };
  // Head Country
  const [headCountry, setHeadCountry] = useState(data?.country_id?.name || "");
  const [headCountryId, setHeadCountryId] = useState(
    data?.country_id?._id || ""
  );
  // city
  const [cityId, setCityId] = useState(data?.city_id);

  const {
    data: cityData,
    isLoading: cityLoading,
    isError: cityError,
  } = useFetchCityListByIdQuery();

  const {
    data: countryData,
    isLoading: countryLoading,
    isError: countryError,
  } = useFetchCountryListQuery();

  //   const handleCity=(event)=>{
  //     const cityId = event.target.value;
  //    if(selectedCityId === ""){
  //      setCityId(data?.city_id);
  //    }else{
  //      setCityId(cityId);
  //    }
  //  }

  const handleHeadCountry = (event) => {
    const selectedCountry = event.target.value.trim();
    if (selectedCountry === "") {
      setHeadCountry(data?.country_id?.name || "");
      setHeadCountryId(data?.country_id._id || "");
    } else {
      setHeadCountry(selectedCountry);
      const selectedCountryObject = countryData?.data.find(
        (country) => country.name === selectedCountry
      );
      if (selectedCountryObject) {
        setHeadCountryId(selectedCountryObject._id);
      } else {
        setHeadCountryId("");
      }
    }
  };

  // Industry
  const [industry, setIndustry] = useState(data?.industry_type || "");
  const handleIndustry = (event) => {
    const inputValue = event.target.value.trim();
    setIndustry(inputValue);
  };

  const [companySize, setCompanySize] = useState(data?.company_size || "");

  const handleCompanySize = (event) => {
    const inputValue = event.target.value.trim();
    setCompanySize(inputValue);
  };
  // Company Revenue
  const [companyRevenue, setCompanyRevenue] = useState(
    data?.company_revenue_level || ""
  );

  const handleCompanyRevenue = (event) => {
    const inputValue = event.target.value.trim();
    setCompanyRevenue(inputValue);
  };

  // Entity
  const [entityType, setEntityType] = useState(data?.entity_type || "");

  const handleEntityType = (event) => {
    const inputValue = event.target.value.trim();
    setEntityType(inputValue);
  };

  // Investment Stage
  const [investmentStage, setInvestmentStage] = useState(
    data?.investment_stage
  );
  const handleInvestmentStage = (event) => {
    const inputValue = event.target.value.trim();
    setInvestmentStage(inputValue);
  };
  // looking for startup funding
  const [lookingForFunding, setLookingForFunding] = useState(
    data?.looking_for_startup_funding
  );
  const handleLookingForFunding = (event) => {
    const inputValue = event.target.value.trim();
    setLookingForFunding(inputValue);
  };

  // funding amount
  const [fundingAmount, setFundingAmount] = useState(data?.funding_amount);
  const handleFundingAmount = () => {
    const inputValue = event.target.value.trim();
    setFundingAmount(inputValue);
  };
  const [companyFoundedDate, setCompanyFoundedDate] = useState(
    data?.company_founded_date
  );

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  const [
    updateSolutionProviderProfile,
    { isLoading: profileUpdateLoading, isError: profileError },
  ] = useUpdateSolutionProviderProfileMutation();

  const {
    data: IndustryData,
    isLoading: industryLoading,
    error: industryError,
  } = useFetchIndustryListQuery();
  const {
    data: UseCaseData,
    isLoading: useCaseLoading,
    error: useCaseError,
  } = useFetchUseCaseListQuery();
  const {
    data: masterData,
    isLoading: masterLoading,
    error: masterError,
  } = useFetchMasterDetailsQuery();

  const updateProfile = async () => {
    const foundedDate = formatDate(selectedFoundedDate);
    try {
      const response = await updateSolutionProviderProfile({
        profileId: userProfileData?.data._id,
        userId: userId,
        userType: storedUserType,
        businessName: businessName !== "" ? businessName : data?.business_name,
        businessUrl: businessUrl !== "" ? businessUrl : data?.business_url,
        phoneCode:
          countryCode !== "" ? countryCode : data?.country_id?.phone_code,
        countryId: headCountryId !== "" ? headCountryId : data?.country_id._id,
        phoneNumber: phoneNumber !== "" ? phoneNumber : data?.phone_number,
        role: role !== "" ? role : data?.company_role,
        cityId: cityId !== "" ? cityId : data?.city_id,
        companySize: companySize !== "" ? companySize : data?.company_size,
        companyRevenue:
          companyRevenue !== "" ? companyRevenue : data?.company_revenue_level,
        entityType: entityType !== "" ? entityType : data?.entity_type,
        companyFoundedData:
          selectedFoundedDate !== "" ? foundedDate : data?.company_founded_date,
        // fundingAmount: fundingAmount !==""? fundingAmount : fundingAmount,
        // investmentStage: investmentStage !==""?investmentStage: investmentStage,
        lookingForFunding:
          lookingForFunding !== "" ? lookingForFunding : lookingForFunding,
      }).unwrap();

      const successMessage = response?.message || "Profile updated";
      showToastMessage(successMessage, "success");
    } catch (error) {
      const errorMessage = error?.message || "Failed to update";
      showToastMessage(errorMessage, "error");
    }
  };
  useEffect(() => {
    if (data) {
      setBusinessName(data.business_name || "");
      setBusinessUrl(data?.business_url || "");
      setPhoneNumber(data?.phone_number || "");
    }
  }, [data]);

  useEffect(() => {
    if (profileUpdateLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [profileUpdateLoading, dispatch]);

  return (
    <>
      <Box>
        <Box
          sx={{
            // pt: 3,
            maxWidth: "100%",
            margin: "0 auto",
            // mb: 3,
            [theme.breakpoints.up("xl")]: {
              maxWidth: "100vw",
              // marginLeft: "25vw",
            },
          }}
        >
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#FFFFFF",
              border: "none",
              padding: "0",
              boxShadow: "none",
              borderRadius: "10px",
              p: 1,
              [theme.breakpoints.down("md")]: {
                p: 0,
              },
            }}
          >
            {/* <Grid
              container
              direction={{ xs: "column", md: "row", lg: "row", xl: "row" }}
              rowSpacing={2}
              columnSpacing={3}
              sx={{
                mb: 2,
              }}
            >
              <Grid item xs={2} sm={3} md={ open ? 4 : 3} lg={open? 3: 2}  xl={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "flex-start",
                    [theme.breakpoints.down("md")]: {
                      // justifyContent: 'center'
                    },
                  }}
                >
                  <AvatarWithUpload />
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={open? 8 : 9} lg={open? 9: 10}  xl={10}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Business Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={businessName}
                      placeholder="Business Name*"
                      required
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleBusinessName}
                    />
                  </Box>
                  <Box>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Business Url
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={businessUrl}
                      placeholder="Business Website*"
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handleBusinessUrl}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid> */}

            <Grid
              key={data?._id}
              container
              rowSpacing={2}
              columnSpacing={3}
              sx={{
                mb: 2,
              }}
            >
              {/* Business Name */}
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      color: "secondary.main",
                      fontWeight: "bold",
                      width: "100%",
                      mb: 0.5,
                    }}
                  >
                    Business Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    value={businessName}
                    placeholder="Business Name*"
                    required
                    style={{
                      margin: 0,
                      width: "100%",
                    }}
                    sx={{
                      width: "100%",
                      borderRadius: "4px",
                      backgroundColor: "extraLTheme.main",
                      border: "solid 1px #DBEBF8",
                      m: 0,
                      "& .MuiInputBase-root": {
                        backgroundColor: "extraLTheme.main",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "0.9rem",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        pt: 1.5,
                        pb: 1.5,
                        backgroundColor: "extraLTheme.main",
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                        {
                          border: "none",
                        },
                      "& .MuiInputBase-root:hover:before": {
                        border: "none",
                      },
                      "&:hover": {
                        borderColor: "#DBEBF8",
                        backgroundColor: "extraLTheme.main",
                      },
                      "& .MuiInputBase-root:before": {
                        border: "none",
                      },
                      "& .MuiInputBase-root:after": {
                        border: "none",
                      },
                    }}
                    onChange={handleBusinessName}
                  />
                </Box>
              </Grid>

              {/* Business Url */}
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      color: "secondary.main",
                      fontWeight: "bold",
                      width: "100%",
                      mb: 0.5,
                    }}
                  >
                    Business Url
                  </Typography>
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    variant="filled"
                    value={businessUrl}
                    placeholder="Business Website*"
                    style={{
                      margin: 0,
                      width: "100%",
                    }}
                    sx={{
                      width: "100%",
                      borderRadius: "4px",
                      backgroundColor: "extraLTheme.main",
                      border: "solid 1px #DBEBF8",
                      m: 0,
                      "& .MuiInputBase-root": {
                        backgroundColor: "extraLTheme.main",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "0.9rem",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        pt: 1.5,
                        pb: 1.5,
                        backgroundColor: "extraLTheme.main",
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                        {
                          border: "none",
                        },
                      "& .MuiInputBase-root:hover:before": {
                        border: "none",
                      },
                      "&:hover": {
                        borderColor: "#DBEBF8",
                        backgroundColor: "extraLTheme.main",
                      },
                      "& .MuiInputBase-root:before": {
                        border: "none",
                      },
                      "& .MuiInputBase-root:after": {
                        border: "none",
                      },
                    }}
                    onChange={handleBusinessUrl}
                  />
                </Box>
              </Grid>

              {/* Country code and phoneNumber */}
              <Grid item xs={12} md={6} lg={6}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={2}
                  sx={{
                    mb: 0,
                  }}
                >
                  <Grid item xs={4} md={4} lg={4}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Country Code
                    </Typography>
                    {countryData?.data && (
                      <FormControl
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={countryCode}
                          onChange={handleCountryCode}
                          displayEmpty
                          IconComponent={KeyboardArrowDownOutlinedIcon}
                          sx={{
                            background: "#ECF6FF",
                            "& .MuiSelect-select": {
                              minHeight: "20px",
                              padding: "11px 14px",
                              color: "lightSecondary.main",
                              fontWeight: "600",
                              fontSize: "0.9rem",
                              border: "solid 1px #DBEBF8",
                            },
                            "& .MuiSelect-icon": {
                              color: "lightSecondary.main",
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: "200px",
                                overflowY: "auto",
                                "&::-webkit-scrollbar": {
                                  width: "6px",
                                  borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: "transparent",
                                  borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#9C9C9C",
                                  borderRadius: "4px",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value={countryCode} disabled>
                            {data?.country_id?.phone_code || "ph-code*"}
                          </MenuItem>
                          {countryData &&
                            countryData.data.map((country) => (
                              <MenuItem
                                key={country._id}
                                value={country.phone_code}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flag
                                    code={country.iso2}
                                    style={{
                                      width: "20px",
                                      height: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {country.phone_code}
                                </Box>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={8} md={8} lg={8}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "secondary.main",
                        fontWeight: "bold",
                        width: "100%",
                        mb: 0.5,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={phoneNumber}
                      placeholder="phoneNumber"
                      style={{
                        margin: 0,
                        width: "100%",
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "extraLTheme.main",
                        border: "solid 1px #DBEBF8",
                        m: 0,
                        "& .MuiInputBase-root": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.9rem",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          pt: 1.5,
                          pb: 1.5,
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                          {
                            border: "none",
                          },
                        "& .MuiInputBase-root:hover:before": {
                          border: "none",
                        },
                        "&:hover": {
                          borderColor: "#DBEBF8",
                          backgroundColor: "extraLTheme.main",
                        },
                        "& .MuiInputBase-root:before": {
                          border: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          border: "none",
                        },
                      }}
                      onChange={handlePhoneNumber}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Role */}
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Role
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    onChange={handleRole}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={role} disabled>
                      {data?.company_role || "Select Role*"}
                    </MenuItem>
                    {masterData?.masterData?.roles.map(
                      (role, index) =>
                        index === index && (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Country */}
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Country
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={headCountry}
                    onChange={handleHeadCountry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={headCountry} disabled>
                      {data?.country_id?.name || "Select Country*"}
                    </MenuItem>
                    {countryData &&
                      countryData.data.map((country) => (
                        <MenuItem key={country._id} value={country.name}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Flag
                              code={country.iso2}
                              style={{
                                width: "20px",
                                height: "15px",
                                marginRight: "10px",
                              }}
                            />
                            {country.name}
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* City */}
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cityId}
                    onChange={handleCity}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={headCountry} disabled>
                      {data?.city_id || "Select City*"}
                    </MenuItem>
                    {cityList &&
                      cityList?.data.map((city, index) => (
                        <MenuItem key={city._id} value={city.name}>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                           
                            {city.name}
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* Industry */}
              {/* <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={industry}
                    onChange={handleIndustry}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={industry} disabled>
                      {data?.industry_type || "Select Industry*"}
                    </MenuItem>
                    {IndustryData &&
                      IndustryData.data.map((industry) => (
                        <MenuItem key={industry._id} value={industry.name}>
                          {industry.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* company size */}
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Company Size
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companySize}
                    onChange={handleCompanySize}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "300px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={companySize} disabled>
                      {data?.company_size || "Select Company Size*"}
                    </MenuItem>
                    {masterData?.masterData?.companySize.map(
                      (values, index) =>
                        index === index && (
                          <MenuItem key={values} value={values}>
                            {values}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* company Revenue */}
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Company Revenue
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyRevenue}
                    onChange={handleCompanyRevenue}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={companyRevenue} disabled>
                      {data?.company_revenue_level || "Select Company Revenue*"}
                    </MenuItem>
                    {masterData?.masterData?.companyRevenue.map(
                      (revenue, index) =>
                        index === index && (
                          <MenuItem key={revenue} value={revenue}>
                            {revenue}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Select Entity */}
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Entity
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={entityType}
                    onChange={handleEntityType}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={entityType} disabled>
                      {data?.entity_type || "Select Entity Type*"}
                    </MenuItem>
                    {masterData?.masterData?.entityType.map(
                      (entityType, index) =>
                        index === index && (
                          <MenuItem key={entityType} value={entityType}>
                            {entityType}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* Select Founded Date */}
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className="customDatePickerContainer"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Founded Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={selectedFoundedDate}
                    // label="Founded Date"
                    onChange={(date) => setSelectedFoundedDate(date)}
                    className={`${classes.datePicker} selectDate`}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "extraLTheme.main",
                        "& fieldset": {
                          border: "none", // Remove the default border
                        },
                        "&:hover fieldset": {
                          border: "none", // Ensure no border on hover
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", // Ensure no border on focus
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        backgroundColor: "extraLTheme.main", // Icon background color
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {/* Select funding status */}
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    color: "secondary.main",
                    fontWeight: "bold",
                    width: "100%",
                    mb: 0.5,
                  }}
                >
                  Funding Status
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lookingForFunding}
                    onChange={handleLookingForFunding}
                    displayEmpty
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{
                      background: "#ECF6FF",
                      "& .MuiSelect-select": {
                        minHeight: "20px",
                        padding: "11px 14px",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        border: "solid 1px #DBEBF8",
                      },
                      "& .MuiSelect-icon": {
                        color: "lightSecondary.main",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#9C9C9C",
                            borderRadius: "4px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {data?.looking_for_startup_funding ||
                        "Are you raising funds?"}
                    </MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  p: "10px 20px",
                  boxShadow: "none",
                  fontWeight: "600",
                  mt: 4,
                  width: "240px",
                }}
                onClick={updateProfile}
              >
                Save Changes
              </Button>
            </Box>
          </MainCard>
        </Box>
      </Box>
    </>
  );
};

export default UpdateSolutionProviderProfile;
