import React from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MilestoneSkeleton = () => {
  return (
    <Accordion
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        mt: 4,
        "&:first-of-type": {
          marginTop: 0,
          borderRadius: "10px 10px 10px 10px",
          mt: 4,
        },
        "&:last-of-type": {
          marginTop: 0,
          borderRadius: "10px 10px 10px 10px",
          mt: 4,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color:'lightSecondary.main'}} />}
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          {/* Drag and drop icon skeleton */}
          <Tooltip arrow title="Drag Milestone">
            <IconButton sx={{ cursor: "grab" }}>
              <DragIndicatorIcon sx={{ fontSize: "1.1em", color: "lightSecondary.main" }} />
            </IconButton>
          </Tooltip>

          {/* Milestone name skeleton */}
          <Skeleton variant="text" width={150} height={24} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}  />

          {/* Task count skeleton */}
          <Skeleton variant="text" width={80} height={16} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}  />
        </Box>

        <Box>
          {/* Edit Icon skeleton */}
          <Tooltip arrow title="Edit Milestone">
            <IconButton>
              <ModeEditOutlineIcon sx={{ color: "lightSecondary.main" }} />
            </IconButton>
          </Tooltip>

          {/* Add Task Icon skeleton */}
          <Tooltip arrow title="Add Task">
            <IconButton>
              <AddCircleIcon sx={{ fontSize: "1.1em", color: "lightSecondary.main" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </AccordionSummary>
    </Accordion>
  );
};

export default MilestoneSkeleton;
