import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    token: null,
    planName: null, // Add a new field for planName
    businessUserPlanDetail: {}, 
  },
  reducers: {
    getUser: (state, action) => {
      state.data = action.payload;
    },
    getToken: (state, action) => {
      state.token = action.payload;
    },
    setPlanName: (state, action) => {
      // Store the planName from either paid or unPaid subscription
      state.planName = action.payload;
    },
    setBusinessUserPlanDetail: (state, action) => {
      const planValues = action.payload;

      // Ensure planVariables exists before assignment
      if (!state.businessUserPlanDetail) {
        state.businessUserPlanDetail = {};
      }else{
      // Save each plan value dynamically
      planValues.forEach((item) => {
        state.businessUserPlanDetail[item.key] = item.value;
      });
      }

     
    },
    clearUserData: (state) => {
      // Store the planName from either paid or unPaid subscription
      state.data = null;
      state.token = null;
      state.planName = null;
      state.businessUserPlanDetail = null;
    }
  }
});
export const { getUser, getToken, clearUserData, setPlanName , setBusinessUserPlanDetail  } = userSlice.actions;
export default userSlice.reducer;
