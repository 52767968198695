import React, { useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../commonComponents/MainCard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getImages } from "../../commonComponents/const";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import {
  getBusinessLogo,
  getBusinessName,
  getChatId,
  getGroupName,
  getSolutionLogo,
  setBusinessUserId,
  setChatSelected,
  setOpenChat,
  setSolutionId,
  setSolutionName,
  setSolutionProviderId,
  toggleChatData,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFetchRecentChatsQuery } from "../../redux/api/Dashboard/recentChats";
import RecentChatSkeleton from "../Skeleton/RecentChatSkull";
import {
  getSolutionId,
  getSolutionName,
} from "../../redux/features/inboxSlice";
import NoRecentChatScreen from "../NoDataScreens/NoRecentChatPage";
const RecentChats = ({ setOpenUpgradeModal, setOpenSubscribeModal }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const chatData = useSelector((state) => state.chat.chatData);
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const userType = localStorage.getItem("userType");
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const MessagePermission = useSelector(
    (state) => state.user.businessUserPlanDetail.bsi_colab_messaging
  );
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const navigate = useNavigate();

  const {
    data: recentChatData,
    isLoading: recentChatLoading,
    error: recentChatError,
    refetch: refetchRecentChatData,
  } = useFetchRecentChatsQuery();

  const handleNavigation = (path) => {
    navigate(path);
    if (path === "/inbox") {
      dispatch(setChatSelected(true));
      dispatch(setOpenChat(true));
      if (!chatData) {
        dispatch(toggleChatData());
      }
    }
  };

  const formatMessageDate = (createdAt) => {
    if (!createdAt || isNaN(new Date(createdAt))) {
      // Return a fallback message or an empty string
      return "";
    }

    const now = new Date();
    const messageDate = new Date(createdAt);

    const isToday = now.toDateString() === messageDate.toDateString();

    // Check if the message was sent yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = yesterday.toDateString() === messageDate.toDateString();

    const timeDifferenceInDays = (now - messageDate) / (1000 * 60 * 60 * 24);

    // Check if the message is within the last 7 days (excluding today and yesterday)
    const isWithinLast7Days =
      timeDifferenceInDays > 1 && timeDifferenceInDays <= 7;

    if (isToday) {
      // Return the time if the message is from today
      return messageDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (isYesterday) {
      // Return "Yesterday" if the message is from yesterday
      return "Yesterday";
    } else if (isWithinLast7Days) {
      // Return the day if the message is from the last 7 days (excluding today and yesterday)
      return messageDate.toLocaleDateString("en-US", { weekday: "long" });
    } else {
      // Return the full date in MM/DD/YYYY format if the message is older than 7 days
      return messageDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
  };
  const handleChatBox = (path) => {
    navigate(path);
    if (path === "/inbox") {
      dispatch(setChatSelected(true));
      dispatch(setOpenChat(true));
      if (chatData) {
        dispatch(toggleChatData());
      }
    }
  };
  const handleGoToChatForSolutionProvider = (
    solutionProviderId,
    solutionId,
    solutionName,
    businessUserId,
    businessName,
    businessLogo,
    chatId
  ) => {
    if (MessagePermission === "false") {
      setOpenSubscribeModal(true);
    } else if (MessagePermission === "true") {
      navigate("/inbox");
      dispatch(setSolutionProviderId(solutionProviderId));
      dispatch(setSolutionId(solutionId));
      dispatch(setSolutionName(solutionName));
      dispatch(setBusinessUserId(businessUserId));
      dispatch(getBusinessName(businessName));
      dispatch(getGroupName(businessName));
      dispatch(getBusinessLogo(businessLogo));
      dispatch(getChatId(chatId));
      dispatch(toggleGroupSelected(true));
    }
  };

  const handleGoToChatForBusinessUser = (
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    solutionLogo,
    groupChatId
  ) => {
    if (MessagePermission === "false") {
      setOpenSubscribeModal(true);
    } else if (MessagePermission === "true") {
      navigate("/inbox");
      dispatch(setSolutionProviderId(solutionProviderId));
      dispatch(setSolutionId(solutionId));
      dispatch(setBusinessUserId(businessUserId));
      dispatch(setSolutionName(solutionName));
      dispatch(getGroupName(solutionName));
      dispatch(getSolutionLogo(`${logoBaseUrl}${solutionLogo}`));
      dispatch(getChatId(groupChatId));
      dispatch(toggleGroupSelected(true));
    }
  };

  useEffect(() => {
    refetchRecentChatData();
  }, []);

  return (
    <>
      <MainCard
        content={false}
        sx={{
          backgroundColor: "#ECF6FF",
          border: "none",
          padding: "15px 25px",
          minHeight: "450px",
        }}
      >
        <Box sx={{ p: 0, pb: 0 }}>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "inherit",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "secondary.main",
                marginBottom: "1rem",
              }}
            >
              Recent Chats
            </Typography>
            <Button
              onClick={() => handleNavigation("/inbox")}
              variant="text"
              style={{ marginTop: "0" }}
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                textTransform: "inherit",
              }}
            >
              View all{" "}
              <ArrowForwardIosOutlinedIcon
                sx={{
                  marginLeft: "5px",
                  fill: "primary.main",
                  width: "12px",
                  height: "12px",
                }}
              />
            </Button>
          </Stack>
          <List
            component="nav"
            sx={{
              px: 0,
              py: 0,
              "& .MuiListItemButton-root": {
                py: 1.5,
              },
            }}
          >
            {recentChatData && recentChatData?.data  &&recentChatData?.data.length > 0 ? (
              userType === "solution_provider" ? (
                recentChatData?.data?.slice(0, 5).map((recentChat, index) => (
                  <ListItemButton
                    sx={{ px: 1, py: 1 }}
                    key={recentChat._id}
                    onClick={() =>
                      handleGoToChatForSolutionProvider(
                        recentChat?.solution_id?.solution_provider_id,
                        recentChat?.solution_id?._id,
                        recentChat?.solution_id?.solution_name,
                        recentChat?.userProfile?.user_id,
                        recentChat?.userProfile?.business_name,
                        recentChat?.userProfile?.business_logo,
                        recentChat?._id
                      )
                    }
                  >
                    <ListItemAvatar
                      sx={{
                        width: "56px",
                      }}
                    >
                      <Avatar
                        sx={{
                          color: "success.main",
                          bgcolor: "success.lighter",
                          width: "50px",
                          height: "50px",
                        }}
                        src={recentChat?.userProfile?.business_logo}
                        alt={"logo"}
                      ></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        pl: "10px",
                        width: isDrawerOpen ? "30%" : "50%",
                      }}
                      primary={
                        <Typography
                          noWrap
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            color: "secondary.main",
                            lineHeight: "22px",
                            maxWidth: isDrawerOpen ? "140px" : "180px",
                            [theme.breakpoints.down("sm")]: {
                              maxWidth: "90px",
                            },
                          }}
                          variant="h6"
                        >
                          <Tooltip
                            title={recentChat.userProfile.business_name || ""}
                          >
                            {recentChat.userProfile.business_name || ""}
                          </Tooltip>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "primary.main",
                            maxWidth: "220px",
                            visibility: "hidden",
                            [theme.breakpoints.down("sm")]: {
                              maxWidth: "110px",
                            },
                          }}
                        >
                          What’s the best solution for my problem? Can you send
                          me a listed document please.
                        </Typography>
                      }
                    />
                    <ListItemText
                      sx={{
                        width: "calc(40% - 56px)",
                      }}
                    >
                      <Stack alignItems="flex-end">
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{
                            color: "ltheme.main",
                            fontSize: "12px",
                            fontWeight: "500",
                            mb: "5px",
                          }}
                        >
                          {recentChat?.latestMessage?.createdAt &&
                            formatMessageDate(
                              recentChat?.latestMessage?.createdAt
                            )}
                        </Typography>
                        <Box
                          component="span"
                          sx={{
                            bgcolor: "primary.main",
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            fontSize: "12px",
                            color: "#fff",
                            textAlign: "center",
                            lineHeight: "24px",
                            visibility:
                              recentChat.unreadMessageCount === 0
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          {recentChat.unreadMessageCount}
                        </Box>
                      </Stack>
                    </ListItemText>
                  </ListItemButton>
                ))
              ) : (
                recentChatData?.data?.slice(0, 4).map((recentChat, index) => (
                  <ListItemButton
                    key={recentChat._id}
                    sx={{
                      px: 1,
                      py: 1,
                      minHeight: "80px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      handleGoToChatForBusinessUser(
                        recentChat?.solution_id?.solution_provider_id,
                        recentChat?.solution_id?._id,
                        recentChat?.userProfile?.user_id,
                        recentChat?.solution_id?.solution_name,
                        recentChat?.solution_id?.logo,
                        recentChat?._id
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {/* Solution Logo */}
                      <Box
                        sx={{
                          maxWidth: "50px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            height: "30px",
                            objectFit: "contain",
                            mixBlendMode: "multiply",
                          }}
                          src={`${logoBaseUrl}${recentChat.solution_id.logo}`}
                          alt={recentChat.solution_id.logo?.slug}
                        />
                      </Box>

                      {/* Solution Name */}
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 600,
                          color: "secondary.main",
                          lineHeight: "22px",
                        }}
                      >
                        <Tooltip
                          title={recentChat.solution_id.solution_name || ""}
                        >
                          {recentChat.solution_id.solution_name || ""}
                        </Tooltip>
                      </Typography>
                    </Box>

                    {/* Second Column: Date and Unread Messages */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        gap: 2.5,
                      }}
                    >
                      {/* Date */}
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          color: "ltheme.main",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {recentChat?.latestMessage?.createdAt &&
                          formatMessageDate(
                            recentChat?.latestMessage?.createdAt
                          )}
                      </Typography>

                      {/* Unread Message Count */}
                      <Box
                        component="span"
                        sx={{
                          bgcolor: "primary.main",
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          fontSize: "12px",
                          color: "#fff",
                          textAlign: "center",
                          lineHeight: "24px",
                          visibility:
                            recentChat.unreadMessageCount === 0
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        {recentChat.unreadMessageCount}
                      </Box>
                    </Box>
                  </ListItemButton>
                ))
              )
            ) : recentChatLoading ? (
              <>
                <RecentChatSkeleton />
              </>
            ) : (
              <>
              
              <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        textAlign: "center",
                        // mt: 2,
                        py: "26%",
                        [theme.breakpoints.down("lg")]: {
                          py: "26%",
                        },
                        [theme.breakpoints.down("md")]: {
                          py: "26%",
                        },
                        [theme.breakpoints.down("sm")]: {
                          py: "26%",
                        },
                      }}
                    >
                     <NoRecentChatScreen/>
                    </Typography>
                  </Grid>
              </>
            )}

            {/* <ListItemButton sx={{ px: "0" }}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('2.jpg')}
                                >

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Messiah Rozo
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >Hello David</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        Just now
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}onClick={()=> handleChatBox('/inbox')}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Badge
                                    overlap="circular">

                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        badgeContent={
                                            <SmallAvatar
                                                sx={{
                                                    width: "30px",
                                                    height: "30px",
                                                    ml: 1.5
                                                }}
                                                alt="Remy Sharp" src={getImages('2.jpg')} />
                                        }
                                    />
                                    <Avatar alt="Travis Howard"
                                        sx={{
                                            color: 'success.main',
                                            bgcolor: 'success.lighter',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                        src={getImages('1.jpg')}
                                    />
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <SmallAvatar
                                                sx={{
                                                    mr: 1.5
                                                }}
                                                alt="Remy Sharp" src={getImages('2.jpg')} />
                                        }
                                    />
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Solution discussion
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'primary.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >Vish: Yes I’m available.</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        09:41 AM
                                    </Typography>
                                    <Box component="span"
                                        sx={{
                                            bgcolor: 'primary.main',
                                            width: 24, height: 24,
                                            borderRadius: '50%',
                                            fontSize: '12px',
                                            color: '#fff',
                                            textAlign: 'center',
                                            lineHeight: '24px'
                                        }}>10</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Avatar
                                    sx={{
                                        color: 'success.main',
                                        bgcolor: 'success.lighter',
                                        width: '50px',
                                        height: '50px'
                                    }}
                                    src={getImages('avatar-1.png')}
                                >

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Emily Blunt
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >I’ll confirm this with my team and get back to you asap!</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Typography
                                        variant="body2" noWrap
                                        sx={{
                                            color: "ltheme.main",
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            mb: '5px'
                                        }}
                                    >
                                        24 Jan
                                    </Typography>
                                    <Box component="span"
                                    >&nbsp;</Box>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ px: "0" }}>
                            <ListItemAvatar
                                sx={{
                                    width: "56px"
                                }}
                            >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar
                                            sx={{
                                                width: "30px",
                                                height: "30px"
                                            }}
                                            alt="Remy Sharp" src={getImages('2.jpg')} />
                                    }
                                >
                                    <Avatar alt="Travis Howard"
                                        sx={{
                                            color: 'success.main',
                                            bgcolor: 'success.lighter',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                        src={getImages('4.jpg')}
                                    />
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    pl: '10px',
                                    width: "60%"
                                }}
                                primary={
                                    <Typography noWrap
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: '600',
                                            color: 'secondary.main',
                                            lineHeight: '22px',
                                            maxWidth: '220px',
                                            [theme.breakpoints.down('sm')]: {
                                                maxWidth: '110px'
                                            }
                                        }}
                                        variant="h6">Tyson, Lauren
                                    </Typography>}
                                secondary={<Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        color: 'ltheme.main',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                >Lauren : When can we schedule a call?</Typography>}
                            />
                            <ListItemText
                                sx={{
                                    width: "calc(40% - 56px)"
                                }}
                            >
                                <Stack alignItems="flex-end">
                                    <Stack alignItems="flex-end">
                                        <Typography
                                            variant="body2" noWrap
                                            sx={{
                                                color: "ltheme.main",
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                mb: '5px'
                                            }}
                                        >
                                            Yesterday
                                        </Typography>
                                        <Box component="span"
                                        >&nbsp;</Box>
                                    </Stack>
                                </Stack>
                            </ListItemText>
                        </ListItemButton> */}
          </List>
        </Box>
      </MainCard>
    </>
  );
};

export default RecentChats;
