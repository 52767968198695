import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";
const baseUrl = process.env.REACT_APP_base_URL;
export const projectBaseApis = createApi({
    reducerPath: 'projectBaseApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
          // const token = useSelector((state)=> state.user.token)
          const token = store.getState().user.token;
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
          return headers;
      },
    }),
    endpoints:(builder)=>({
         // ─── GET ───

        // All Project
        fetchProjectList: builder.query({
        query:(searchValue)=>({
            url:`projects?search=${searchValue}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
          return response
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch project list error:', error);
          // Handle error cases as per your application's requirements
        },
        }),

        // Project Detail
        fetchProjectDetail: builder.query({
            query:({projectId})=>({
                url:`projects/detail/${projectId}`,
                method: 'GET',
            }),
            retry: (failureCount, error) => {
                // Retry up to 3 times if the request fails due to network issues or server errors
                return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
              },
            transformResponse: (response) => {
              return response;
            },
            
            refetchOnWindowFocus: true,
            // retry: RetryOnError,
            onError: (error) => {
              console.error('Fetch project detail error:', error);
              // Handle error cases as per your application's requirements
            },
         }),

        // ─── POST ───

        // Create Project
        createProject: builder.mutation({
            query: ({ projectName , isActive , selectedSolutionId , userId , selectedBusinessUserId , selectedBoardId , selectedTeamMembers}) => ({
                url:'projects/create',
                method: 'POST',
                body: {
                    plan_name : projectName,
                    is_active : isActive,
                    solution_id : selectedSolutionId,
                    created_by : userId,
                    business_user_id : selectedBusinessUserId || userId, 
                    requirement_board_id : selectedBoardId || selectedSolutionId,
                    team_members : selectedTeamMembers
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }), 

        // Create Milestone 
         createMilestone: builder.mutation({
            query: ({projectId , milestones}) => ({
                url:`projects/milestone/${projectId}`,
                method: 'POST',
                body: {
                    milestones
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),

        // Create Task
        createTask: builder.mutation({
            query: ({ projectId ,taskDetail , milestoneId}) => ({
                url:`projects/tasks/${projectId}`,
                method: 'POST',
                body: {
                    tasks: taskDetail,
                    milestone_id: milestoneId
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),

        // EditMilestone
        editMilestone: builder.mutation({
            query: ({ projectId , formData}) => ({
                url:`projects/editMilestone/${projectId}`,
                method: 'POST',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),

        // Edit Task
        editTask: builder.mutation({
            query: ({ projectId , formData}) => ({
                url:`projects/editTask/${projectId}`,
                method: 'POST',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),

        // Drag&Drop
        dragAndDrop: builder.mutation({
            query: ({ respectiveId , updatedMilestones}) => ({
                url:`projects/reorder-milestone/${respectiveId}`,
                method: 'POST',
                body: {
                    milestones: updatedMilestones
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),

        // Reorder Task
        reorderTask: builder.mutation({
            query: ({ projectId , updatedTasks , milestoneId}) => ({
                url:`projects/reorder-task/${projectId}`,
                method: 'POST',
                body: {
                    tasks: updatedTasks,
                    milestone_id: milestoneId
                },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),

    })
})

export const {
useFetchProjectListQuery ,
useFetchProjectDetailQuery,
useCreateMilestoneMutation,
useCreateProjectMutation,
useCreateTaskMutation,
useDragAndDropMutation,
useEditMilestoneMutation,
useEditTaskMutation,
useReorderTaskMutation,
} =  projectBaseApis;
export default  projectBaseApis;