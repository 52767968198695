import { createSlice } from "@reduxjs/toolkit";

const driveShareSlice = createSlice({
    name :'driveShareSlice',
    initialState: {
      selectedBoardId: "" ,
      selectedBoardName: "" ,
      selectedSolutionId: "",
      selectedSolutionName: "",
      selectedBusinessId: "",
      selectedUserId: "",
      groupChatId: "",
    },
    reducers:{
        getShareListBoardId: (state , action)=> {
            state.selectedBoardId =  action.payload
        },
        getShareListBoardName: (state , action)=> {
            state.selectedBoardName =  action.payload
        },
        getShareListSolutionId: (state , action)=> {
            state.selectedSolutionId =  action.payload
        },
        getShareListSolutionName: (state , action)=> {
            state.selectedSolutionName =  action.payload
        },
        getShareListBusinessId: (state , action)=> {
            state.selectedBusinessId =  action.payload
        },
        getShareListUserId: (state , action)=> {
            state.selectedUserId =  action.payload
        }, 
        getGroupChatId: (state, action)=>{
            state.groupChatId = action.payload
        },
    }
});

export const {getShareListBoardId ,getShareListBoardName,  getShareListSolutionId , getShareListSolutionName, getShareListBusinessId  , getShareListUserId , getGroupChatId} = driveShareSlice.actions;
export default driveShareSlice.reducer;