import { Card, CardContent, Box, Typography, Skeleton, Button } from '@mui/material';

const InviteSolutionProviderSkull = () => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
      }}
    >
      <CardContent
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            px: 2,
            pt: 4,
            pb: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rectangular" width={154} height={41} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
            <Box sx={{display:'flex' , gap:2}}>
              <Skeleton variant="rectangular" width={80} height={36} sx={{ borderRadius: 1, mr: 2 ,  bgcolor: "rgba(0, 0, 0, 0.05)" }} />
              <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: 1 ,  bgcolor: "rgba(0, 0, 0, 0.05)"}} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Box>
              <Skeleton variant="text" width={200} height={24} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
              <Skeleton variant="text" width={150} height={16} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            py: 2,
            px: 5,
            backgroundColor: "#DBEBF833",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "solid 1px rgb(16 110 237 / 30%)",
          }}
        >
          {[...Array(3)].map((_, index) => (
            <Box
              key={index}
              sx={{
                px: "0",
                alignItems: "center",
                width: "120px",
                height: "30px",
                display: "flex",
                gap: "8px",
              }}
            >
              <Skeleton variant="circular" width={24} height={24} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
              <Box sx={{ width: "100%" }}>
                <Skeleton variant="text" width="80%" height={14} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
                <Skeleton variant="text" width="90%" height={12} sx={{ bgcolor: "rgba(0, 0, 0, 0.05)"}} />
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default InviteSolutionProviderSkull;


