// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
projectName : "",
isActive : false,
selectedBoardId : "",
selectedSolutionId : "",
selectedBusinessUserId: "",
selectedTeamMembers: [],
};

const createProjectSlice = createSlice({
  name: "createProject",
  initialState,
  reducers: {
   setProjectName(state , action ) {
   state.projectName = action.payload;
   },
   setIsActive (state, action) {
    state.isActive = action.payload; 
  },
   setSelectedBoardId(state, action){
    state.selectedBoardId = action.payload;
   },
   setSelectedSolutionId(state , action){
    state.selectedSolutionId = action.payload;
   },
   setSelectedBusinessUserId(state , action){
    state.selectedBusinessUserId = action.payload;
   },
   setSelectedTeamMembers(state , action){
    state.selectedTeamMembers = action.payload;
   },
   resetCreateProjectState(state) {
    Object.assign(state, initialState);
  },
  }
});

export const {
    setProjectName,
    setIsActive,
    setSelectedBoardId,
    setSelectedSolutionId , 
    setSelectedBusinessUserId,
    setSelectedTeamMembers,
    resetCreateProjectState,
} = createProjectSlice.actions;
export default createProjectSlice.reducer;
