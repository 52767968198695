import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  Menu,
  styled,
  InputBase,
  Radio,
  FormControlLabel,
  Select,
  FormControl,
  Grid,
  Card,
  CardContent,
  Paper,
  IconButton,
  ListItemText,
  Tooltip,
  Skeleton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { useTheme } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { getImages } from "../../commonComponents/const";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useFetchMySolutionListingQuery } from "../../redux/api/SolutionProvider/MySolution/solutionListing";
import NoDataScreen from "../../commonComponents/noData";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { resetRefetchMySolutionList } from "../../redux/features/refetch";
import { setSelectedSolutionId } from "../../redux/features/SolutionProvider/editSolutionSlice";
import MySolutionSkull from "../../commonComponents/MySolutions/mySolutionSkull";
import useBreakpoint from "../../commonComponents/ResponsiveBreakPoints";
import Modals from "../../commonComponents/Modals";
import LimitExceededModal from "../../commonComponents/LimitExceededModal";
import SubscribeModal from "../../commonComponents/SubscribeModal";
import PaymentSuccess from "../MySubscription/paymentSuccess";
import NoSolutionScreen from "../../commonComponents/NoDataScreens/noSolutionScreen";
import UpgradeModal from "../../commonComponents/upgradeModal";

const MySolutions = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const createRequirementLimit = useSelector(
    (state) =>
      state.user?.businessUserPlanDetail?.bsi_colab_requirement_creation_wizard
  );
  const { breakpoint } = useBreakpoint();

  // Define skull count based on breakpoints
  const getSkullCount = (breakpoint) => {
    switch (breakpoint) {
      case "xl":
        return 6; // e.g., 10 skulls for extra-large screens
      case "lg":
        return 6; // e.g., 8 skulls for large screens
      case "md":
        return 4; // e.g., 6 skulls for medium screens
      case "sm":
        return 2; // e.g., 4 skulls for small screens
      case "xs":
      default:
        return 2; // e.g., 2 skulls for extra-small screens
    }
  };
  const skullCount = getSkullCount(breakpoint);

  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // States
  const userId = localStorage.getItem("userId");
  const s3Url = process.env.REACT_APP_S3_BUCKET_URL;
  const [designation, setDesignation] = useState("");
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElFolderDropdown, setAnchorElFolderDropdown] = useState(null);
  const [anchorElSolutionListOptions, setAnchorElSolutionListOptions] =
    useState(null);
  // const [selectedSolutionId , setSelectedSolutionId] = useState(null);
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const planName = useSelector((state) => state.user.planName);
  const { selectedSolutionId } = useSelector((state) => state.editSolution);
  const createSolutionLimit = useSelector(
    (state) => state.user?.businessUserPlanDetail?.no_of_soulution
  );
  const limitMessage = `Your current plan only support  ${createSolutionLimit} solution submission. Please upgrade to higher plan to add more solutions`;
  // solution listing api
  const {
    data: solutionList,
    isError: solutionListError,
    isLoading: solutionLoading,
    refetch,
  } = useFetchMySolutionListingQuery({
    userId,
    searchValue,
  });

  // set refetchSolutionList in reduxStore
  const refetchMySolutionList = useSelector(
    (state) => state.refetch.refetchMySolutionList
  );
  useEffect(() => {
    if (refetchMySolutionList) {
      refetch().finally(() => {
        dispatch(resetRefetchMySolutionList());
      });
    }
  }, [refetchMySolutionList, refetch, dispatch]);

  // Sort and Filter logic

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(!openSort);
  };
  const handleCloseSort = (event) => {
    setAnchorElSort(null);
    setOpenSort(false);
    setSelectedSortOption(event.target.value);
  };
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(!openFilter);
  };
  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  const handleChange = (event) => {
    setDesignation(event.target.value);
  };

  const openFolderDropdown = Boolean(anchorElFolderDropdown);
  const handleFolderDropdownClick = (event) => {
    setAnchorElFolderDropdown(event.currentTarget);
  };
  const handleFolderDropdownClose = () => {
    setAnchorElFolderDropdown(null);
  };

  const openSolutionListOptions = Boolean(anchorElSolutionListOptions);
  const handleSolutionListOptionsClick = (event, selectedSolutionId) => {
    setAnchorElSolutionListOptions(event.currentTarget);
    dispatch(setSelectedSolutionId(selectedSolutionId));
  };
  const handleSolutionListOptionsClose = () => {
    setAnchorElSolutionListOptions(null);
    dispatch(setSelectedSolutionId(null));
  };

  // limitExceed modal
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const handleOpenUpgradeModal = () => {
    setOpenUpgradeModal(true);
  };
  const handleCloseUpgradeModal = () => {
    setOpenUpgradeModal(false);
  };
  // Subscribe modal
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const handleOpenSubscribeModal = () => {
    setOpenSubscribeModal(true);
  };
  const handleCloseSubscribeModal = () => {
    setOpenSubscribeModal(false);
  };

  //  PaymentSuccess Modal
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);

  const handleOpenPaymentSuccessModal = () => {
    setOpenPaymentSuccess(true);
  };
  const handleClosePaymentSuccessModal = () => {
    setOpenPaymentSuccess(false);
  };

  // Searching logic
  const handleInputChange = (event) => {
    setSearchParams(event.target.value.trim());
  };

  const handleRequirementBoardSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetch();
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        await refetch();
        if (solutionListError) {
          const errorMessage = boardData?.message || "No Data Found";
          showToastMessage(errorMessage, "error");
          return;
        }
      } catch (solutionListError) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue("");
    refetch();
  };

  const handleCreateSolution = () => {
    if (!createSolutionLimit) {
      showToastMessage("Invalid plan name", "error");
      return;
    }

    if (
      solutionList?.data?.total <= createSolutionLimit ||
      solutionList?.message == "Solution list not found"
      
    ) {
      navigate("/create-solution");
    } else {
      if (planName === "Lite") {
        setOpenUpgradeModal(true);
      } else if (planName === "Plus") {
        setOpenUpgradeModal(true);
      } else if (planName === "Pro") {
        setOpenUpgradeModal(true);
      }
    }
  };

  useEffect(() => {
    if (solutionLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [solutionLoading, dispatch]);

  return (
    <>
      {/* Search and Sort section */}
      <Grid container rowSpacing={{ md: 3.5, xs: 1 }} columnSpacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Button
            onClick={handleCreateSolution}
            variant="contained"
            sx={{
              textTransform: "inherit",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
              p: "10px 25px",
              boxShadow: "none",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                boxShadow: "none",
              },
              [theme.breakpoints.down("lg")]: {
                p: isDrawerOpen ? "10px 7px" : "10px 7px",
              },
              [theme.breakpoints.down("md")]: {
                p: isDrawerOpen ? "10px 0px" : "10px 7px",
              },
              [theme.breakpoints.down("sm")]: {
                p: "10px 25px",
                width: "100%",
              },
              [theme.breakpoints.down("xs")]: {
                p: "10px 25px",
                width: "100%",
              },
              "@media (max-width:900px) and (min-width:748px)": {
                p: isDrawerOpen ? "10px 7px" : "9px 7px",
              },
            }}
          >
            <AddIcon
              sx={{
                fontSize: "20px",
                marginRight: "8px",
              }}
            />{" "}
            Create New Solution
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              [theme.breakpoints.down("sm")]: {
                flexWrap: "wrap",
                width: "100%",
                maxWidth:'100%'
              },
            }}
          >
            <Box
              sx={{
                width: "45%",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  mr: 0,
                },
              }}
            >
              <Search>
                <StyledInputBase
                  placeholder="Search Solutions"
                  inputProps={{ "aria-label": "search" }}
                  value={searchParams}
                  onChange={handleInputChange}
                  onKeyDown={handleRequirementBoardSearch}
                  autoFocus
                />
                {searchParams ? (
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ position: "absolute", right: 0 }}
                  >
                    <CancelIcon sx={{ color: "lightSecondary.main" }} />
                  </IconButton>
                ) : (
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                  </SearchIconWrapper>
                )}
              </Search>
            </Box>
          </Box>
        </Grid>
      </Grid>
        <Grid mb={2} mt={1} container rowSpacing={2} columnSpacing={2}>
          {solutionLoading ? ( // Check if the data is loading
            <Grid container spacing={2} sx={{ py: 2, px: 1 }}>
              {[...Array(skullCount)].map((_, index) => (
                <MySolutionSkull />
              ))}
            </Grid>
          ) : solutionList &&
            solutionList?.message == "Solution list not found" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NoSolutionScreen
                handleCreateSolution={handleCreateSolution}
                setOpenUpgradeModal={setOpenUpgradeModal}
                showToastMessage={showToastMessage}
              />
            </Box>
          ) : (
            solutionList?.data?.solutions?.map((solution, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ mb: 2 }}
                key={solution._id}
              >
                <Card
                  sx={{
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      p: 0,
                    }}
                    style={{
                      paddingBottom: "0",
                    }}
                  >
                    <Box
                      sx={{
                        px: 2,
                        pt: 2,
                        pb: 2,
                        [theme.breakpoints.down("md")]: {
                          pt: 3,
                          pb: 3,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          width: "100%",
                          maxWidth:'100%',
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: "150px",
                            maxWidth: "150px",
                            height: "44px",
                            "& img": {
                              [theme.breakpoints.down("sm")]: {
                                width: "auto !important",
                                maxWidth: "100%",
                                maxHeight: "50px",
                              },
                            },
                          }}
                        >
                          <img
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              mixBlendMode: "multiply",
                            }}
                            src={s3Url + solution?.logo}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link to={solution.source_link} target="blank">
                            <IconButton
                              sx={{
                                p: 0.5,
                              }}
                            >
                              <OpenInNewOutlinedIcon
                                sx={{ color: "lightSecondary.main" }}
                              />
                            </IconButton>
                          </Link>
                          <div
                            key={solution._id}
                            style={{
                              position: "relative",
                            }}
                          >
                            <IconButton
                              id={`basic-button-${solution._id}`}
                              aria-controls={
                                openSolutionListOptions &&
                                selectedSolutionId === solution._id
                                  ? `basic-menu-${solution._id}`
                                  : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                openSolutionListOptions &&
                                selectedSolutionId === solution._id
                                  ? "true"
                                  : undefined
                              }
                              onClick={(event) =>
                                handleSolutionListOptionsClick(
                                  event,
                                  solution._id
                                )
                              }
                              sx={{
                                p: 0.5,
                              }}
                            >
                              <MoreVertOutlinedIcon
                                sx={{
                                  fontSize: "1.2rem",
                                  color: "lightSecondary.main",
                                }}
                              />
                            </IconButton>
                            <Menu
                              id={`basic-menu-${solution._id}`}
                              anchorEl={anchorElSolutionListOptions}
                              open={
                                openSolutionListOptions &&
                                selectedSolutionId === solution._id
                              }
                              onClose={handleSolutionListOptionsClose}
                              MenuListProps={{
                                "aria-labelledby": `basic-button-${solution._id}`,
                              }}
                              sx={{
                                "& .MuiMenu-paper": {
                                  borderRadius: "4px",
                                  border: "none",
                                  minWidth: "210px",
                                  p: 0.5,
                                },
                              }}
                            >
                              <Paper
                                sx={{
                                  width: 210,
                                  maxWidth: "100%",
                                  background: "#fff",
                                  borderRadius: "10px",
                                  borderBottom: "none",
                                  boxShadow: "none",
                                  p: 0.5,
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    color: "lightSecondary.main",
                                    py: 1,
                                    px: 1.4,
                                    "&:hover": {
                                      backgroundColor: "extraLTheme.main",
                                    },
                                    "&.active": {
                                      backgroundColor: "primary.main",
                                      color: "#fff",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Link
                                    to="/edit-solution"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                      width: "100%",
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  onClick={handleSolutionListOptionsClose}
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    color: "lightSecondary.main",
                                    py: 1,
                                    px: 1.4,
                                    "&:hover": {
                                      backgroundColor: "extraLTheme.main",
                                    },
                                    "&.active": {
                                      backgroundColor: "primary.main",
                                      color: "#fff",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  Archive
                                </MenuItem>
                                <MenuItem
                                  onClick={handleSolutionListOptionsClose}
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    color: "lightSecondary.main",
                                    py: 1,
                                    px: 1.4,
                                    "&:hover": {
                                      backgroundColor: "extraLTheme.main",
                                    },
                                    "&.active": {
                                      backgroundColor: "primary.main",
                                      color: "#fff",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Paper>
                            </Menu>
                          </div>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          mt: 1,
                          maxWidth: "80%",
                          [theme.breakpoints.down("md")]: {
                            mt: 1,
                            maxWidth: "100%",
                          },
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            sx={{
                              fontSize: "1.2rem",
                              fontWeight: "700",
                              color: "secondary.main",
                              mb: 0.5,
                              maxWidth: "80%",
                            }}
                          >
                            {solution.solution_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontSize: "0.95rem",
                              fontWeight: "500",
                              color: "secondary.main",
                              minHeight: "41px",
                              maxHeight: "41px",
                              overflow: "hidden",
                            }}
                          >
                            {solution.short_overview}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        py: 2,
                        px: 2,
                        width: "100%",
                        backgroundColor: "rgba(219, 235, 248, 0.2)",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                        borderTop: "solid 1px rgba(162, 194, 241, 1)",
                        // minHeight: '102px',
                        [theme.breakpoints.down("md")]: {
                          flexWrap: "wrap",
                          px: 2,
                        },
                      }}
                    >
                      {/* HeadQuarter */}
                      <Box
                        display={"flex"}
                        sx={{
                          px: "0",
                          pr: 1,
                          alignItems: "center",
                          maxWidth: "33.33%",
                        }}
                      >
                        <Box>
                          <img
                            style={{ display: "block" }}
                            src={getImages("headquarter_icon.png")}
                          />
                        </Box>
                        <ListItemText
                          sx={{
                            pl: "10px",
                            width: "60%",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                            }}
                            variant="h6"
                          >
                            Headquarters
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "lightSecondary.main",
                            }}
                          >
                            {solution.head_quarters}
                          </Typography>
                        </ListItemText>
                      </Box>
                      {/* Industries */}
                      <Box
                        display={"flex"}
                        sx={{
                          px: "0",
                          pr: 1,
                          alignItems: "center",
                          maxWidth: "33.33%",
                        }}
                      >
                        <Box>
                          <img
                            style={{ display: "block" }}
                            src={getImages("industry_icon.png")}
                          />
                        </Box>
                        <ListItemText
                          sx={{
                            pl: "10px",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                            }}
                            variant="h6"
                          >
                            Industries
                          </Typography>
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "lightSecondary.main",
                            }}
                          >
                            <Tooltip
                              title={solution.industry_ids
                                .map((industry) => industry.name)
                                .join(", ")}
                            >
                              {solution?.industry_ids &&
                              solution.industry_ids.length > 0
                                ? solution.industry_ids
                                    .map((industry) => industry.name)
                                    .join(", ")
                                : ""}
                            </Tooltip>
                          </Typography>
                        </ListItemText>
                      </Box>
                      {/* useCases */}
                      <Box
                        display={"flex"}
                        sx={{
                          px: "0",
                          alignItems: "center",
                          maxWidth: "33.33%",
                        }}
                      >
                        <Box>
                          <img
                            style={{ display: "block" }}
                            src={getImages("usecase_icon.png")}
                          />
                        </Box>
                        <ListItemText
                          sx={{
                            pl: "10px",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                            }}
                            variant="h6"
                          >
                            Use Cases
                          </Typography>

                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "lightSecondary.main",
                            }}
                          >
                            <Tooltip
                              title={solution.use_case_ids
                                .map((useCase) => useCase.title)
                                .join(", ")}
                            >
                              {solution?.use_case_ids &&
                              solution.use_case_ids.length > 0
                                ? solution.use_case_ids
                                    .map((useCase) => useCase.title)
                                    .join(", ")
                                : ""}
                            </Tooltip>
                          </Typography>
                        </ListItemText>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>



      <Modals
        open={openUpgradeModal}
        handleClose={handleCloseUpgradeModal}
        modalWidth={400}
      >
        <LimitExceededModal
          limitMessage={limitMessage}
          handleClose={handleCloseUpgradeModal}
          showToastMessage={showToastMessage}
          setOpenSubscribeModal ={setOpenSubscribeModal}
        />
      </Modals>
      {/* Subscribe Modal */}
      <UpgradeModal
        open={openSubscribeModal}
        handleClose={handleCloseSubscribeModal}
        modalWidth={650}
      >
        <SubscribeModal
          limitMessage={limitMessage}
          handleClose={handleCloseSubscribeModal}
          setOpenPaymentSuccess={setOpenPaymentSuccess}
          showToastMessage={showToastMessage}
        />
      </UpgradeModal>
      <Modals
        open={openPaymentSuccess}
        handleClose={handleClosePaymentSuccessModal}
        modalWidth={450}
      >
        <PaymentSuccess handleClose={handleClosePaymentSuccessModal} />
      </Modals>
    </>
  );
};

export default MySolutions;
