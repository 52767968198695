// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {  
  taskData: null,  
  taskName: "",
  taskDescription: "",
  taskStatus: "pending",
  taskPriority: "high",
  taskStartDate: dayjs().toISOString(), // Store as ISO string
  taskDueDate: dayjs().toISOString(),  // Store as ISO string

};

const editTaskSlice = createSlice({
  name: "editTaskSlice",
  initialState,
  reducers: {
    setTaskData(state, action){
        const data = action.payload;
        state.taskData = data || [];
        state.taskName = data?.name || "";
        state.taskDescription = data?.description || "";
        state.taskStatus =  data?.status || "pending";
        state.taskPriority = data?.priority || "low";
        state.taskStartDate = data?.start_date || dayjs().toISOString();
        state.taskDueDate = data?.due_date || dayjs().toISOString();
    },
    setTaskName(state, action) {
      state.taskName = action.payload;
    },
    setTaskDescription(state, action) {
      state.taskDescription = action.payload;
    },
    setTaskStatus(state, action) {
      state.taskStatus = action.payload;
    },
    setTaskPriority(state, action) {
      state.taskPriority = action.payload;
    },
    setTaskStartDate(state, action) {
      state.taskStartDate = action.payload;
    },
    setTaskDueDate(state, action) {
      state.taskDueDate = action.payload;
    },
  },
});

export const {
  setTaskData,
  setTaskName,
  setTaskDescription,
  setTaskStatus,
  setTaskPriority,
  setTaskStartDate,
  setTaskDueDate,
} = editTaskSlice.actions;

export default editTaskSlice.reducer;
