import React, { useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Button
} from '@mui/material'
import { useCreateFolderMutation } from '../../redux/api/BsiDrive/driveFolderCreate';
import { useDispatch, useSelector } from 'react-redux';
import { setRefetchDocument, setRefetchSubFolder } from '../../redux/features/refetch';

const NewFolderModal = ({ handleClose , showToastMessage , parentId , documentId}) => {
    // getting userId from localStorage
    const userId = localStorage.getItem('userId'); 
    const [isFolderCreate, setIsFolderCreate] = useState(false);
    const dispatch = useDispatch();
    const [folderName , setFolderName] = useState("")

    const [createDriveFolder, { isLoading, isError, error,  }] =  useCreateFolderMutation();
    const handleChange =(event)=>{
       let value = event.target.value.trim().replace(/\s+/g, ' ');
        setFolderName(value)
    }

    const createFolderName = async () => {
        if (!folderName.trim()) {
          showToastMessage('Folder name cannot be empty', 'error');
          return;
        }
        setIsFolderCreate(true);
        try {
          const response = await createDriveFolder({
            folderName: folderName,
            isDocument: true,
            parentId: documentId,
            userId: userId,
          }).unwrap();
          const successMessage = response?.message || 'Folder created';
          showToastMessage(successMessage, 'success');
          handleClose();
          dispatch(setRefetchDocument(true));
          dispatch(setRefetchSubFolder(true));
        } catch (error) {
          const errorMessage = error?.message || 'Failed to create folder';
          showToastMessage(errorMessage, 'error');
        }
      };

    return (
        <>
            <Box
                sx={{
                    mb: 3
                }}
            >
                <Typography variant='h5'
                    sx={{
                        fontSize: "1.3rem",
                        color: "secondary.main",
                        fontWeight: "700"
                    }}>
                    New Folder
                </Typography>
            </Box>
            <TextField
                hiddenLabel
                type='text'
                required
                id="filled-hidden-label-normal"
                variant="filled"
                placeholder='Folder name*'
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                    createFolderName(); // Trigger function on Enter
                    }
                  }}
                sx={{
                    width: '100%',
                    borderRadius: '4px',
                    backgroundColor: 'extraLTheme.main',
                    border: 'solid 1px #DBEBF8',
                    '& .MuiInputBase-input': {
                        fontSize: '0.9rem',
                        color: 'lightSecondary.main',
                        fontWeight: '500',
                        pt: 1.5,
                        pb: 1.5
                    },
                    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                        border: 'none'
                    },
                    '& .MuiInputBase-root:hover:before': {
                        border: 'none'
                    },
                    '&:hover': {
                        borderColor: '#DBEBF8'
                    },
                    '& .MuiInputBase-root:before': {
                        border: 'none'
                    },
                    '& .MuiInputBase-root:after': {
                        border: 'none'
                    }
                }}
            />
            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    gap: 2
                }}
            >
                <Button variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        p: "10px 25px",
                        width: '50%',
                        borderWidth: '2px',
                        fontWeight: '600',
                        '&:hover': {
                            borderWidth: '2px',
                        }
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        p: "12px 25px",
                        width: '50%',
                        boxShadow: 'none',
                        fontWeight: '600',
                        '&:hover': {
                            boxShadow: 'none',
                        }
                    }}
                    onClick={createFolderName}
                    disabled={isFolderCreate}
                    >
                    Create
                </Button>
            </Box>
        </>
    )
}

export default NewFolderModal