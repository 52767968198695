import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItemAvatar,
  List,
  ListItem,
  ListItemIcon,
  keyframes,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { getImages } from "./const";
import CheckIcon from "@mui/icons-material/Check";
import { setRefetchUserData } from "../redux/features/refetch";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/features/loader";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import PlanCardSkull from "./Skeleton/PlanCardSkull";
import ModalPlanCardSkull from "./Skeleton/ModalPlanCardSkull";
import { useFetchPricingTableDetailQuery, useManageSubscriptionPlanMutation, useUpgradeSubscriptionPlanMutation } from "../redux/api/MySubscription";

const SubscribeModal = ({ showToastMessage }) => {

const shineAnimation = keyframes`
0% {
  background-position: -200%;
}
100% {
  background-position: 200%;
}
`;

  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const userEmail = useSelector((state) => state.user.data.email);
  const customerId = useSelector((state) => state.user.data.customerId[0]) ?? "";
  const selectedPlanId =  useSelector((state) => state.user.data.selectedPlanId);
  const planName = useSelector((state) => state.user.planName);
  const [billingCycle, setBillingCycle] = useState("monthly");
    const [animate, setAnimate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

    const {
      data: pricingTableData,
      isError: pricingTableError,
      isLoading: pricingTableLoading,
      refetch: refetchPricingTableData,
    } = useFetchPricingTableDetailQuery();

     // Api Call
  const [upgradeSubscriptionPlan, { isLoading: upgradePlanLoading }] =
  useUpgradeSubscriptionPlanMutation();
const [manageSubscriptionPlan, { isLoading: managePlanLoading }] =
  useManageSubscriptionPlanMutation();

const handleManageSubscription = async (newPlanName) => {
  if (newPlanName !== "Lite") {
    try {
      // Call the API to upgrade the subscription
      const response = await manageSubscriptionPlan({
        customerId: customerId,

      }).unwrap();

      if (response.statusCode === 200) {
        const customerPortalUrl = response?.data;
        window.location.href = customerPortalUrl;
      } else {
        showToastMessage("Plan upgrade failed", "error");
      }
    } catch (error) {
      showToastMessage(error?.message || "Plan Upgrade Failed", "error");
    }
  }
};

const upgradeSubscription = async (productId , planTenure , planPrize) => {
  try {
    // Call the API to upgrade the subscription
    const response = await upgradeSubscriptionPlan({
      productId: productId,
      planType: planTenure,
      planPrize: planPrize,
      isTrial: customerId ? false : true,
    }).unwrap();

    if (response.statusCode === 200) {
      window.location.href = response.data;
    } else {
      showToastMessage("Plan upgrade failed", "error");
    }
  } catch (error) {
    showToastMessage(error?.message || "Plan Upgrade Failed", "error");
  }
};

const handleToggle = (event, newCycle) => {
  if (newCycle) {
    setAnimate(true);
    setTimeout(() => {
      setBillingCycle(newCycle);
      setAnimate(false);
    }, 400); // Animation duration
  }
};

  useEffect(() => {
    if (managePlanLoading || upgradePlanLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [managePlanLoading, upgradePlanLoading, dispatch]);


  return (
    <>
    <Box sx={{ textAlign: "center", marginBottom: 1 ,}}>
    <ToggleButtonGroup
      value={billingCycle}
      exclusive
      onChange={handleToggle}
    >
      <ToggleButton
        value="monthly"
        sx={(theme) => ({
          borderRadius: "8px",
          textTransform: "none",
          width: "110px",
          height: "40px",
          backgroundColor:
            billingCycle === "monthly"
              ? "#106EED  !important"
              : "hsla(0,0%, 10%, 0.07)  !important",
          color:
            billingCycle === "monthly"
              ? "#fff !important"
              : "lightSecondary.main !important",

          "&:hover": {
            backgroundColor:
              billingCycle === "monthly"
                ? "#106EED  !important"
                : "hsla(0,0%, 10%, 0.07)  !important",
            color:
              billingCycle === "monthly"
                ? "#fff !important"
                : "lightSecondary.main !important",
          },
        })}
      >
        Monthly
      </ToggleButton>
      <ToggleButton
        value="yearly"
        sx={(theme) => ({
          borderRadius: "8px",
          textTransform: "none",
          width: "110px",
          height: "40px",
          backgroundColor:
            billingCycle === "yearly"
              ? "#106EED  !important"
              : "hsla(0,0%, 10%, 0.07) !important",
          color:
            billingCycle === "yearly"
              ? "#fff !important"
              : "lightSecondary.main !important",

          "&:hover": {
            backgroundColor:
              billingCycle === "yearly"
                ? "#106EED !important"
                : "hsla(0,0%, 10%, 0.07)  !important",
            color:
              billingCycle === "yearly"
                ? "#fff !important"
                : "lightSecondary.main !important",
          },
        })}
      >
        Yearly
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
  <SwitchTransition>
            <CSSTransition
              key={billingCycle}
              timeout={300}
              classNames="fade"
            >
    <Grid
            container
            spacing={1}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {pricingTableLoading ? (
              <>
                <ModalPlanCardSkull/>
              </>
            ) : userType == "solution_provider" ? (
              pricingTableData?.solutionProviderPlans[billingCycle]
                .slice(1,3)
                .map((plan, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    key={index}
                    sx={{
                      mt: 1,
                      padding: "8px",
                      // backgroundColor:
                      //   plan.title == "Plus"
                      //     ? "hsla(0,0%, 10%, 0.06)"
                      //     : "#dbebf8",
                      backgroundColor:
                        plan.title == "Plus"
                          ? "hsla(0,0%, 10%, 0.06)"
                          : "#fff",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        // textAlign: "center",
                        border: "none",
                        borderRadius: "10px",
                        display: "flex",
                        width: "200px",
                        justifyContent: "center",

                        // transition: "all 0.5s ease-in-out 0s",
                        // "&:hover": {
                        //   background: "#ffffff",
                        //   boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        // },
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            fontSize: "0.8em",
                            fontWeight: 600,
                            lineHeight: "1.2em",
                            color: "hsla(0,0%, 10%, 1.1)",
                            backgroundColor: "#ffffff",
                            padding: "2px 8px",
                            // borderRadius: "2px",
                            textTransform: "none",
                            boxShadow: "none",
                            minWidth: "auto",
                            visibility:
                              plan.title == "Plus" ? "visible" : "hidden",
                            textTransform: "none",
                            // background: "linear-gradient(120deg, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.1) 70%)",
                            // background: "linear-gradient(120deg, rgba(7, 57, 133, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(7, 57, 133, 0.2) 70%)",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background:
                                "linear-gradient(150deg, rgba(245, 235, 235, 0.5) 30%, rgba(245, 245, 245, 0.8) 50%, rgba(235, 235, 235, 0.5) 70%)",
                              backgroundSize: "200% 100%",
                              animation: `${shineAnimation} 3s linear infinite`,
                              borderRadius: "inherit", // To match the parent's border-radius
                              zIndex: 1,
                              pointerEvents: "none", // Makes the shimmer non-interactive
                            },
                          }}
                        >
                          Most popular
                        </Button>
                        <Typography
                          sx={{
                            fontSize: "1.4em",
                            fontWeight: 500,
                            lineHeight: "1.2em",
                            // color: "secondary.main",
                            color: "black",
                            mb: 1,
                            mt: 2,
                          }}
                        >
                          {plan.title}
                        </Typography>
                        <Box
                          sx={{
                            mb: 1,
                            mt: 5,
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "2.6em",
                              fontWeight: 600,
                              lineHeight: "1.4em",
                              // color: "lightSecondary.main",
                            }}
                          >
                            <span style={{ marginRight: "2px" }}>$</span>
                            {plan.price}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8em",
                              fontWeight: 600,
                              lineHeight: "1.3em",
                              color: "hsla(0,0%, 10%, 0.5)",
                              // color: "lightSecondary.main",
                              // mb: 1,
                              mt: 1,
                            }}
                          >
                            per <br />
                            {plan.tenure}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          color={
                            planName === plan.planName ? "secondary" : "primary"
                          }
                          sx={{
                            textTransform: "none",
                            mb: 2,
                            width: "230px",
                            height: "40px",
                            border: "8px",
                          }}
                          disabled={
                            planName === plan.planName || plan.planName === "Lite" || managePlanLoading || upgradePlanLoading
                          }
                          // onClick={()=>upgradeSubscription(plan.planName)}
                          onClick={() => {
                            if (customerId) {
                              handleManageSubscription(plan.planName); // Call this if customerId is present
                            } else {
                              upgradeSubscription(plan.productId, plan.tenure ,plan.price); // Otherwise, call this
                            }
                          }}
                        >
                          {
                             !customerId && plan.planName !== "Lite" ? "Start Free Trial" 
                            :planName === plan.planName
                            ? "Current"
                            : plan.planName === "Lite"
                            ? "- - - -"
                            : plan.buttonText}
                        </Button>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 450,
                              textAlign: "left",
                              fontSize: "0.9em",
                              color: "hsla(0,0%, 10%, 0.8)",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            This Includes:
                          </Typography>
                          <Box sx={{ textAlign: "left" }}>
                            {plan.features.map((feature, i) => (
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  // alignItems: "center",
                                  mb: 1,
                                  mt: 0.5,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: "hsla(0,0%, 10%, 0.6)",
                                    fontSize: "14px",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                    lineHeight: "1.5em",
                                    color: "hsla(0,0%, 10%, 0.8)",
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
            ) : (
              pricingTableData?.businessUserPlans[billingCycle]
              .slice(1,3)
              .map(
                (plan, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    key={index}
                    sx={{
                      mt: 2,
                      padding: "16px",
                      // backgroundColor:
                      //   plan.title == "Plus"
                      //     ? "hsla(0,0%, 10%, 0.06)"
                      //     : "#dbebf8",
                      backgroundColor:
                        plan.title == "Plus"
                          ? "hsla(0,0%, 10%, 0.06)"
                          : "#fff",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        // textAlign: "center",
                        border: "none",
                        borderRadius: "10px",
                        display: "flex",
                        width: "250px",
                        justifyContent: "center",

                        // transition: "all 0.5s ease-in-out 0s",
                        // "&:hover": {
                        //   background: "#ffffff",
                        //   boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        // },
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            fontSize: "0.8em",
                            fontWeight: 600,
                            lineHeight: "1.2em",
                            color: "hsla(0,0%, 10%, 1.1)",
                            backgroundColor: "#ffffff",
                            padding: "2px 8px",
                            // borderRadius: "2px",
                            textTransform: "none",
                            boxShadow: "none",
                            minWidth: "auto",
                            visibility:
                              plan.title == "Plus" ? "visible" : "hidden",
                            textTransform: "none",
                            // background: "linear-gradient(120deg, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.1) 70%)",
                            // background: "linear-gradient(120deg, rgba(7, 57, 133, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(7, 57, 133, 0.2) 70%)",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background:
                                "linear-gradient(150deg, rgba(245, 235, 235, 0.5) 30%, rgba(245, 245, 245, 0.8) 50%, rgba(235, 235, 235, 0.5) 70%)",
                              backgroundSize: "200% 100%",
                              animation: `${shineAnimation} 3s linear infinite`,
                              borderRadius: "inherit", // To match the parent's border-radius
                              zIndex: 1,
                              pointerEvents: "none", // Makes the shimmer non-interactive
                            },
                          }}
                        >
                          Most popular
                        </Button>
                        <Typography
                          sx={{
                            fontSize: "1.4em",
                            fontWeight: 500,
                            lineHeight: "1.2em",
                            // color: "secondary.main",
                            color: "black",
                            mb: 1,
                            mt: 2,
                          }}
                        >
                          {plan.title}
                        </Typography>
                        <Box
                          sx={{
                            mb: 1,
                            mt: 5,
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "2.6em",
                              fontWeight: 600,
                              lineHeight: "1.4em",
                              // color: "lightSecondary.main",
                            }}
                          >
                            <span style={{ marginRight: "2px" }}>$</span>
                            {plan.price}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8em",
                              fontWeight: 600,
                              lineHeight: "1.3em",
                              color: "hsla(0,0%, 10%, 0.5)",
                              // color: "lightSecondary.main",
                              // mb: 1,
                              mt: 1,
                            }}
                          >
                            per <br />
                            {plan.tenure}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          color={
                            planName === plan.planName ? "secondary" : "primary"
                          }
                          sx={{
                            textTransform: "none",
                            mb: 2,
                            width: "230px",
                            height: "40px",
                            border: "8px",
                          }}
                          disabled={
                            planName === plan.planName || plan.planName === "Lite" || managePlanLoading || upgradePlanLoading
                          }
                          // onClick={()=>upgradeSubscription(plan.planName)}
                          onClick={() => {
                            if (customerId) {
                              handleManageSubscription(plan.planName); // Call this if customerId is present
                            } else {
                              upgradeSubscription(plan.productId, plan.tenure ,plan.price); // Otherwise, call this
                            }
                          }}
                        >
                         {
                          !customerId && plan.planName !== "Lite" ? "Start Free Trial" 
                            :planName === plan.planName
                            ? "Current"
                            : plan.planName === "Lite"
                            ? "- - - -"
                            : plan.buttonText}
                        </Button>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 450,
                              textAlign: "left",
                              fontSize: "0.9em",
                              color: "hsla(0,0%, 10%, 0.8)",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            This Includes:
                          </Typography>
                          <Box sx={{ textAlign: "left" }}>
                            {plan.features.map((feature, i) => (
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  // alignItems: "center",
                                  mb: 2,
                                  mt: 1,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: "hsla(0,0%, 10%, 0.6)",
                                    fontSize: "14px",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                    lineHeight: "1.5em",
                                    color: "hsla(0,0%, 10%, 0.8)",
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )
              )
            )}
          </Grid>
          </CSSTransition>
          </SwitchTransition>
          </>
  );
};

export default SubscribeModal;
