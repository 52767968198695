import { Box, Button, ListItemAvatar, Typography } from "@mui/material";
import { getImages } from "./const";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LimitExceededModal = ({handleClose , showToastMessage , limitMessage , setOpenSubscribeModal}) => {
  const navigate = useNavigate();
  const planName = useSelector((state) => state.user.planName);
  const upgradeSubscriptionLink = process.env.LARAVEL_APP_PRICING_URL;
  const upgradeSubscription = ()=>{
    if(planName === "Lite"){
      setOpenSubscribeModal(true);
    }else{
      navigate('/manage-subscription');
    }
  }
  return (
    <>
    <Box sx={{width:'100%'}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent:'center'
        }}
      >
        <ListItemAvatar
          sx={{
            textAlign: "center",
            minWidth: "30px",
            mixBlendMode: "multiply",
          }}
        >
          <img
            style={{
              maxWidth: "55px",
              maxHeight: "55px",
              display: "block",
            }}
            src={getImages("diamond.png")}
            alt="upgrade"
          />
        </ListItemAvatar>
        </Box>
        <Box
        sx={{
          display: "flex",
          flexDirection:'column',
          gap:2,
          alignItems: "center",
          justifyContent:'center',
          mt:2
        }}
        >
        <Typography  
       sx={{
        fontSize: '1.3rem',
        color: 'secondary.main',
        fontWeight: '700',
      }}
        >
        Limit Reached
        </Typography>
        <Typography 
         sx={{
          fontSize: "0.9rem",
          color: "lightSecondary.main",
          fontWeight: "500",
        }}
        >
         {limitMessage}
        </Typography>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            gap: 2,
            width:'100%',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              textTransform: "inherit",
              width: "100%",
              borderWidth: "2px",
              fontWeight: "600",
              "&:hover": {
                borderWidth: "2px",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "inherit",
              width: "100%",
              boxShadow: "none",
              fontWeight: "600",
            }}
            onClick={upgradeSubscription}
          >
            Upgrade
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LimitExceededModal;
