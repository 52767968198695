import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, CircularProgress } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { getImages } from "../../commonComponents/const";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useDispatch, useSelector } from "react-redux";
import { setRefetchUserData } from "../../redux/features/refetch";
import { useUpgradeSubscriptionPlanMutation } from "../../redux/api/MySubscription";
const stripePromise = loadStripe("pk_test_51QjEBXCcorL04LZnSQQl93sK2InZguSYoP3HWufdZhv8MjEWEss3DQrY40Kq2uIxtfbHAfsioqH99X6ZY6wdGxSV00OQFueBue");


const StripePayment = ({newPlanName , showToastMessage , handleClose , handleOpenPaymentSuccessModal, handleClosePaymentSuccessModal}) => {
    const dispatch = useDispatch();
    const planName = useSelector((state) => state.user.planName);
    const userId = localStorage.getItem("userId");
    const userType = localStorage.getItem("userType");
    const [elements, setElements] = useState(null);
 
    const [
      upgradeSubscriptionPlan,
      { isLoading: upgradePlanLoading },
    ] = useUpgradeSubscriptionPlanMutation();
  
    useEffect(() => {
      const initializeStripe = async () => {
        const stripe = await stripePromise;
        const stripeElements = stripe.elements();
  
        setElements(stripeElements); // Save elements for later use
  
        const style = {
          base: {
            iconColor: "#c4f0ff",
            color: "#0D1A3E",
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#C8D7DE",
            },
          },
          invalid: {
            color: "#E53E3E",
            iconColor: "#E53E3E",
          },
        };
  
        // Create Stripe Elements
        const cardNumber = stripeElements.create("cardNumber", { style });
        const cardExpiry = stripeElements.create("cardExpiry", { style });
        const cardCvc = stripeElements.create("cardCvc", { style });
  
        // Mount elements
        cardNumber.mount("#card-number-element");
        cardExpiry.mount("#card-expiry-element");
        cardCvc.mount("#card-cvc-element");
  
        // Error handling
        const handleErrors = (event) => {
          const displayError = document.getElementById("payment-message");
          if (event.error) {
            displayError.style.display = "block";
            displayError.textContent = event.error.message;
          } else {
            displayError.style.display = "none";
            displayError.textContent = "";
          }
        };
  
        [cardNumber, cardExpiry, cardCvc].forEach((element) =>
          element.on("change", handleErrors)
        );
      };
  
      initializeStripe();
    }, []);
  
    const upgradeSubscription = async () => {
      if (!elements) {
        showToastMessage("Stripe is not initialized. Please try again.", "error");
        return;
      }
    
      if (newPlanName !== "Lite" || planName !== newPlanName) {
        try {
          const stripe = await stripePromise;
    
          // Get the card details elements
          const cardNumber = elements.getElement("cardNumber");
          if (!cardNumber) {
            showToastMessage("Please enter valid card details.", "error");
            return;
          }
    
          // Create a payment method
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumber,
          });
    
          if (error) {
            showToastMessage(error.message, "error");
            return;
          }
    
          // Call the API to upgrade the subscription
          const response = await upgradeSubscriptionPlan({
            userId,
            upgradePlanName: newPlanName,
            userType,
            planType: "monthly",
            paymentMethodId: paymentMethod.id
          }).unwrap();
       
          // const { clientSecret } = response.data;
    
          // // Confirm the card payment
          // const result = await stripe.confirmCardPayment(clientSecret, {
          //   payment_method: paymentMethod.id,
          // });
    
          if (response.statusCode === 200) {
            showToastMessage("Payment Successful!", "success");
            dispatch(setRefetchUserData());
            handleClose();
            handleOpenPaymentSuccessModal();
            setTimeout(()=>{
              handleClosePaymentSuccessModal();
            },3000)
          } else  {
            showToastMessage("Plan upgrade failed", "error");
          }
        } catch (error) {
          showToastMessage(error?.message || "Plan Upgrade Failed", "error");
        } 
      } else {
        showToastMessage("Plan already active.", "info");
      }
    };
    
  return (
    <Box p={3}>
      <Typography
        variant="h5"
        fontWeight="800"
        textAlign="center"
        color="secondary.main"
        mb={2}
      >
        Complete Your Payment
      </Typography>
      <form id="payment-form">
        {/* Card Option */}
        <Box sx={{display:'flex' , justifyContent:'flex-end'}}>
        {/* <Box>
          <input
            type="radio"
            id="card-option"
            name="payment-method"
            defaultChecked
          />
          <label htmlFor="card-option" style={{ marginLeft: "10px" , color:'#073985'}}>
            Card
          </label>
        </Box> */}
        {/* Card Icons */}
        <Box>
          <img src={getImages("visa.svg")} alt="Visa" style={{ height: "35px", marginLeft: "5px" }} />
          <img src={getImages("mastercard.svg")}alt="MasterCard" style={{ height: "35px", marginLeft: "5px" }} />
          <img src={getImages("american-express.svg")} alt="AMEX" style={{ height: "35px", marginLeft: "5px" }} />
          <img src={getImages("discover-card.png")}   alt="Discover" style={{ height: "35px", marginLeft: "5px" }} />
        </Box>
        </Box>
        {/* Card Number */}
        <Box mb={3}>
          <Typography variant="body1" fontWeight="600" color="secondary.main">
            Card Number
          </Typography>
          <div id="card-number-element" style={stripeElementStyle}></div>
        </Box>
        {/* Expiry and CVC */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="600" color="secondary.main">
              Expiration Date
            </Typography>
            <div id="card-expiry-element" style={stripeElementStyle}></div>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="600" color="#073985">
              Security Code
            </Typography>
            <div id="card-cvc-element" style={stripeElementStyle}></div>
          </Grid>
        </Grid>
        {/* Payment Message */}
        <Typography
          id="payment-message"
          color="error"
          mt={3}
          style={{ display: "none" }}
        ></Typography>
        {/* Submit Button */}
        <Button
  id="submit"
  variant="contained"
  color="primary"
  fullWidth
  sx={{
    mt: 3,
    "&:disabled": {
      backgroundColor: "#106EED", // Gray background for disabled state
      color: "primary", // Muted text color
    },
  }}
  onClick={upgradeSubscription}
  disabled={upgradePlanLoading} // Disable button while loading
>
  {upgradePlanLoading ? (
    <CircularProgress
      size={24}
      sx={{
        color: "white",
      }}
    />
  ) : (
    "Pay"
  )}
</Button>

      </form>
    </Box>
  );
};

// Inline Stripe Element Styles
const stripeElementStyle = {
  border: "1px solid #C8D7DE",
  borderRadius: "5px",
  padding: "10px",
  backgroundColor: "#ffffff",
};

export default StripePayment;
