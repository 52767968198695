import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  resetCreateTaskState,
  setTaskDescription,
  setTaskDueDate,
  setTaskName,
  setTaskPriority,
  setTaskStartDate,
  setTaskStatus,
} from "../../redux/features/MyProjects/createTaskSlice";
import { setRefetchProjectDetail } from "../../redux/features/refetch";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useCreateTaskMutation } from "../../redux/api/MyProjects";

const useStyles = makeStyles({
  datePicker: {
    "& > div.MuiInputBase-root > input": {
      padding: "10px 0 10px 14px !important",
      borderRadius: "4px",
      backgroundColor: "#ECF6FF",
      border: "solid 1px #DBEBF8",
      width: "100% ",
      color: "#2A589",
    },
  },
});

const AddTask = ({ handleClose, showToastMessage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  //   States
  const {
    taskName,
    taskDescription,
    taskStatus,
    taskPriority,
    taskStartDate,
    taskDueDate,
  } = useSelector((state) => state.createTaskSlice);

  const { milestoneId } = useSelector((state) => state.projectSlice);
  const { projectId } = useSelector((state) => state.projectSlice);
  // Validation state
  const [errors, setErrors] = useState({
    taskName: false,
    taskDescription: false,
    taskStatus: false,
    taskPriority: false,
    taskStartDate: false,
    taskDueDate: false,
  });

  // api

  // add task
  const [addTask, { isLoading: addTaskLoading, isError: addTaskError }] =useCreateTaskMutation();

  // Functions

  // function to handle task name
  const handleTaskName = (event) => {
    const inputValue = event.target.value;

    // Check for errors and set the corresponding message
    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskName: "This field is required.",
      }));
    } else if (inputValue.length > 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskName: "Limit exceeded",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskName: false,
      }));
    }

    dispatch(setTaskName(inputValue));
  };

  // function to handle task name
  const handleTaskDescription = (inputValue) => {
    if (inputValue.length > 250) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDescription: true,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDescription: false,
      }));
    }
    dispatch(setTaskDescription(inputValue));
  };
  // function to handle task status
  const handleTaskStatus = (event) => {
    const inputValue = event.target.value;
    dispatch(setTaskStatus(inputValue));
  };
  // function to handle task priority
  const handleTaskPriority = (event) => {
    const inputValue = event.target.value;
    dispatch(setTaskPriority(inputValue));
  };
  const handleTaskStartDate = (date) => {
    // Check if the date is valid
    if (!dayjs(date).isValid()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskStartDate: true,
      }));
      return;
    }

    // Check if selected date is before today
    if (dayjs(date).isBefore(dayjs(), "day")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskStartDate: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskStartDate: false,
        taskDueDate: dayjs(taskDueDate).isBefore(dayjs(date), "day"), // Update due date error if necessary
      }));
      // Dispatch the date as an ISO string to Redux store
      dispatch(setTaskStartDate(dayjs(date).toISOString()));
    }
  };

  const handleTaskDueDate = (date) => {
    // Check if the date is valid
    if (!dayjs(date).isValid()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: true,
      }));
      return;
    }

    // Check if selected date is before today
    if (dayjs(date).isBefore(dayjs(), "day")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: true,
      }));
    }
    // Check if due date is before start date
    else if (dayjs(date).isBefore(dayjs(taskStartDate), "day")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        taskDueDate: false,
      }));
      // Dispatch the date as an ISO string to Redux store
      dispatch(setTaskDueDate(dayjs(date).toISOString()));
    }
  };

  const createNewTask = async () => {
    let hasError = false;
    const newErrors = {
      taskName: false,
      taskStartDate: false,
      taskDueDate: false,
    };

    // Validate task name
    if (taskName.trim() === "") {
      newErrors.taskName = "This field is required.";
      hasError = true;
    } else if (taskName.length > 100) {
      newErrors.taskName = "Character limit exceeded (max 100).";
      hasError = true;
    }

    // Validate task description length
    if (taskDescription.length > 250) {
      newErrors.taskDescription = "Character limit exceeded (max 250).";
      hasError = true;
    }

    // Validate task start date
    if (!taskStartDate || dayjs(taskStartDate).isBefore(dayjs(), "day")) {
      newErrors.taskStartDate = "The start date cannot be in the past.";
      hasError = true;
    }

    // Validate task due date
    if (!taskDueDate) {
      newErrors.taskDueDate = "This field is required.";
      hasError = true;
    } else if (dayjs(taskDueDate).isBefore(dayjs(), "day")) {
      newErrors.taskDueDate = "The due date cannot be in the past.";
      hasError = true;
    } else if (dayjs(taskDueDate).isBefore(dayjs(taskStartDate), "day")) {
      newErrors.taskDueDate =
        "The due date cannot be earlier than the start date.";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    const taskDetail = [];
    taskDetail.push({
      name: taskName,
      description: taskDescription,
      start_date: taskStartDate,
      due_date: taskDueDate,
      priority: taskPriority,
      status: taskStatus,
    });

    try {
      const response = await addTask({
        projectId,
        taskDetail,
        milestoneId,
      });

      const successMessage = response?.data?.message || "Task created";
      showToastMessage(successMessage, "success");
      dispatch(setRefetchProjectDetail());
      handleClose();

      if (response?.statusCode === 500) {
        showToastMessage(successMessage, "error");
      }
    } catch (addTaskError) {
      const errorMessage = addTaskError?.message || "Failed to create task";
      showToastMessage(errorMessage, "error");
    } finally {
      dispatch(resetCreateTaskState());
    }
  };

  useEffect(() => {
    if (addTaskLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [addTaskLoading, dispatch]);

  return (
    <>
      <Grid
        container
        item
        rowSpacing={3}
        columnSpacing={5}
        xs={12}
        md={12}
        lg={12}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Task Name*
          </Typography>
          <TextField
            hiddenLabel
            id="filled-hidden-label-normal"
            variant="filled"
            value={taskName}
            onChange={handleTaskName}
            style={{
              margin: 0,
              width: "100%",
            }}
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "extraLTheme.main",
              border: "solid 1px #DBEBF8",
              m: 0,
              "& .MuiInputBase-root": {
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                fontWeight: "500",
                pt: 1.5,
                pb: 1.5,
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  border: "none",
                },
              "& .MuiInputBase-root:hover:before": {
                border: "none",
              },
              "&:hover": {
                borderColor: "#DBEBF8",
              },
              "& .MuiInputBase-root:before": {
                border: "none",
              },
              "& .MuiInputBase-root:after": {
                border: "none",
              },
              "& .MuiInputBase-root.Mui-focused .MuiIconButton-root": {
                backgroundColor: "transparent !important", // Ensure it does not inherit focus styles
              },
            }}
          />
          {errors.taskName && (
            <Typography
              variant="body2"
              color="error"
              sx={{ fontSize: "12px", mt: 0.5 }}
            >
              {errors.taskName}
            </Typography>
          )}
        </Grid>
        {/* Start Date */}
        <Grid item xs={12} md={6} lg={6} className="customDatePickerContainer">
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Start Date*
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={taskStartDate ? dayjs(taskStartDate) : null}
              onChange={handleTaskStartDate}
              className={`${classes.datePicker} selectDate`}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "extraLTheme.main",
                  "& fieldset": {
                    border: "none", // Remove the default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Ensure no border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // Ensure no border on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  backgroundColor: "extraLTheme.main", // Icon background color
                },
              }}
            />
            {errors.taskStartDate && (
              <Typography
                variant="body2"
                color="error"
                sx={{ fontSize: "12px", mt: 0.5 }}
              >
                The start date cannot be in the past
              </Typography>
            )}
          </LocalizationProvider>
        </Grid>
        {/* Due Date */}
        <Grid item xs={12} md={6} lg={6} className="customDatePickerContainer">
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Due Date*
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={taskDueDate ? dayjs(taskDueDate) : null}
              onChange={handleTaskDueDate}
              className={`${classes.datePicker} selectDate`}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  // width: "56.6ch",
                  backgroundColor: "extraLTheme.main",
                  "& fieldset": {
                    border: "none", // Remove the default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Ensure no border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // Ensure no border on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  backgroundColor: "extraLTheme.main", // Icon background color
                },
              }}
            />
            {errors.taskDueDate && (
              <Typography
                variant="body2"
                color="error"
                sx={{ fontSize: "12px", mt: 0.5 }}
              >
                {dayjs(taskDueDate).isBefore(dayjs(taskStartDate), "day")
                  ? "The due date cannot be earlier than the start date."
                  : "The due date cannot be in the past."}
              </Typography>
            )}
          </LocalizationProvider>
        </Grid>
        {/* Task Description */}
        <Grid item xs={12} md={6} lg={12} xl={12}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Task Description
          </Typography>
          {/* <TextField
            hiddenLabel
            id="filled-hidden-label-normal"
            variant="filled"
            value={taskDescription}
            onChange={handleTaskDescription}
            style={{
              margin: 0,
              width: "100%",
            }}
            sx={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "extraLTheme.main",
              border: "solid 1px #DBEBF8",
              m: 0,
              "& .MuiInputBase-root": {
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                fontWeight: "500",
                pt: 1.5,
                pb: 1.5,
                backgroundColor: "extraLTheme.main",
              },
              "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                {
                  border: "none",
                },
              "& .MuiInputBase-root:hover:before": {
                border: "none",
              },
              "&:hover": {
                borderColor: "#DBEBF8",
              },
              "& .MuiInputBase-root:before": {
                border: "none",
              },
              "& .MuiInputBase-root:after": {
                border: "none",
              },
            }}
          /> */}
          <Box
            style={{
              width: "100%",
              borderRadius: "4px",
              border: "solid 1px #DBEBF8",
              backgroundColor: "extraLTheme.main",
              marginBottom: 10,
            }}
            sx={{
              "& .ql-editor": {
                fontSize: "0.9rem",
                color: "lightSecondary.main",
                fontWeight: "500",
                width: "100%",
                maxWidth: 1000,
              },
              "& .ql-toolbar": {
                width: "100%",
                // borderRadius: "4px",
                backgroundColor: "extraLTheme.main",
                border: "solid 1px #DBEBF8",
                height: "40px",
              },
              "& .ql-formats": {
                mb: 3,
              },
              "& .ql-container": {
                width: "100%",
                // borderRadius: "4px",
                backgroundColor: "extraLTheme.main",
                border: "solid 1px #DBEBF8",
                maxWidth: "100%",
              },
            }}
          >
            <ReactQuill
              value={taskDescription}
              onChange={handleTaskDescription}
              placeholder={"Write something here..."}
              theme="snow"
              style={{
                height: "100px",
                backgroundColor: "extraLTheme.main",
                fontSize: "1.9rem",
                fontWeight: "600",
                color: "lightSecondary.main",
                borderRadius: "4px",
                width: "100%",
                maxWidth: "100%",
              }}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              formats={["bold", "italic", "underline", "list", "bullet"]}
            />
          </Box>
          {errors.taskDescription && (
            <Typography
              variant="body2"
              color="error"
              sx={{ fontSize: "12px", mt: 0.5 }}
            >
              Character limit exceeded (max 250).
            </Typography>
          )}
        </Grid>
        {/* Priority */}
        <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mt: 4 }}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Priority
          </Typography>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              IconComponent={KeyboardArrowDownOutlinedIcon}
              value={taskPriority}
              onChange={handleTaskPriority}
              sx={{
                background: "#ECF6FF",
                "& .MuiSelect-select": {
                  minHeight: "20px",
                  padding: "11px 14px",
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  border: "solid 1px #DBEBF8",
                },
                "& .MuiSelect-icon": {
                  color: "lightSecondary.main",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "300px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                },
              }}
            >
              <MenuItem value={"high"}>High</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Status */}
        <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mt: 4 }}>
          <Typography
            noWrap
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "secondary.main",
              fontWeight: "bold",
              width: "100%",
              mb: 0.5,
            }}
          >
            Status
          </Typography>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              IconComponent={KeyboardArrowDownOutlinedIcon}
              value={taskStatus}
              onChange={handleTaskStatus}
              sx={{
                background: "#ECF6FF",
                "& .MuiSelect-select": {
                  minHeight: "20px",
                  padding: "11px 14px",
                  color: "lightSecondary.main",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  border: "solid 1px #DBEBF8",
                },
                "& .MuiSelect-icon": {
                  color: "lightSecondary.main",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "300px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#9C9C9C",
                      borderRadius: "4px",
                    },
                  },
                },
              }}
            >
              <MenuItem value={"inprogress"}>In-Progress</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Submit Button */}
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            textAlign: "center",
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            disabled={addTaskLoading}
            sx={{
              textTransform: "inherit",
              p: "10px 20px",
              boxShadow: "none",
              fontWeight: "600",
              width: "200px",
            }}
            onClick={createNewTask}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddTask;
