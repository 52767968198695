import React from 'react';
import { Box, Typography, Button, Icon, ListItemAvatar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getImages } from '../../commonComponents/const';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = ({handleClose }) => {
 const navigate = useNavigate();
  const handleGoToDashboard = ()=>{
  navigate("/dashboard");
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height:'50vh'
        // padding: 3,
      }}
    >
      {/* Icon */}
     
    <ListItemAvatar
          sx={{
            textAlign: "center",
            minWidth: "30px",
            mixBlendMode: "multiply",
          }}
        >
          <img
            style={{
              maxWidth: "55px",
              maxHeight: "55px",
              filter: "invert(33%) sepia(100%) saturate(500%) hue-rotate(90deg) brightness(90%) contrast(85%)",
              display: "block",
            }}
            src={getImages("verify-check-icon.png")}
            alt="success"
          />
        </ListItemAvatar>

      {/* Thank You Message */}
      <Typography
        variant="h5"
        sx={{
          color:'secondary.main',  
          fontWeight: 'bold',
          mb: 1,
          mt:3
        }}
      >
        Subscription Activated
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'lightSecondary.main',
          mb: 4,
        }}
      >
        Your Subscription has been activated.
      </Typography>

      {/* Button */}
      <Button
        variant="contained"
        sx={{
          color: '#fff',
          fontWeight: 'bold',
          padding: '10px 20px',
          textTransform: "inherit",
        }}
        onClick={handleGoToDashboard}
      >
       Continue to Dashboard
      </Button>
    </Box>
  );
};

export default PaymentSuccess;
