import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Skeleton,
  Card,
  CardContent,
} from "@mui/material";
const ModalPlanCardSkull = ({ count = 2 }) => {
  // Generate skeletons dynamically based on the count
  return (
    <>
     <Grid
      container
      spacing={3}
      sx={{ display: "flex", gap:2, justifyContent: "center" , mt:2 }}
    >
      {Array.from({ length: count }).map((_, index) => (
           <Grid item key={index}  
           xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}>
           <Box
             sx={{
               padding: "16px",
               borderRadius: "10px",
              //  backgroundColor: "#dbebf8",
               backgroundColor: "#fff",
             }}
           >
             <Skeleton
               variant="rectangular"
               width="80%"
               height={120}
               sx={{ borderRadius: "8px" }}
             />
             <Skeleton
               variant="text"
               width="60%"
               height={30}
               sx={{ mt: 2, mb: 1 }}
             />
             <Skeleton
               variant="text"
               width="80%"
               height={20}
               sx={{ mb: 1 }}
             />
             <Skeleton
               variant="text"
               width="80%"
               height={20}
               sx={{ mb: 1 }}
             />
             <Skeleton
               variant="rectangular"
               width="100%"
               height={40}
               sx={{ mt: 2, borderRadius: "8px" }}
             />
           </Box>
         </Grid>
      ))}
      </Grid>
    </>
  );
};

export default ModalPlanCardSkull;
