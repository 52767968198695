// Import API endpoints
import deleteDriveFolder from '../api/BsiDrive/deleteDriveFolder';
import driveDocumentShare from '../api/BsiDrive/driveDocumentShare';
import createDriveFolder from '../api/BsiDrive/driveFolderCreate';
import { getDriveDocumentList } from '../api/BsiDrive/getDriveDocuments';
import getDriveItemList, { getDriveFolderList } from '../api/BsiDrive/getDriveFolderList';
import getDriveSubFolderList from '../api/BsiDrive/getDriveSubFolderList';
import MoveDocument from '../api/BsiDrive/moveFolder';
import RenameDocument from '../api/BsiDrive/renameDocument';
import UploadDocument from '../api/BsiDrive/uploadDocument';
import InboxRequirementBoardList from '../api/Inbox/getInboxRequirementBoard';
import InboxSelectedSolutionList from '../api/Inbox/getInboxSelectedSolutionList';
import getUserList from '../api/Inbox/getUserList';
import createMeetingApi from '../api/Meetings/createMeetings';
import MeetingUserList from '../api/Meetings/getMeetingUserList';
import MeetingList from '../api/Meetings/meetingList';
import quickAccessDocs from '../api/Meetings/quickAccessDocs';
import recentSolutionList from '../api/Meetings/recentSolutions';
import upComingMeetingList from '../api/Meetings/upComingMeetingList';
import deleteRequirementBoardApi from '../api/Requirement Boards/deleteBoard';
import { requirementBoardDetailApi } from '../api/Requirement Boards/getRequirementBoardDetail';
import { requirementBoardApi } from '../api/Requirement Boards/getRequirementBoardList';
import {getAllSolutionList} from '../api/Requirement Boards/getSolutionList';
import InviteSolutionProvider from '../api/Requirement Boards/inviteSolutionProvider';
import getInviteBsiColab, { inviteTeamMember } from '../api/Requirement Boards/getInviteBsiColab';
import getInviteListsDetail, { teamMemberList } from '../api/Requirement Boards/getInviteListsDetail';


import updateRequirementBoardNameApi from '../api/Requirement Boards/updateRequirementBoardName';
import updateSolutionStatusApi from '../api/Requirement Boards/updateSolutionStatus';
import deleteMeeting from '../api/Meetings/deleteMeeting';
import currentMeetingDetail from '../api/Meetings/currentMeetingDetail';
import updateMeetingDetail from '../api/Meetings/updateMeeting';
import withDrawInvitation from '../api/Requirement Boards/withdrawInvitation';
import viewRequirement from '../api/Requirement Boards/viewRequirement';
import { solutionProviderSolutionList } from '../api/SolutionProvider/solutionList';
import getBsiCoLabSolutionList from '../api/SolutionProvider/getBsiCoLabSolutionList';
import recentInvitations from '../api/Meetings/recentInvitation';
import rejectMeeting from '../api/Meetings/RejectMeeting';
import acceptMeeting from '../api/Meetings/AcceptMeeting';
import getBusinessUserRequirement from '../api/SolutionProvider/getBusinessUserRequirement';
import createGroup from '../api/Inbox/createGroup';
import getMessage from '../api/Inbox/getMessage';
import sendMessage from '../api/Inbox/sendMessage';
import getInboxBusinessUserList from '../api/SolutionProvider/Inbox/getBusinessUserList';
import SolutionProviderKanbanList from '../api/SolutionProvider/SolutionProviderKanban';
import sendFile from '../api/SolutionProvider/Inbox/sendFile';
import addGroupUsers from '../api/SolutionProvider/Inbox/addUser';
import removeGroupUsers from '../api/SolutionProvider/Inbox/removeUser';
import messageRead from '../api/Inbox/messageRead';
import { mySolutionListing } from '../api/SolutionProvider/MySolution/solutionListing';
import createSolution from '../api/SolutionProvider/MySolution/createSolution';
import { getMasterDataForSolutionProvider } from '../api/SolutionProvider/MySolution/getMaster';
import { getSolutionDetail } from '../api/SolutionProvider/MySolution/getSolutionDetail';
import updateSolutionDetail from '../api/SolutionProvider/MySolution/updateSolution';
import { getExistingSolutionName } from '../api/SolutionProvider/MySolution/checkExistingSolutionName';
import getRecentChats from '../api/Dashboard/recentChats';
import userBaseApis from '../api/Users';
import { subscriptionBaseApis } from '../api/MySubscription';
import projectBaseApis from '../api/MyProjects';


// Import other APIs here if you have any...

const apis = [
  //----------------------------------------- User-----------------------------------//
  userBaseApis,

  //---------------------------------------- Bsi-Co-Lab-------------------------------//
  requirementBoardApi,
  requirementBoardDetailApi,
  updateSolutionStatusApi,
  updateRequirementBoardNameApi,
  deleteRequirementBoardApi,
  getAllSolutionList,
  InviteSolutionProvider,
  inviteTeamMember,
  teamMemberList,
  withDrawInvitation,
  viewRequirement,

  // Bsi-Drive
  getDriveItemList,
  getDriveDocumentList,
  createDriveFolder,
  RenameDocument,
  MoveDocument,
  deleteDriveFolder,
  getDriveSubFolderList,
  UploadDocument,
  // driveDocumentShare,

  // Inbox
  InboxRequirementBoardList,
  InboxSelectedSolutionList,
  getUserList,
  createGroup,
  getMessage,
  sendMessage,
  messageRead,
  // Meetings
  createMeetingApi,
  upComingMeetingList,
  MeetingList,
  quickAccessDocs,
  recentSolutionList,
  MeetingUserList,
  deleteMeeting,
  currentMeetingDetail,
  updateMeetingDetail,
  recentInvitations,
  acceptMeeting,
  rejectMeeting,

  //----------------------------------------------------- Projects---------------------------------------//

  projectBaseApis,

  //----------------------------------------------------- Dashboard----------------------------------------//
  
  getRecentChats,

  //----------------------------------------------------- Subscription-------------------------------------//
  
  subscriptionBaseApis,

  // SolutionProvider Manage user
  solutionProviderSolutionList,

  // SolutionProviderBsiCoLab
  getBsiCoLabSolutionList,
  SolutionProviderKanbanList,
  getBusinessUserRequirement,

  // solution provider inbox
  getInboxBusinessUserList,
  sendFile,
  addGroupUsers,
  removeGroupUsers,

  // solution provider my solution
  mySolutionListing,
  createSolution,
  getMasterDataForSolutionProvider,
  getSolutionDetail,
  updateSolutionDetail,
  getExistingSolutionName,
];

// Export an array of API middlewares
export const apiMiddlewares = apis.map((api) => api.middleware);

// Combine all API reducers into a single object
export const apiReducers = apis.reduce((reducers, api) => {
  reducers[api.reducerPath] = api.reducer;
  return reducers;
}, {});