// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
milestoneData:"",    
milestoneTitle : "",
milestoneDescription: "",
milestoneStatus : "",
};

const editMilestoneSlice = createSlice({
  name: "editMilestone",
  initialState,
  reducers: {
   setMilestoneData(state, action){
    const data = action.payload;
    state.milestoneTitle = data?.name || "";
    state.milestoneDescription = data?.description || "";
    // state.milestoneStatus = data?.milestoneStatus || ""
   },
   setMilestoneTitle(state , action ) {
   state.milestoneTitle = action.payload;
   },
   setMilestoneDescription(state , action ) {
   state.milestoneDescription = action.payload;
   },
   setMilestoneStatus(state , action ) {
   state.milestoneStatus = action.payload;
   },
  }
});

export const {
  setMilestoneData,
  setMilestoneTitle,
  setMilestoneDescription,
  setMilestoneStatus
} = editMilestoneSlice.actions;
export default editMilestoneSlice.reducer;
