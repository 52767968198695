import React, { useState, useEffect } from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider
} from "react-router-dom";
import App from "../App";
import Dashboard from "../pages/Dashboard";
import ManageTeamMembers from "../pages/ManageTeamMembers";
import EditTeamMembers from "../pages/ManageTeamMembers/EditTeamMembers";
import InviteSolutionProvider from "../pages/BsiCoLab/InviteSolutionProvider";
import Inbox from "../pages/Inbox";
import Kanban from "../pages/Kanban";
import FrontSteps from "../pages/Frontend/FrontSteps";
import SolutionListing from "../pages/Frontend/SolutionListing";
import BsiDrive from "../pages/BsiDrive";
import Meetings from "../pages/Meetings";
import Profile from "../pages/Profile";
import BusinessProfile from "../pages/Profile/BusinessProfile";
import MySolutions from "../pages/MySolutions";
import EditSolutionDetails from "../commonComponents/MySolutions/EditSolutionDetails";
import SolutionInvestmentSummary from "../commonComponents/MySolutions/SolutionInvestmentSummary";
import BsiCoLabSolutionEnquiries from "../pages/BsiCoLab/BsiCoLabSolutionEnquiries";
import KanbanSolutionInquiries from "../pages/Kanban/KanbanSolutionInquiries";
import BSICoLabInvestmentEnquiries from "../pages/BsiCoLab/BSICoLabInvestmentEnquiries";
import KanbanInvestmentEnquiries from "../pages/Kanban/KanbanInvestmentEnquiries";
import Portfolio from "../pages/Portfolio";
import BsiCoLabContent from "../pages/BsiCoLab";
import BsiCoLab from "../pages/BsiCoLab";
import BsiSubDrive from "../commonComponents/BsiDrive/BsiSubDrive";
import BsiColabRequirement from "../pages/BsiCoLab/BsiColabRequirement";
import SolutionProviderBsiCoLab from "../pages/BsiCoLab/SolutionProviderBsiCoLab";
import SolutionProviderKanban from "../pages/Kanban/SolutionProviderKanban";
import BusinessUserRequirement from "../pages/Kanban/businessUserRequirement";
import CreateSolution from "../pages/MySolutions/createSolution";
import EditSolution from "../pages/MySolutions/editSolution";
import MySubscription from "../pages/MySubscription";
import { useDispatch } from "react-redux";
import { getToken } from "../redux/features/userSlice";
import AddProject from "../pages/MyProjects/addProject";
import MyProjects from "../pages/MyProjects";
import ProjectDetail from "../pages/MyProjects/projectDetail";
import AddMileStone from "../pages/MyProjects/addMileStone";
import EditMileStone from "../pages/MyProjects/EditMileStone";

function MainRoutesWrapper() {
    const [userType, setUserType] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch;
    useEffect(() => {
        const checkUserType = () => {
            const storedUserType = localStorage.getItem("userType");
            if (storedUserType) {
                setUserType(storedUserType);
                setLoading(false);
            }
        };

        // Initial check
        checkUserType();

        // Set an interval to keep checking
        const intervalId = setInterval(() => {
            checkUserType();
        }, 1000); // Check every second

        // Cleanup interval on component unmount or when userType is set
        return () => clearInterval(intervalId);
    }, []);

    const userId = localStorage.getItem("userId");
    // Getting Id from URL if user is redirected here
    const queryParams = new URLSearchParams(window.location.search);
    const userIdFromAPI = queryParams.get("user");
    const token = queryParams.get("token");
    // if(token == null || token == undefined){
    //     const laravelWebUrl =  process.env.REACT_APP_LARAVEL_WEB_URL;
    //     window.location.href = laravelWebUrl;
    // }
    if(token != null || token != undefined){
        dispatch((getToken(token)))
    }else{
        dispatch((getToken(null)))
    }

    if (loading) {
        const MainRoutes = createBrowserRouter([
            {
                path: "*",
                element: <App />,
                
            },
        ]);
        return <RouterProvider router={MainRoutes} />;

    }else{

        const MainRoutes = createBrowserRouter([
            {
                path: "*",
                element: <App />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="bsi-co-lab" replace />
                    },
                    {
                        path: "ask-for-technology",
                        element: <FrontSteps />,
                    },
                    {
                        path: "solution-listing",
                        element: <SolutionListing />,
                    },
                    {
                        path: "dashboard",
                        element: <Dashboard />,
                    },
                    {
                        path: "manage-users",
                        element: <ManageTeamMembers />,
                    },
                    {
                        path: "edit-team-members",
                        element: <EditTeamMembers />,
                    },
                    {
                        path: "solution-enquiries",
                        element: <BsiCoLabSolutionEnquiries />,
                    },
                    {
                        path: "investment-enquiries",
                        element: <BSICoLabInvestmentEnquiries />,
                    },
                    {
                        path: "bsi-co-lab",
                        element: userType === "solution_provider" ? <SolutionProviderBsiCoLab /> : <BsiCoLab />
                    },
                    {
                        path: "bsi-co-lab-requirements",
                        element: userType === "solution_provider" ? <BusinessUserRequirement/> : <BsiColabRequirement/>
                    },
                    {
                        path: "bsi-co-lab/kanban",
                        element: userType === "solution_provider" ? <SolutionProviderKanban /> : <Kanban />
                    },
                    {
                        path: "invite-solution-provider",
                        element: <InviteSolutionProvider />,
                    },
                    {
                        path: "inbox",
                        element: <Inbox />,
                    },
                    {
                        path: "meetings",
                        element: <Meetings />,
                    },
                    {
                        path: "bsi-drive",
                        element: <BsiDrive />,
                    },
                    {
                        path: "bsi-sub-drive",
                        element: <BsiSubDrive />,
                    },
                    {
                        path: "profile",
                        element: <Profile />,
                    },
                    {
                        path: "manage-subscription",
                        element: <MySubscription />,
                    },
                    {
                        path: "profile-business",
                        element: <BusinessProfile />,
                    },
                    {
                        path: "my-solutions",
                        element: <MySolutions />,
                    },
                    {
                        path: "create-solution",
                        element: <CreateSolution/>,
                    },
                    {
                        path: "edit-solution",
                        element: <EditSolution/>,
                    },
                    {
                        path: "edit-solution-details",
                        element: <EditSolutionDetails />,
                    },
                    {
                        path: "solution-investment-summary",
                        element: <SolutionInvestmentSummary />,
                    },
                    {
                        path: "kanban-solution-enquiries",
                        element: <KanbanSolutionInquiries />,
                    },
                    {
                        path: "kanban-investment-enquiries",
                        element: <KanbanInvestmentEnquiries />,
                    },
                    {
                        path: "portfolio",
                        element: <Portfolio />,
                    },
                    {
                        path: "add-project",
                        element: <AddProject/>,
                    },
                    {
                        path: "projects",
                        element: <MyProjects/>,
                    },
                    {
                        path: "project-detail",
                        element: <ProjectDetail/>,
                    },
                    {
                        path: "add-mile-stone",
                        element: <AddMileStone/>,
                    },
                    {
                        path: "edit-mile-stone",
                        element: <EditMileStone/>,
                    },
                ],
            },
        ]);
            return <RouterProvider router={MainRoutes} />;

    
    }
    

 
}

export default MainRoutesWrapper;
