import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Add, Create } from "@mui/icons-material";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { setProjectId } from "../../redux/features/MyProjects/projectSlice";
import { getImages } from "../const";

const NoMilestoneScreen = ({ handleCreateMilestone }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      // minHeight="calc(100% - 164px)"
      textAlign="center"
      // padding={3}
    >
      <img
        src={getImages("no_milestone.png")} // Replace with your image path
        // alt="No Data"
        style={{
          width: "150px",
          height: "130px",
          // marginBottom: "20px",
          borderRadius: 8,
          mixBlendMode:'multiply'
        }} // Adjust the size as needed
      />
      <Typography
        variant="h6"
        sx={{
          color: "secondary.main",
          fontWeight: 600,
        }}
        gutterBottom
      >
        No milestone added yet
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "lightSecondary.main",
          fontWeight: 500,
        }}
        gutterBottom
      >
        You can start by creating a new milestone
      </Typography>

      <Button
        onClick={handleCreateMilestone}
        variant="contained"
        sx={{
          textTransform: "inherit",
          mt: 1,
          py: "11px",
          boxShadow: "none",
          px: 8.5,
          "&:hover": {
            boxShadow: "none",
          },
          [theme.breakpoints.down("md")]: {
            px: 2.5,
            width: "100%",
          },
        }}
      >
        <AddIcon
          sx={{
            fontSize: "20px",
            marginRight: "8px",
          }}
        />{" "}
        Create New Milestone
      </Button>
    </Box>
  );
};

export default NoMilestoneScreen;
