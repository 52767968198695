// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
projectId: "",
encodedProjectId: "",
projectName: "",
milestoneId: "",
taskId: "",
projectDetailData: [],
};

const projectSlice = createSlice({
  name: "projectSlice",
  initialState,
  reducers: {
  setProjectId(state , action){
    state.projectId = action.payload
  },
  setEncodedProjectId(state , action){
    state.encodedProjectId = action.payload
  },
  setProjectName(state , action){
    state.projectName = action.payload
  },
  setMilestoneId(state , action){
    state.milestoneId = action.payload
  },
  setTaskId(state , action){
    state.taskId = action.payload
  },
  setProjectDetailData(state , action){
    state.projectDetailData = action.payload
  },
  reorderMilestones(state, action) {
    const { sourceIndex, destinationIndex } = action.payload;
    // Remove the item from the source position
    const [movedMilestone] = state.projectDetailData.splice(sourceIndex, 1);

    // Insert it at the destination position
    state.projectDetailData.splice(destinationIndex, 0, movedMilestone);
  },
  reorderTasks(state, action) {
    const { sourceMilestoneIndex, destinationMilestoneIndex } = action.payload;
    const [movedTasks] = state.projectDetailData?.map((milestone , index)=> milestone.tasks.splice(sourceMilestoneIndex , 1))
      // Insert it at the destination position
      state.projectDetailData?.map((milestone , index)=> milestone.tasks.splice(destinationMilestoneIndex, 0, movedTasks));
  }
  }
});

export const {
    setProjectId,
    setProjectName,
    setMilestoneId,
    setTaskId,
    setProjectDetailData,
    reorderMilestones,
    reorderTasks,
    setEncodedProjectId
} = projectSlice.actions;
export default projectSlice.reducer;
