import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import MainCard from "../../commonComponents/MainCard";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getImages } from "../../commonComponents/const";
import { useTheme } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import {
  getBoardId,
  getBoardName,
  getIndustryName,
} from "../../redux/features/boardSlice";
import { useDispatch, useSelector } from "react-redux";
import RecentResponsesSkull from "../Skeleton/RecentResponsesSkull";
import NoRecentResponsesScreen from "../NoDataScreens/NoRecentResponsesPage";

const RecentEnquiries = ({
  userOverviewData,
  userOverviewLoading,
  userOverviewError,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const handleViewAllClick = () => {
    navigate("/bsi-co-lab");
  };
  const getInitials = (name) => {
    if (!name) return ""; // Handle empty or undefined name

    const namesArray = name.trim().split(" ");

    if (namesArray.length === 1) {
      // Single name: return the first two characters
      return namesArray[0].slice(0, 2).toUpperCase();
    }

    // Two or more names: return the first character of the first two words
    const firstInitial = namesArray[0].charAt(0).toUpperCase();
    const secondInitial = namesArray[1].charAt(0).toUpperCase();

    return firstInitial + secondInitial;
  };
  const handleOnClick = (boardId, boardName, industryName) => {
    dispatch(getBoardId(boardId));
    dispatch(getBoardName(boardName));
    dispatch(getIndustryName(industryName));
    navigate("/bsi-co-lab/kanban");
  };

  const handleBusinessCardClick = (solutionId, solutionName) => {
    localStorage.setItem("solutionId", solutionId);
    localStorage.setItem("solutionName", solutionName);
    navigate("/bsi-co-lab/kanban");
  };

  return (
    <>
      <MainCard
        content={false}
        sx={{
          backgroundColor: "#ECF6FF",
          border: "none",
          padding: "15px 25px",
          minHeight: isDrawerOpen ? "470px" : "420px",
          // minHeight: userType === "solution_provider" 
          // ? (isDrawerOpen ? "510px" : "460px") 
          // : (isDrawerOpen ? "470px" : "420px"),
          [theme.breakpoints.down("md")]: {
            padding: "15px",
          },
        }}
      >
        <Box sx={{ p: 0, pb: 0 }}>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "inherit",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "secondary.main",
                marginBottom: "1rem",
              }}
            >
              Recent Responses
            </Typography>
            <Button
              onClick={handleViewAllClick}
              variant="text"
              style={{ marginTop: "0" }}
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                textTransform: "inherit",
              }}
            >
              View all{" "}
              <ArrowForwardIosOutlinedIcon
                sx={{
                  marginLeft: "5px",
                  fill: "primary.main",
                  width: "12px",
                  height: "12px",
                }}
              />
            </Button>
          </Stack>
          <List
            component="nav"
            sx={{
              px: 0,
              py: 0,
              "& .MuiListItemButton-root": {
                py: 1.5,
              },
            }}
          >
            {userOverviewData?.data?.responded.length > 0 ? (
              userOverviewData?.data?.responded.slice(0,4).map(
                (respondedSolutions, index) =>
                  userType == "solution_provider" ? (
                    <ListItemButton
                      onClick={() =>
                        handleBusinessCardClick(
                          respondedSolutions?.solution_id,
                          respondedSolutions?.solution_name
                        )
                      }
                      key={respondedSolutions?._id}
                      sx={{
                        minHeight: isDrawerOpen ? "90px" : "78px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "inherit",
                          alignItems: "center",
                          width: "60%",
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            width: "56px",
                          }}
                        >
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "success.lighter",
                              width: "50px",
                              height: "50px",
                            }}
                            src={respondedSolutions?.userProfile?.business_logo}
                            alt={"logo"}
                          ></Avatar>
                        </ListItemAvatar>
                        <Typography
                          variant="subtitle1"
                          noWrap
                          sx={{
                            fontSize: "0.95rem",
                            color: "secondary.main",
                            fontWeight: "600",
                            lineHeight: "20px",
                            // maxWidth: "100px"
                          }}
                        >
                          {respondedSolutions?.userProfile?.business_name}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.95rem",
                          color: "primary.main",
                          fontWeight: "600",
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {getInitials(
                          respondedSolutions?.userProfile?.business_name
                        )}
                      </Typography>
                      {/* <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 13</Typography> */}
                      <ArrowForwardIosOutlinedIcon
                        sx={{
                          fill: "primary.main",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      onClick={() =>
                        handleOnClick(
                          respondedSolutions?.board_id?._id,
                          respondedSolutions?.board_id?.board_name,
                          respondedSolutions?.industry_name
                        )
                      }
                      key={respondedSolutions?.board_id?._id}
                      sx={{
                        minHeight: isDrawerOpen ? "75px" : "65px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        marginTop: "5px",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "inherit",
                          alignItems: "center",
                          width: "60%",
                        }}
                      >
                        <ListItemAvatar
                          sx={{
                            display: "inline-block",
                            marginRight: "7px",
                          }}
                        >
                          {/* <img src={respondedSolutions?.solution_id?.logo} /> */}
                          <img
                            style={{
                              width: "80px",
                              height: "30px",
                              mixBlendMode: "multiply",
                            }}
                            src={`${logoBaseUrl}${respondedSolutions?.solution_id?.logo}`}
                            alt={respondedSolutions?.solution_id?.slug}
                          />
                        </ListItemAvatar>
                        <Typography
                          variant="subtitle1"
                          noWrap
                          sx={{
                            fontSize: "0.95rem",
                            color: "secondary.main",
                            fontWeight: "600",
                            lineHeight: "20px",
                            // maxWidth: "100px"
                          }}
                        >
                          {respondedSolutions.solution_name}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "0.95rem",
                          color: "primary.main",
                          fontWeight: "600",
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {respondedSolutions?.board_id?.board_title}
                      </Typography>
                      {/* <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 13</Typography> */}
                      <ArrowForwardIosOutlinedIcon
                        sx={{
                          fill: "primary.main",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    </ListItemButton>
                  )
              )
            ) : userOverviewLoading ? (
              <RecentResponsesSkull />
            ) : (
               <>
              
               <Grid item xs={12}>
                     <Typography
                       variant="h6"
                       color="textSecondary"
                       sx={{
                         fontSize: "1.2rem",
                         fontWeight: "600",
                         color: "secondary.main",
                         textAlign: "center",
                         // mt: 2,
                         py: "14%",
                         [theme.breakpoints.down("lg")]: {
                           py: "14%",
                         },
                         [theme.breakpoints.down("md")]: {
                           py: "24%",
                         },
                         [theme.breakpoints.down("sm")]: {
                           py: "24%",
                         },
                       }}
                     >
                       <NoRecentResponsesScreen/>
                     </Typography>
                   </Grid>
               </>
            )}

            {/* <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_154.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>CIAM - Customer Identity & Access Management</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>LR</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 27</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_53.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>Advanced Solutions in Digital Healthcare</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>DHR</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 69</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_155.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>Blockpass Identity for a Connected World</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>IB1</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 04</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton>
                        <ListItemButton sx={{
                            minHeight: "65px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            marginTop: "5px"
                        }}>
                            <Stack sx={{
                                flexDirection: "inherit",
                                alignItems: "center",
                                width: "60%"
                            }}>
                                <ListItemAvatar sx={{
                                    display: "inline-block",
                                    marginRight: "7px"
                                }}>
                                    <img src={getImages('image_153.png')} />
                                </ListItemAvatar>
                                <Typography variant="subtitle1" sx={{
                                    fontSize: "0.95rem",
                                    color: "secondary.main",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    maxHeight: '40px',
                                    overflow: 'hidden'
                                }}>Polysign</Typography>
                            </Stack>
                            <Typography variant="subtitle1" sx={{
                                fontSize: "0.95rem",
                                color: "primary.main",
                                fontWeight: "600",
                                width: "10%",
                                textAlign: "center"
                            }}>SCE</Typography>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon sx={{
                                    verticalAlign: "middle",
                                    fill: "ltheme.main",
                                    width: "20px",
                                    height: "20px"
                                }} /> 69</Typography>
                            <ArrowForwardIosOutlinedIcon sx={{
                                fill: "primary.main",
                                width: "18px",
                                height: "18px"
                            }} />
                        </ListItemButton> */}
          </List>
        </Box>
      </MainCard>
    </>
  );
};

export default RecentEnquiries;
