import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../commonComponents/MainCard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getImages } from "../../commonComponents/const";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import CancelIcon from "@mui/icons-material/Cancel";
// Dropdown Menu
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@emotion/react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFetchQuickDocumentListQuery } from "../../redux/api/Meetings/quickAccessDocs";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useDispatch } from "react-redux";
import { useFetchDriveItemListQuery } from "../../redux/api/BsiDrive/getDriveFolderList";
import DocumentSkeletonLoader from "../Skeleton/RecentDocumentSkull";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NoDocumentScreen from "../NoDataScreens/NoDocumentScreen";
const ITEM_HEIGHT = 48;
const ImportantDocuments = ({showToastMessage}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleViewAllClick = () => {
    navigate("/bsi-drive");
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#DBEBF8",
    "&:hover": {
      backgroundColor: "#DBEBF8",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex", // Ensure the children are aligned properly
    alignItems: "center", // Center the icons and input vertically
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`, // Add padding for icons
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  // Dropdown Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");
  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const open = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // Api call
//   const {
//     data: recentDocData,
//     isLoading: recentDocLoading,
//     error: recentDocError,
//     refetch: refetchRecentDoc
//   } = useFetchQuickDocumentListQuery({searchParam: searchValue});
const {
    data: driveItemData,
    error: driveItemError,
    isLoading: isDriveItemLoading,
    refetch: refetchDocumentData,
  } = useFetchDriveItemListQuery({
    searchParams: searchValue,
    sortOption: "asc",
  });
  
  // Functions
  const handleClick = (event, documentUrl , documentName) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocumentUrl(documentUrl); 
    setSelectedDocumentName(documentName);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDocumentUrl(null); 
    setSelectedDocumentName(null); 
  };
  const handleDownload = async () => {
    try {
      // Fetch the file from the server
      const response = await fetch(selectedDocumentUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Set the href of the link to the blob URL
      link.href = url;

      // Choose the filename for the downloaded file
      link.download = selectedDocumentName || "downloaded-file"; // Ensure documentName is a string

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link from the DOM
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Free up memory
      }, 100); // Delay removal to ensure click event has been processed
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue("");
  };

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };
  const handleDocumentSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue("");
        refetchDocumentData();
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        await refetchDocumentData();
        if (driveItemError) {
          const errorMessage = driveItemData?.message || "No Data Found";
          showToastMessage(errorMessage, "error");
          return;
        }
      } catch (error) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };

  useEffect(()=>{
    refetchDocumentData();
  },[])
  return (
    <>
      <MainCard
        content={false}
        sx={{
          backgroundColor: "#ECF6FF",
          border: "none",
          padding: "15px 25px 7px",
          minHeight: "450px",
          [theme.breakpoints.down("md")]: {
            padding: "15px",
          },
        }}
      >
        <Box sx={{ p: 0, pb: 0 }}>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "inherit",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "secondary.main",
                marginBottom: "1rem",
              }}
            >
              Important Documents
            </Typography>
            <Button
              onClick={handleViewAllClick}
              variant="text"
              style={{ marginTop: "0" }}
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                textTransform: "inherit",
              }}
            >
              View all{" "}
              <ArrowForwardIosOutlinedIcon
                sx={{
                  marginLeft: "5px",
                  fill: "primary.main",
                  width: "12px",
                  height: "12px",
                }}
              />
            </Button>
          </Stack>
          <Search>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchParams}
                onChange={handleInputChange}
                onKeyDown={handleDocumentSearch}
                autoFocus
              />
              {searchParams ? (
                <IconButton
                  sx={{ position: "absolute", right: 0 }}
                  onClick={handleClearSearch}
                >
                  <CancelIcon sx={{ color: "lightSecondary.main" }} />
                </IconButton>
              ) : (
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: "lightSecondary.main" }} />
                </SearchIconWrapper>
              )}
            </Search>

          <Grid container mt={0.5} rowSpacing={0.1} columnSpacing={1}>
            
          {
            
           driveItemData?.data?.documents.length > 0 ?
           (
          driveItemData?.data?.documents?.slice(0, 10).map((document , index) => {
          const fileExtension = document.name
          ? document.name.split(".").pop().toLowerCase()
          : "";
        // const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
        const isJPG = fileExtension === "jpg";
        const isJPEG = fileExtension === "jpeg";
        const isPNG = fileExtension === "png";
        const isPDF = fileExtension === "pdf";
        const isGif = fileExtension === "gif";
        const isDocx = fileExtension === "docx";
        const isDoc = fileExtension === "doc";
        const isMp4 = fileExtension === "mp4";
        const isAvi = fileExtension === "avi";
        const isMov = fileExtension === "mov";
        const isWmv = fileExtension === "wmv";
        const isMp3 = fileExtension === "mp3";
        const isWav = fileExtension === "wav";
        const isOgg = fileExtension === "ogg";
        const xlsx = fileExtension === "xlsx";
        const csv = fileExtension === "csv";

          return(
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={
                        isPNG
                          ? getImages("png.png")
                          :
                          isJPG
                          ? getImages("jpg.png")
                          :isJPEG
                          ? getImages("img.png")
                          : isPDF
                          ? getImages("pdf.png")  // Replace with your PDF icon path
                          : isGif
                          ? getImages("file.png")
                          : isDocx
                          ? getImages("doc.png")
                          : isDoc
                          ? getImages("doc.png")
                          : isMp4
                          ?  getImages("mp4.png")
                          : isAvi
                          ? getImages("file.png")
                          : isMov
                          ? getImages("file.png")
                          : isWav
                          ? getImages("file.png")
                          : isMp3
                          ? getImages("mp3.png")
                          : // : isWav
                          // ? getImages("wav.png")
                          isOgg
                          ? getImages("file.png")
                          : xlsx
                          ? getImages("xls.png")
                          : csv
                          ? getImages("csv.png")
                          : getImages("file.png") // Replace with your default icon path
                      }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      <Tooltip title={document.name}>
                          {document.name}
                        </Tooltip>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      {fileExtension} 
                      {/* •  {fileSize !== undefined
                      ? `${(fileSize / 1024 ).toFixed(2)} MB`
                      : '...'} */}
                    
                    </Typography>
                  }
                />
                <div>
          <IconButton
            aria-label="more"
            id={`long-button-${index}`}
            aria-controls={anchorEl ? "long-menu" : undefined}
            aria-expanded={anchorEl ? "true" : undefined}
            aria-haspopup="true"
            onClick={(event) => handleClick(event,document.s3Url , document.name  )}
          >
            <MoreVertIcon sx={{ width: "20px", height: "15px" }} />
          </IconButton>
          <Menu
            id={`long-menu-${index}`}
            MenuListProps={{
              "aria-labelledby": `long-button-${index}`,
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 1.5, // Adjust max height
                width: "18ch",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // Add shadow
              },
            }}
          >

              <MenuItem
              sx={{
                fontSize: "0.9rem",
                fontWeight: "600",
                lineHeight: "20px",
                color: "lightSecondary.main",
                py: 1,
                px: 1.4,
                "&:hover": {
                  backgroundColor: "extraLTheme.main",
                },
                "&.active": {
                  backgroundColor: "primary.main",
                  color: "#fff",
                  borderRadius: "10px",
                },
              }}
              onClick={() => handleDownload()}
            >
              <FileDownloadOutlinedIcon sx={{ mr: 1 }} />{" "}
              Download
            </MenuItem>
            
          </Menu>
        </div>
              </ListItem>
            </Grid>
          )}))
          :
          (
            <>
            {
                isDriveItemLoading ? 
                (
                <DocumentSkeletonLoader/>
                ):(
                    <Grid item xs={12}>
                    <Box
                      variant="h6"
                      color="textSecondary"
                      sx={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        textAlign: "center",
                        // mt: 2,
                        py: "21%",
                        [theme.breakpoints.down("lg")]: {
                          py: "21%",
                        },
                        [theme.breakpoints.down("md")]: {
                          py: "31%",
                        },
                        [theme.breakpoints.down("sm")]: {
                          py: "31%",
                        },
                      }}
                    >
                      <NoDocumentScreen/>
                    </Box>
                  </Grid>
                )
            }
            </>
          )
        
        }
            {/* <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("doc_icon.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Service description
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Doc • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("jpeg.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Finance state
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Jpeg • 3.8 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("video_icon.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Solution details
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Mp4 • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("pdf_icon.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Steps
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Mp4 • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("pdf_icon.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      BSI Terms
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Mp4 • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("doc_icon.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Network
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Doc • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("Vector.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Pitch call 2023-03-21
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Aac • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("jpeg.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Downloaded vector-art
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Png • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ListItem sx={{ px: "0" }}>
                <ListItemAvatar sx={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                    src={getImages("doc_icon.png")}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "secondary.main",
                        lineHeight: "22px",
                      }}
                      variant="subtitle1"
                    >
                      Project proposal
                    </Typography>
                  }
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "ltheme.main",
                        width: "100%",
                      }}
                    >
                      Doc • 21.5 mb
                    </Typography>
                  }
                />
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </ListItem>
            </Grid> */}
          </Grid>
          {/* </List> */}
        </Box>
      </MainCard>
    </>
  );
};

export default ImportantDocuments;
