import React, { useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { UserAddOutlined } from "@ant-design/icons";
import InvitetoBSIColab from "../Inbox/InvitetoBSIColab";
import { setRefetchTeamMemberList } from "../../redux/features/refetch";
import Modals from "../Modals";
import { getImages } from "../const";

const NoTeamMemberScreen = ({ InviteBsiCoLabHandle }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="calc(100% - 164px)"
        textAlign="center"
        padding={3}
      >
        <img
          src={getImages("no_team_member.webp")} // Replace with your image path
          // alt="No Data"
          style={{
            width:'90px',
            height:'90px',
          // marginBottom: "20px",
            borderRadius: 8,
            mixBlendMode: "multiply",
          }} // Adjust the size as needed
        />
        <Typography
          variant="h6"
          sx={{
            color: "secondary.main",
            fontWeight: 600,
          }}
          gutterBottom
        >
          No team member added yet
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "lightSecondary.main",
            fontWeight: 500,
          }}
          gutterBottom
        >
          You can start by inviting a new team member
        </Typography>

        <Button
          onClick={InviteBsiCoLabHandle}
          variant="contained"
          sx={{
            textTransform: "inherit",
            mt: 1,
            py: "11px",
            boxShadow: "none",
            px: 12.5,
            "&:hover": {
              boxShadow: "none",
            },
            [theme.breakpoints.down("md")]: {
              px: 2.5,
              width: "100%",
            },
          }}
        >
          <UserAddOutlined
            style={{
              width: "20px",
              height: "20px",
              marginRight: "1px",
              // marginTop: "4px",
            }}
          />{" "}
          Invite to BSI Co-lab
        </Button>
      </Box>
    </>
  );
};

export default NoTeamMemberScreen;
