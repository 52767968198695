// src/features/folderSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const refetchSlice = createSlice({
  name: "refetch",
  initialState: {
    refetchUserData: false,
    refetchSubFolder: false,
    refetchDocument: false,
    refetchKanbanBoard: false,
    refetchSolutionProviderKanbanBoard: false,
    refetchRequirementBoard: false,
    refetchMeetingList: false,
    refetchQuickDocsList: false,
    refetchRecentSolutionList: false,
    refetchTeamMemberList: false,
    refetchBusinessUserList: false,
    refetchSolutionList: false,
    refetchMySolutionList: false,
    refetchProjectList: false,
    refetchProjectDetail: false,
  },
  reducers: {
    setRefetchUserData: (state) => {
      state.refetchUserData = true;
    },
    setRefetchSubFolder: (state) => {
      state.refetchSubFolder = true;
    },
    setRefetchDocument: (state) => {
      state.refetchDocument = true;
    },
    setRefetchRequirementBoard: (state) => {
      state.refetchRequirementBoard = true;
    },
    setRefetchKanbanBoard: (state) => {
      state.refetchKanbanBoard = true;
    },
    setRefetchSolutionProviderKanbanBoard: (state) => {
      state.refetchSolutionProviderKanbanBoard = true;
    },
    setRefetchMeetingList: (state) => {
      state.refetchMeetingList = true;
    },
    setRefetchQuickDocsList: (state) => {
      state.refetchQuickDocsList = true;
    },
    setRefetchTeamMemberList: (state) => {
      state.refetchTeamMemberList = true;
    },
    setRefetchRecentSolutionList: (state) => {
      state.refetchRecentSolutionList = true;
    },
    setRefetchBusinessUserList: (state) => {
      state.refetchBusinessUserList = true;
    },
    setRefetchSolutionList: (state) => {
      state.refetchSolutionList = true;
    },
    setRefetchMySolutionList: (state) => {
      state.refetchMySolutionList = true;
    },
    setRefetchProjectList: (state) => {
      state.refetchProjectList = true;
    },
    setRefetchProjectDetail: (state) => {
      state.refetchProjectDetail = true;
    },
    resetRefetchUserData: (state) => {
      state.refetchUserData = false;
    },
    resetRefetchDocument: (state) => {
      state.refetchDocument = false;
    },
    resetRefetchSubFolder: (state) => {
      state.refetchSubFolder = false;
    },
    resetRefetchKanbanBoard: (state) => {
      state.refetchKanbanBoard = false;
    },
    resetRefetchSolutionProviderKanbanBoard: (state) => {
      state.refetchSolutionProviderKanbanBoard = false;
    },
    resetRefetchRequirementBoard: (state) => {
      state.refetchRequirementBoard = false;
    },
    resetRefetchMeetingList: (state) => {
      state.refetchMeetingList = false;
    },
    resetRefetchQuickDocsList: (state) => {
      state.refetchQuickDocsList = false;
    },
    resetRefetchRecentSolutionList: (state) => {
      state.refetchRecentSolutionList = false;
    },
    resetRefetchTeamMemberList: (state) => {
      state.refetchTeamMemberList = false;
    },
    resetRefetchBusinessUserList: (state) => {
      state.refetchBusinessUserList = false;
    },
    resetRefetchSolutionList: (state) => {
      state.refetchSolutionList = false;
    },
    resetRefetchMySolutionList: (state) => {
      state.refetchMySolutionList = false;
    },
    resetRefetchProjectList: (state) => {
      state.refetchProjectList = false;
    },
    resetRefetchProjectDetail: (state) => {
      state.refetchProjectDetail = false;
    },
  },
});

export const {
  setRefetchUserData,
  resetRefetchBusinessUserList,
  resetRefetchSolutionList,
  setRefetchBusinessUserList,
  setRefetchSolutionList,
  setRefetchTeamMemberList,
  resetRefetchTeamMemberList,
  setRefetchSolutionProviderKanbanBoard,
  resetRefetchSolutionProviderKanbanBoard,
  setRefetchRecentSolutionList,
  resetRefetchRecentSolutionList,
  setRefetchQuickDocsList,
  resetRefetchQuickDocsList,
  setRefetchSubFolder,
  setRefetchMeetingList,
  resetRefetchMeetingList,
  setRefetchDocument,
  setRefetchRequirementBoard,
  setRefetchKanbanBoard,
  resetRefetchUserData,
  resetRefetchDocument,
  resetRefetchRequirementBoard,
  resetRefetchSubFolder,
  resetRefetchKanbanBoard,
  setRefetchMySolutionList,
  resetRefetchMySolutionList,
  setRefetchProjectList,
  setRefetchProjectDetail,
  resetRefetchProjectList,
  resetRefetchProjectDetail
} = refetchSlice.actions;

export default refetchSlice.reducer;
