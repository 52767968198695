// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  taskName: "",
  taskDescription: "",
  taskStatus: "pending",
  taskPriority: "low",
  taskStartDate: dayjs().toISOString(), // Store as ISO string
  taskDueDate: dayjs().toISOString(),  // Store as ISO string
};

const createTaskSlice = createSlice({
  name: "createTaskSlice",
  initialState,
  reducers: {
    setTaskName(state, action) {
      state.taskName = action.payload;
    },
    setTaskDescription(state, action) {
      state.taskDescription = action.payload;
    },
    setTaskStatus(state, action) {
      state.taskStatus = action.payload;
    },
    setTaskPriority(state, action) {
      state.taskPriority = action.payload;
    },
    setTaskStartDate(state, action) {
      state.taskStartDate = action.payload;
    },
    setTaskDueDate(state, action) {
      state.taskDueDate = action.payload;
    },
    resetCreateTaskState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setTaskName,
  setTaskDescription,
  setTaskStatus,
  setTaskPriority,
  setTaskStartDate,
  setTaskDueDate,
  resetCreateTaskState
} = createTaskSlice.actions;

export default createTaskSlice.reducer;
