import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Add, Create } from "@mui/icons-material";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { getImages } from "../const";

const NoMeetingScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleUploadDocument = () => {
    navigate("/bsi-drive");
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100% - 164px)"
      textAlign="center"
      // padding={3}
    >
      <img
        src={getImages("no_meetings.png")} // Replace with your image path
        // alt="No Data"
        style={{
          width: "150px",
          height: "130px",
          // marginBottom: "20px",
          borderRadius: 8,
          mixBlendMode: "multiply",
        }} // Adjust the size as needed
      />
      <Typography
      variant="h6"
        sx={{
          fontSize:'1rem',
          color: "secondary.main",
          fontWeight: 600,
        }}
        gutterBottom
      >
       You have no meetings scheduled yet
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize:'0.9rem',
          color: "lightSecondary.main",
          fontWeight: 500,
        }}
        gutterBottom
      >
      You can start by scheduling new meeting 
      </Typography>
    </Box>
  );
};

export default NoMeetingScreen;
