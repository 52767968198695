import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { store } from '../../../store/store';

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const mySolutionListing  = createApi({
    reducerPath: 'mySolutionListing',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchMySolutionListing: builder.query({
        query: ({ searchValue}) => ({
          url: `solution/my_solution?search=${searchValue}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
           return response;
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchMySolutionListingQuery } = mySolutionListing;
