import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Avatar from "@mui/material/Avatar";
import { getImages } from "./const";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { CircularProgress, Divider, keyframes } from "@mui/material";
import PropTypes from "prop-types";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleDrawer,
  handleOverlayBg,
  closeDrawer,
} from "../redux/features/drawerSlice";

import {
  clearUserData,
  getToken,
  getUser,
  setBusinessUserPlanDetail,
  setPlanName,
  setPremiumBusinessUserPlanDetail,
} from "../redux/features/userSlice";
import NotFoundPage from "../utils/Error/error";
import CustomLoader from "../utils/Loader/CustomLoader";
import Modals from "./Modals";
import ChangePassword from "./Profile/ChangePassword";
import { resetRefetchUserData } from "../redux/features/refetch";
import { Upgrade } from "@mui/icons-material";
import SubscribeModal from "./SubscribeModal";
import UpgradeModal from "./upgradeModal";
import { hideLoader, showLoader } from "../redux/features/loader";
import { toast } from "react-toastify";
import { useFetchUserDetailsQuery } from "../redux/api/Users";
import { useManageSubscriptionPlanMutation } from "../redux/api/MySubscription";
//  ══════════════════════════ API Imports ══════════════════════════

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} )`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} )`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "0",
    left: "-100%",
  },
});
const fadeInOut = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;
const shineAnimation = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;
// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
// }));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  zIndex: 2,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "@media (max-width:600px)": {
      marginLeft: "0",
      width: "100%",
      zIndex: "1",
    },
  }),
  ...(!open && {
    marginLeft: "65px",
    width: `calc(100% - 65px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      width: `100%`,
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
   overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9C9C9C",
                  borderRadius: "4px",
                },
              
}));
const formatUserType = (userType) => {
  if (!userType) return "User"; // Default value if userType is not defined
  return userType
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export default function Layout({ children, title }) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.drawer.open);
  const loaderVisible = useSelector((state) => state.loader.visible);
  const planName = useSelector((state) => state.user.planName);
  const userId = localStorage.getItem("userId");
  const customerId = useSelector((state) => state.user?.data?.customerId?.[0]) ?? "";
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const zohoFeedbackScript = process.env.REACT_APP_ZOHO_FEEDBACK_SCRIPT;
  useEffect(() => {
    // Load Zoho Feedback Widget Script
    const script = document.createElement("script");
    script.src = zohoFeedbackScript;
    script.async = true;

    document.body.appendChild(script);

    // Inject CSS to hide the suggestions section
    const style = document.createElement("style");
    style.innerHTML = `
      .zohodesk-portal-suggestions {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(style);
    };
  }, []);



  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // get current user details
  const {
    data: userData,
    error,
    isLoading,
    refetch: userDataRefetch,
  } = useFetchUserDetailsQuery();
  const defaultUserType = "user";
  const userType = userData?.userType || defaultUserType;
  useEffect(() => {
    if (userData) {
      dispatch(getUser(userData));
      dispatch(
        setPlanName(
          userData?.subscription?.planSlug ||
            userData?.subscription?.plan_data?.plan_slug ||
            ""
        )
      );
      localStorage.setItem("userId", userData.id);
      localStorage.setItem("userType", userData.userType || defaultUserType);
      localStorage.setItem("userName", `${userData.firstName} ${userData.lastName}`);
      localStorage.setItem("avatar", userData?.logo);
    }
  }, [userData, dispatch]); 
  useEffect(() => {
    if (planName === "Lite") {
      dispatch(setBusinessUserPlanDetail(userData?.subscription?.planValues || []));
    } else if (planName === "Plus" || planName === "Pro") {
      dispatch(setBusinessUserPlanDetail(userData?.subscription?.plan_data?.plan_values || []));
    }
  }, [planName, dispatch, userData]);
  

  // set refetchKanban in reduxStore
  const refetchUserData = useSelector((state) => state.refetch.refetchUserData);
  
  useEffect(() => {
    if (refetchUserData) {
      userDataRefetch().finally(() => {
        dispatch(resetRefetchUserData());
      });
    }
  }, [refetchUserData, userDataRefetch, dispatch]);

  const [manageSubscriptionPlan, { isLoading: managePlanLoading }] =
    useManageSubscriptionPlanMutation();

  const handleManageSubscription = async () => {
    if (planName !== "Lite") {
      try {
        // Call the API to upgrade the subscription
        const response = await manageSubscriptionPlan({
          customerId: customerId,
        }).unwrap();

        if (response.statusCode === 200) {
          const customerPortalUrl = response?.data;
          window.location.href = customerPortalUrl;
        } else {
          showToastMessage("Plan upgrade failed", "error");
        }
      } catch (error) {
        showToastMessage(error?.message || "Plan Upgrade Failed", "error");
      }
    }
  };


// Subscribe modal
  const [openSubscribeModal , setOpenSubscribeModal] = useState(false);
  const handleOpenSubscribeModal = ()=>{
  if(planName == "Lite"){
    setOpenSubscribeModal(true);
  }  else{
    handleManageSubscription();
  }
  }
  const handleCloseSubscribeModal = ()=>{
  setOpenSubscribeModal(false);
  }

  //Change Password Modal
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const handleChangePwd = () => {
    setOpenChangePassword(true);
  };
  const closeChangePwd = () => {
    setOpenChangePassword(false);
  };

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer());
  };
  const handleOverlayClose = () => {
    dispatch(handleOverlayBg());
  };
  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };


  const icons = [
    getImages("dashboard_icon.png"),
    getImages("BSI_Co-lab.png"),
    getImages("Inbox.png"),
    getImages("Meetings.png"),
    getImages("bsi_drive.png"),
    userType === "solution_provider" ? getImages("my_solutions.png") : getImages("project.png"),
    getImages("project.png"),
    getImages("Profile.png"),
  ];
  const iconsHover = [
    getImages("dashboard_icon_hover.png"),
    getImages("BSI_Co-lab_hover.png"),
    getImages("Inbox_hover.png"),
    getImages("Meetings_hover.png"),
    getImages("bsi_drive_hover.png"),
    userType === "solution_provider" ? getImages("my_solutions_hover.png") : getImages("project_hover.png"),
    getImages("project_hover.png"),
    getImages("Profile_hover.png"),
  ];
  

  
  // list items according to user type
  const listItemGroups = {
    solution_provider: [
      "Dashboard",
      "BSI Co-lab",
      "Inbox",
      "Meetings",
      "BSI Drive",
      "My Solutions",
      "Projects",
      "Settings",
    ],
    business_user: [
      "Dashboard",
      "BSI Co-lab",
      "Inbox",
      "Meetings",
      "BSI Drive",
      "Projects",
      "Settings",
    ],
    investor: [
      "Dashboard",
      "BSI Co-lab",
      "Inbox",
      "Meetings",
      "BSI Drive",
      "Portfolio",
      "Profile",
    ],
    user: [
      "Dashboard",
      "BSI Co-lab",
      "Inbox",
      "Meetings",
      "BSI Drive",
      "Projects",
      "Profile",
    ],
  };

  const [activeItem, setActiveItem] = useState(0);
  const [activeProfileItem, setActiveProfileItem] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  Layout.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const getStyles = () => {
    // Default styles
    let styles = {
      background: "#dbebf8",
      padding: 3,
      pb: 0,
    };
    if (location.pathname === "/inbox") {
      styles.background = "#ECF6FF";
      styles.px = 0;
      styles.pt = 3;
      styles.pb = 0;
    }
    return styles;
  };
  const getBoxStyles = () => {
    // Default styles
    const isMobile = theme.breakpoints.down("sm");
    let boxStyle = {
      mt: 9,
    };
    if (location.pathname === "/inbox") {
      boxStyle.mt = 6;
      boxStyle[theme.breakpoints.down("sm")] = {
        mt: 8,
      };
    }
    return boxStyle;
  };

  // Getting Id from URL if user is redirected here
  const queryParams = new URLSearchParams(window.location.search);
  const userIdFromAPI = queryParams.get("user");
  const token = queryParams.get("token");

  // if (userIdFromAPI !== undefined && userIdFromAPI !== null) {
  //   // Store userId in localStorage
  //   localStorage.setItem("userId", userIdFromAPI);

  //   // Remove 'user' parameter from the URL
  //   queryParams.delete("user");

  //   // Construct the new URL without any query parameters
  //   const newUrl = window.location.origin + window.location.pathname;

  //   // Update the URL to only have the base path
  //   window.history.replaceState({}, document.title, newUrl);
  // }
  if (token !== undefined && token !== null) {
    // Store userId in localStorage
    // localStorage.setItem("userId", userIdFromAPI);
    dispatch(getToken(token));
    // Remove 'user' parameter from the URL
    queryParams.delete("token");
    // Construct the new URL without any query parameters
    const newUrl = window.location.origin + window.location.pathname;

    // Update the URL to only have the base path
    window.history.replaceState({}, document.title, newUrl);
  }

  const [isBSICoLabExpanded, setIsBSICoLabExpanded] = useState(false);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
  const [isSettingExpanded, setIsSettingExpanded] = useState(false);
  const [activeSettingsSubMenuItem, setActiveSettingsSubMenuItems] =
    useState(null);

  const handleListItemClick = (index, path) => {
    if (isBSICoLabExpanded) {
      toggleBSICoLabExpansion();
    }
    if (index !== 1 && userData.userType === "solution_provider") {
      setActiveSubMenuItem(null);
    }
    if (isSettingExpanded) {
      toggleSettingExpansion();
    }
    if (index !== 5) {
      setActiveSettingsSubMenuItems(null);
    }
    setActiveItem(index);
    Cookies.set("activeItem", index);
  };

  // logic to handle bsi-co-lab dropdown
  useEffect(() => {
    const currentPath = location.pathname;
    // if(currentPath === "/solution-enquiries"){
    //   setActiveItem(1)
    // }
    // if (currentPath === "/solution-enquiries") {
    //   setActiveSubMenuItem(1);
    //   setIsBSICoLabExpanded(true);
    // } else if (currentPath === "/investment-enquiries") {
    //   setActiveSubMenuItem(2);
    //   setIsBSICoLabExpanded(true);
    // } else {
    //   setActiveSubMenuItem(null);
    //   setIsBSICoLabExpanded(false);
    // }
    // if (currentPath === "/kanban-investment-enquiries") {
    //   setActiveSubMenuItem(2);
    //   setIsBSICoLabExpanded(true);
    // } else if (currentPath === "/kanban-solution-enquiries") {
    //   setActiveSubMenuItem(1);
    //   setIsBSICoLabExpanded(true);
    // }
    if (currentPath === "/profile") {
      setActiveSettingsSubMenuItems(6);
      // setIsSettingExpanded(true);
    } else if (currentPath === "/manage-users") {
      setActiveSettingsSubMenuItems(7);
      // setIsSettingExpanded(true);
    } else if (currentPath === "/manage-subscription") {
      setActiveSettingsSubMenuItems(8);
    } else {
      setActiveSettingsSubMenuItems(null);
      setIsSettingExpanded(false);
    }
    if (currentPath === "/bsi-sub-drive") {
      setActiveItem(4); // Set the active item based on path
    }
  }, [location.pathname]);

  const toggleBSICoLabExpansion = () => {
    setIsBSICoLabExpanded(!isBSICoLabExpanded);
    setActiveItem(isBSICoLabExpanded ? -1 : 1);
    Cookies.set("activeItem", isBSICoLabExpanded ? -1 : 1);
  };
  const toggleSettingExpansion = () => {
    setIsSettingExpanded(!isSettingExpanded);
    setActiveItem(isSettingExpanded ? -2 : 2);
  };

  const handleSubMenuItemClick = (index, path) => {
    setActiveSubMenuItem(index);
    navigate(path);
  };
  const handleSettingSubMenuItemClick = (index, path) => {
    setActiveSettingsSubMenuItems(index);
    navigate(path);
  };

  const handleProfileMenuClick = (index) => {
    if (index) {
      setActiveProfileItem(index);
    } else {
      setActiveProfileItem(null);
    }
  };



  // Handle navigation and active item
  const handleBsiCoLab = (index, path) => {
    if (userData && userData.userType !== "solution_provider") {
      setActiveItem(index);
      navigate(path);
    } else if (userData && userData.userType === "solution_provider") {
      setActiveItem(index);
      navigate("bsi-co-lab");
    }
  };

  const handleLogOut = () => {
    localStorage.clear();
    window.history.replaceState(null, "", "/");
    const laravelWebUrl = process.env.REACT_APP_LARAVEL_WEB_URL;
    window.location.href = laravelWebUrl;
    dispatch(getToken(null));
    // dispatch(clearUserData());
    // Check userId and current URL; this block will not be executed as the redirect happens immediately
    if (!userData) {
      const laravelWebUrl = process.env.REACT_APP_LARAVEL_WEB_URL;
      window.location.replace = laravelWebUrl;
    }
  };

    useEffect(() => {
      if (managePlanLoading) {
        dispatch(showLoader());
      } else {
        dispatch(hideLoader());
      }
    }, [managePlanLoading, dispatch]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        sx={{
          display: "none",
          background: "rgba(0 0 0 / 50%)",
          position: "fixed",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
          "@media (max-width:600px)": {
            zIndex: "3",
            display: open ? "block" : "none",
            cursor: "pointer",
          },
        }}
        onClick={handleOverlayClose}
      ></Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className="menu-open-btn"
        sx={{
          color: "#fff",
          background: "#100345",
          marginLeft: "0",
          position: "fixed",
          left: open ? "235px" : "44px",
          top: "20px",
          width: "40px",
          height: "40px",
          zIndex: "3",
          transition: "all 0.2s ease-in-out 0s",
          // ...(open && { display: 'none' }),
          "&:hover": {
            background: "#100345",
          },
          [theme.breakpoints.down("md")]: {
            width: "36px",
            height: "36px",
            left: open ? "235px" : "44px",
          },
          "@media (max-width:600px)": {
            left: open ? "235px" : "5px",
            top: "17px",
            zIndex: "5",
          },
        }}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleCloseDrawer}
      >
        {!open ? (
          <img
            style={{ maxWidth: "14px" }}
            src={getImages("menu_right_arrow.svg")}
          />
        ) : (
          <img
            style={{ maxWidth: "14px" }}
            src={getImages("menu_left_arrow.svg")}
          />
        )}
      </IconButton>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          background: "#100345",
          zIndex: 1,
          "@media (max-width:600px)": {
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: "4",
          },
        }}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleCloseDrawer}
        className="custom-drawer"
      >
        <List sx={{ background: "#100345", pt: 0 ,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "6px !important" ,
          borderRadius: "4px !important",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent !important",
          borderRadius: "4px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#9C9C9C !important",
          borderRadius: "4px ",
        },

        }}>
          <ListItem
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              py: 1.5,
              mb: 3,
              position: "relative",
              left: open ? "0px" : "-50px",
              top: open ? "0px" : "12px",
              [theme.breakpoints.down("sm")]: {
                top: open ? "0px" : "8px",
              },
              
            }}
          >
            <img
              style={{
                maxWidth: open ? "180px" : "80px",
              }}
              src={getImages("bluesky.png")}
              alt="bluesky"
            />
          </ListItem>
          {(userData ? listItemGroups[userType] : []).map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{
                display: "block",
                px: open ? 2 : 1,
              }}
            >
              {text === "BSI Co-lab" ? (
                <>
                  {/* <NavLink to={`bsi-co-lab${text === 'BSI Co-lab'? '' : `/${text.toLowerCase().replace(/\s+/g, '-')}`}`} style={{ textDecoration: 'none', width: '100%' }}> */}
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      pl: 2.5,
                      pr: 1,
                      backgroundColor:
                        // userData.userType !== "solution_provider" &&
                        location.pathname === "/bsi-co-lab" ||
                        location.pathname === "/bsi-co-lab/kanban" ||
                        location.pathname === "/solution-enquiries" ||
                        location.pathname === "/kanban-solution-enquiries" ||
                        (location.pathname === "/invite-solution-provider" &&
                          text === "BSI Co-lab") ||
                        location.pathname ===
                          `/${text.toLowerCase().replace(/\s+/g, "-")}`
                          ? "#fff"
                          : "",
                      borderRadius:
                        // userData.userType !== "solution_provider" &&
                        location.pathname === "/bsi-co-lab" ||
                        location.pathname === "/bsi-co-lab/kanban" ||
                        location.pathname === "/solution-enquiries" ||
                        location.pathname === "/kanban-solution-enquiries" ||
                        (location.pathname === "/invite-solution-provider" &&
                          text === "BSI Co-lab") ||
                        location.pathname ===
                          `/${text.toLowerCase().replace(/\s+/g, "-")}`
                          ? "6px"
                          : "",
                      "&:hover": {
                        backgroundColor:
                          // userData.userType !== "solution_provider" &&
                          location.pathname === "/bsi-co-lab" ||
                          location.pathname === "/bsi-co-lab/kanban" ||
                          location.pathname === "/solution-enquiries" ||
                          location.pathname === "/kanban-solution-enquiries" ||
                          (location.pathname === "/invite-solution-provider" &&
                            text === "BSI Co-lab") ||
                          location.pathname ===
                            `/${text.toLowerCase().replace(/\s+/g, "-")}`
                            ? "#fff"
                            : "",
                      },
                    }}
                    onClick={() => {
                      toggleBSICoLabExpansion();
                      handleBsiCoLab(index, "bsi-co-lab");
                      handleCloseDrawer();
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open
                          ? 1.5
                          : // : userData.userType === "solution_provider"
                            // ? "auto"
                            1.5,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        className="mainMenuText"
                        sx={{
                          // Apply hover icon for specific routes
                          backgroundImage: `url(${
                            location.pathname === "/bsi-co-lab" ||
                            location.pathname === "/bsi-co-lab/kanban" ||
                            location.pathname === "/solution-enquiries" ||
                            location.pathname ===
                              "/kanban-solution-enquiries" ||
                            location.pathname === "/invite-solution-provider"
                              ? iconsHover[index]
                              : icons[index]
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          backgroundSize: "100%",
                          width: "24px",
                          height: "30px",
                        }}
                      />
                    </ListItemIcon>
                    {open && (
                      <Typography
                        sx={{
                          // color: activeItem === index ? BSICoLabTextColor : "#fff",
                          // color: activeItem === index ? "#fff" : "#fff",
                          color:
                            // userData.userType !== "solution_provider" &&
                            location.pathname === "/bsi-co-lab" ||
                            location.pathname === "/bsi-co-lab/kanban" ||
                            location.pathname === "/solution-enquiries" ||
                            location.pathname ===
                              "/kanban-solution-enquiries" ||
                            (location.pathname ===
                              "/invite-solution-provider" &&
                              text === "BSI Co-lab") ||
                            location.pathname ===
                              `/${text.toLowerCase().replace(/\s+/g, "-")}`
                              ? "#073985"
                              : "#fff",
                          opacity: open ? 1 : 0,
                          fontWeight: activeItem === index ? "500" : "500",
                        }}
                      >
                        {text}
                      </Typography>
                    )}
                    {/* {userData.userType === "solution_provider" && (
                      <ExpandMoreIcon
                        sx={{
                          // color: activeItem === index ? BSICoLabTextColor : "#fff",
                          color: activeItem === index ? "#fff" : "#fff",
                          ml: "auto",
                          transition: "all 0.3s ease-in-out 0s",
                          transform: isBSICoLabExpanded
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                        }}
                      />
                    )} */}
                  </ListItemButton>
                  {/* </NavLink> */}
                  {/* <Collapse
                    in={
                      isBSICoLabExpanded &&
                      userData.userType == "solution_provider"
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "start",
                          pr: 1,
                          pl: open ? "40px" : 1.7,
                          "&.active": {
                            backgroundColor:
                              location.pathname === "/solution-enquiries" ||
                              "/kanban-solution-enquiries"
                                ? "#fff"
                                : "",
                            borderRadius:
                              location.pathname === "/solution-enquiries" ||
                              "/kanban-solution-enquiries"
                                ? "6px"
                                : "",
                          },
                          "&.active .mainMenuText": {
                            backgroundImage:
                              location.pathname === "/solution-enquiries" ||
                              "/kanban-solution-enquiries"
                                ? `url(${getImages(
                                    "solution_icon_active.svg"
                                  )})`
                                : "",
                          },
                        }}
                        onClick={() => {
                          handleSubMenuItemClick(1, "solution-enquiries");
                          handleCloseDrawer();
                        }}
                        className={activeSubMenuItem === 1 ? "active" : ""}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1.5 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            className="mainMenuText"
                            sx={{
                              // backgroundImage: `url(${activeItem === index ? BSICoLabBackground === '#fff'? iconsHover[index]:icons[index] : icons[index]})`,
                              backgroundImage: `url(${getImages(
                                "solution_icon.svg"
                              )})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "100%",
                              width: "24px",
                              height: "30px",
                            }}
                          />
                        </ListItemIcon>
                        {open && (
                          <Typography
                            sx={{
                              color:
                                activeSubMenuItem === 1 ? "#073985" : "#fff",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                            }}
                          >
                            Solution Enquiries
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "start",
                          pr: 1,
                          pl: open ? "40px" : 1.7,
                          "&.active": {
                            backgroundColor:
                              location.pathname === "/investment-enquiries" ||
                              "/kanban-investment-enquiries"
                                ? "#fff"
                                : "",
                            borderRadius:
                              location.pathname === "/investment-enquiries" ||
                              "/kanban-investment-enquiries"
                                ? "6px"
                                : "",
                          },
                          "&.active .mainMenuText": {
                            backgroundImage:
                              location.pathname === "/investment-enquiries" ||
                              "/kanban-investment-enquiries"
                                ? `url(${getImages(
                                    "investment_icon_active.svg"
                                  )})`
                                : "",
                          },
                        }}
                        onClick={() => {
                          handleSubMenuItemClick(2, "investment-enquiries");
                          handleCloseDrawer();
                        }}
                        className={activeSubMenuItem === 2 ? "active" : ""}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1.5 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            className="mainMenuText"
                            sx={{
                              // backgroundImage: `url(${activeItem === index ? BSICoLabBackground === '#fff'? iconsHover[index]:icons[index] : icons[index]})`,
                              backgroundImage: `url(${getImages(
                                "investment_icon.svg"
                              )})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "100%",
                              width: "24px",
                              height: "30px",
                            }}
                          />
                        </ListItemIcon>
                        {open && (
                          <Typography
                            sx={{
                              color:
                                activeSubMenuItem === 2 ? "#073985" : "#fff",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                            }}
                          >
                            Investment Enquiries
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Collapse> */}
                </>
              ) : text === "Settings" ? (
                <>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      pl: 2.5,
                      pr: 1,
                    }}
                    onClick={() => {
                      toggleSettingExpansion();
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open
                          ? 1.5
                          : userData.userType === "solution_provider" ||
                            "business_user"
                          ? "auto"
                          : 1.5,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        className="mainMenuText"
                        sx={{
                          backgroundImage: `url(${getImages("settings.svg")})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          backgroundSize: "100%",
                          width: "24px",
                          height: "30px",
                          color: "#fff", // Ensures any text or color within this box is white
                          // Additional styling to ensure the white icon appearance
                          filter: "brightness(0) invert(1)", // This CSS filter ensures the icon appears white
                        }}
                      />
                    </ListItemIcon>
                    {open && (
                      <Typography
                        sx={{
                          // color: activeItem === index ? BSICoLabTextColor : "#fff",
                          // color: activeItem === index ? "#fff" : "#fff",
                          color: "#fff",
                          opacity: open ? 1 : 0,
                          fontWeight: activeItem === index ? "500" : "500",
                        }}
                      >
                        {text}
                      </Typography>
                    )}
                    <ExpandMoreIcon
                      sx={{
                        // color: activeItem === index ? BSICoLabTextColor : "#fff",
                        color: activeItem === index ? "#fff" : "#fff",
                        ml: "auto",
                        transition: "all 0.3s ease-in-out 0s",
                        transform:
                          isSettingExpanded || open
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                      }}
                    />
                  </ListItemButton>
                  {/* </NavLink> */}
                  <Collapse
                    in={isSettingExpanded || open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "start",
                          pr: 1,
                          pl: open ? "40px" : 1.7,
                          "&.active": {
                            backgroundColor:
                              location.pathname === "/profile" ? "#fff" : "",
                            borderRadius:
                              location.pathname === "/profile" ? "6px" : "",
                            pl: open ? "30px" : 1.6,
                          },
                          "&.active .mainMenuText": {
                            backgroundImage:
                              location.pathname === "/profile"
                                ? `url(${getImages("Profile_hover.png")})`
                                : "",
                          },
                        }}
                        onClick={() => {
                          handleSettingSubMenuItemClick(6, "profile");
                          handleCloseDrawer();
                        }}
                        className={
                          activeSettingsSubMenuItem === 6 ? "active" : ""
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1.5 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            className="mainMenuText"
                            sx={{
                              // backgroundImage: `url(${activeItem === index ? BSICoLabBackground === '#fff'? iconsHover[index]:icons[index] : icons[index]})`,
                              backgroundImage: `url(${getImages(
                                "Profile.png"
                              )})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "100%",
                              width: "24px",
                              height: "30px",
                            }}
                          />
                        </ListItemIcon>
                        {open && (
                          <Typography
                            sx={{
                              color:
                                activeSettingsSubMenuItem === 6
                                  ? "#073985"
                                  : "#fff",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                            }}
                          >
                            Profile
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                    {/* manage Team member */}
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "start",
                          pr: 1,
                          pl: open ? "40px" : 1.7,
                          "&.active": {
                            backgroundColor:
                              location.pathname === "/manage-users"
                                ? "#fff"
                                : "",
                            borderRadius:
                              location.pathname === "/manage-users"
                                ? "6px"
                                : "",
                            pl: open ? "30px" : 1.6,
                          },
                          "&.active .mainMenuText": {
                            backgroundImage:
                              location.pathname === "/manage-users"
                                ? `url(${getImages("add_team_member.png")})`
                                : "",
                            // Adding a blue outline to the icon
                            filter:
                              activeSettingsSubMenuItem === 7
                                ? "invert(0%) sepia(100%) saturate(3000%) hue-rotate(200deg) brightness(100%) contrast(95%)"
                                : "none",
                          },
                        }}
                        onClick={() => {
                          handleSettingSubMenuItemClick(7, "manage-users");
                          handleCloseDrawer();
                        }}
                        className={
                          activeSettingsSubMenuItem === 7 ? "active" : ""
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1.5 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            className="mainMenuText"
                            sx={{
                              // backgroundImage: `url(${activeItem === index ? BSICoLabBackground === '#fff'? iconsHover[index]:icons[index] : icons[index]})`,
                              backgroundImage: `url(${getImages(
                                "add_team_member.png"
                              )})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "100%",
                              width: "24px",
                              height: "30px",
                              color: "#fff", // Ensures any text or color within this box is white
                              // Additional styling to ensure the white icon appearance
                              filter: "brightness(0) invert(1)", // This CSS filter ensures the icon appears white
                            }}
                          />
                        </ListItemIcon>
                        {open && (
                          <Typography
                            sx={{
                              color:
                                activeSettingsSubMenuItem === 7
                                  ? "#073985"
                                  : "#fff",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                            }}
                          >
                            Manage Users
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                    {/* manage Subscription */}
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "start",
                          pr: 1,
                          pl: open ? "40px" : 1.7,
                          "&.active": {
                            backgroundColor:
                              location.pathname === "/manage-subscription"
                                ? "#fff"
                                : "",
                            borderRadius:
                              location.pathname === "/manage-subscription"
                                ? "6px"
                                : "",
                            pl: open ? "30px" : 1.6,
                          },
                          "&.active .mainMenuText": {
                            backgroundImage:
                              location.pathname === "/manage-subscription"
                                ? `url(${getImages(
                                    // "investment_icon_active.svg"
                                    "blueCrown.png"
                                  )})`
                                : "",
                            // Adding a blue outline to the icon
                            filter:
                              activeSettingsSubMenuItem === 8
                                ? "invert(0%) sepia(100%) saturate(3000%) hue-rotate(200deg) brightness(100%) contrast(95%)"
                                : "none",
                          },
                        }}
                        onClick={() => {
                          handleSettingSubMenuItemClick(
                            8,
                            "manage-subscription"
                          );
                          handleCloseDrawer();
                        }}
                        className={
                          activeSettingsSubMenuItem === 8 ? "active" : ""
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1.5 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            className="mainMenuText"
                            sx={{
                              // backgroundImage: `url(${activeItem === index ? BSICoLabBackground === '#fff'? iconsHover[index]:icons[index] : icons[index]})`,
                              backgroundImage: `url(${getImages(
                                // "investment_icon.svg"
                                "whiteUpgradeCrown.png"
                              )})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "100%",
                              width: "24px",
                              height: "30px",
                            }}
                          />
                        </ListItemIcon>
                        {open && (
                          <Typography
                            sx={{
                              color:
                                activeSettingsSubMenuItem === 8
                                  ? "#073985"
                                  : "#fff",
                              fontSize: "0.9rem",
                              fontWeight: "600",
                            }}
                          >
                            Manage Subscription
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Collapse>
                </>
              ) : (
                <NavLink
                  to={
                    text === "Dashboard"
                      ? "/dashboard"
                      : `/${text.toLowerCase().replace(/\s+/g, "-")}`
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      display: "flex",
                      alignItems: "center",
                      py: 1.5,
                      px: 2.5,
                      backgroundColor:
                        (location.pathname === "/dashboard" &&
                          text === "Dashboard") ||
                        location.pathname ===
                          `/${text.toLowerCase().replace(/\s+/g, "-")}`
                          ? "#fff"
                          : "",
                      borderRadius:
                        location.pathname ===
                        (text === "Dashboard"
                          ? "/dashboard"
                          : `/${text.toLowerCase().replace(/\s+/g, "-")}`)
                          ? "6px"
                          : "",
                    }}
                    onClick={() => {
                      handleListItemClick(index);
                      handleCloseDrawer();
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1.5 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          // backgroundImage: `url(${activeItem === index ? iconsHover[index] : icons[index]})`,
                          backgroundImage: `url(${
                            location.pathname ===
                            (text === "Dashboard"
                              ? "/dashboard"
                              : `/${text.toLowerCase().replace(/\s+/g, "-")}`)
                              ? iconsHover[index]
                              : icons[index]
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          backgroundSize: "100%",
                          width: "24px",
                          height: "30px",
                        }}
                      />
                    </ListItemIcon>
                    {open && (
                      <Typography
                        sx={{
                          color:
                            (location.pathname === "/dashboard" &&
                              text === "Dashboard") ||
                            location.pathname ===
                              `/${text.toLowerCase().replace(/\s+/g, "-")}`
                              ? "#073985"
                              : "#fff",
                          opacity: open ? 1 : 0,
                          fontWeight: activeItem === index ? "500" : "500",
                        }}
                      >
                        {text}
                      </Typography>
                    )}
                  </Box>
                </NavLink>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pb: 0,
          background: "#dbebf8",
          minHeight: "100vh",
          ...getStyles(),
          [theme.breakpoints.down("sm")]: {
            p: 2,
          },
        }}
      >
        <AppBar
          position="fixed"
          open={open}
          style={{
            paddingRight: "0px",
            background: "#DBEBF8",
          }}
        >
          <Toolbar
            sx={{
              background: "#DBEBF8",
              py: 1,
              [theme.breakpoints.down("sm")]: {
                pl: "50px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "700",
                color: "#073985",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.2rem",
                  maxWidth: "220px",
                },
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "180px",
                },
              }}
              variant="h4"
              noWrap
              component="div"
            >
              {title}
            </Typography>
            <Box
      sx={{
        display:'flex',
        gap:2,
        marginLeft: "auto",
        borderRight: "1px solid #A2C2F1",
        pr: 1,
      }}
    >
      {/* <Button onClick={handleOpenZohoPopup}>Contact Us</Button> */}
          <Button
          onClick={handleOpenSubscribeModal}
        sx={{
          position: "relative",
          overflow: "hidden",
          color: "#073985",
          fontSize: "1rem",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          textTransform: "none",

          // background: "linear-gradient(120deg, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.1) 70%)",
          // background: "linear-gradient(120deg, rgba(7, 57, 133, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(7, 57, 133, 0.2) 70%)",
          background: "linear-gradient(120deg, rgba(245, 245, 245, 0.5) 30%, rgba(255, 255, 255, 0.8) 50%, rgba(245, 245, 245, 0.5) 70%)",

          backgroundSize: "200% 100%",
          animation: `${shineAnimation} 2.5s infinite linear`,
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            color: "primary.main", 
          },
        }}
      >
       
        <img
          src={getImages("upgradeCrown.png")}
          style={{
            width: "18px",
            height: "20px",
            transition: "all 0.3s ease-in-out",
            // filter: "brightness(0) saturate(100%) invert(21%) sepia(98%) saturate(1800%) hue-rotate(202deg) brightness(80%) contrast(80%)",
            mixBlendMode: "multiply",
          }}
        />
         Upgrade Plan
      </Button>
    </Box>
            {/* ══════════════════════════ Profile ══════════════════════════ */}
            <div>
              <Button
                id="basic-button"
                aria-controls={openDw ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDw ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  pr: 0,
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ p: 0.5 }}
                >
                  <Avatar
                    src={userData?.thumbnailAvatar}
                    alt={`${userData?.firstName} logo`}
                    sx={{
                      width: 42,
                      height: 42,
                      [theme.breakpoints.down("md")]: {
                        width: 34,
                        height: 34,
                      },
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: "left",
                      pl: 2,
                      [theme.breakpoints.down("sm")]: {
                        pl: 0,
                      },
                    }}
                    style={{
                      marginLeft: "0px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "secondary.main",
                        fontSize: "1rem",
                        fontWeight: "600",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "0.8rem",
                        },
                        [theme.breakpoints.down("sm")]: {
                          display: "none",
                        },
                      }}
                    >
                      {userData
                        ? `${userData.firstName} ${userData.lastName}`
                        : "User"}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "primary.main",
                        fontSize: "0.85rem",
                        fontWeight: "600",
                        lineHeight: "normal",
                        marginTop: "0.15rem",
                        textTransform: "capitalize",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.down("sm")]: {
                          display: "none",
                        },
                      }}
                    >
                      {" "}
                      {formatUserType(
                        `${planName || ""} ${userData?.userType || ""}`
                      )}
                    </Typography>
                  </Typography>
                  <KeyboardArrowDownOutlinedIcon
                    sx={{ color: "secondary.main" }}
                    style={{ marginLeft: "0" }}
                  />
                </Stack>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDw}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    borderRadius: "10px",
                    border: "solid 1px #106EED",
                    minWidth: "280px",
                    p: 1,
                  },
                }}
              >
                <Paper
                  sx={{
                    width: 320,
                    maxWidth: "100%",
                    background: "#fff",
                    borderRadius: "10px",
                    borderBottom: "none",
                    boxShadow: "none",
                    p: 2,
                  }}
                >
                  {/* <Typography
                                        sx={{
                                            fontSize: "0.8rem",
                                            fontWeight: "600",
                                            color: 'ltheme.main',
                                            pl: 1.5,
                                            pb: 0.8,
                                        }} variant="body2">
                                        Switch Profile
                                    </Typography> */}
                  {/* profile */}
                  {/* <Link
                    to="/profile"
                    style={{
                      color: activeProfileItem === 1 ? "#fff" : "#2A589C",
                      textDecoration: "none",
                      width: "100%",
                      display: "block",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        lineHeight: "20px",
                        color: "lightSecondary.main",
                        py: 1.3,
                        px: 1.4,
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "&.active": {
                          backgroundColor: "primary.main",
                          color: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                      onClick={() => {
                        handleProfileMenuClick(1);
                        handleClose();
                      }}
                      className={activeProfileItem === 1 ? "active" : ""}
                    >
                      {formatUserType(userData?.userType)}
                      
                    </MenuItem>
                  </Link> */}
                  <Link
                    to="/manage-users"
                    style={{
                      color: activeProfileItem === 2 ? "#fff" : "#2A589C",
                      textDecoration: "none",
                      width: "100%",
                      display: "block",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        lineHeight: "20px",
                        color: "lightSecondary.main",
                        py: 1.3,
                        px: 1.4,
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "extraLTheme.main",
                        },
                        "&.active": {
                          backgroundColor: "primary.main",
                          color: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                      onClick={() => {
                        handleProfileMenuClick(2);
                        handleClose();
                      }}
                      className={activeProfileItem === 2 ? "active" : ""}
                    >
                      Manage Users
                    </MenuItem>
                  </Link>
                  {/* <MenuItem onClick={handleClose}
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            color: "lightSecondary.main",
                                            py: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                            '&.active': {
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                                borderRadius: "10px"
                                            }
                                        }}
                                    >Business Owner</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}
                                        sx={{
                                            fontSize: "0.9rem",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            color: "lightSecondary.main",
                                            py: 1.3,
                                            px: 1.4,
                                            '&:hover': {
                                                backgroundColor: 'extraLTheme.main'
                                            },
                                            '&.active': {
                                                backgroundColor: 'primary.main',
                                                color: "#fff",
                                                borderRadius: "10px"
                                            }
                                        }}
                                    >Investor</MenuItem> */}
                  {/* <Divider
                                        sx={{
                                            borderColor: 'ltheme.main',
                                        }}
                                    /> */}
                  {/* <Link
                      to="/manage-users"
                      style={{
                        color: activeProfileItem === 2 ? "#fff" : "#2A589C",
                        textDecoration: "none",
                        width: "100%",
                        display: "block",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                      }}
                    >                  
                  <MenuItem
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "lightSecondary.main",
                      py: 1.3,
                      px: 1.4,
                      "&:hover": {
                        backgroundColor: "extraLTheme.main",
                      },
                      "&.active": {
                        backgroundColor: "primary.main",
                        color: "#fff",
                        borderRadius: "10px",
                      },
                    }}
                    onClick={() => {
                      handleProfileMenuClick(2);
                      handleClose();
                    }}
                    className={activeProfileItem === 2 ? "active" : ""}
                  >
                  
                      Manage Team Members
                  </MenuItem>
                  </Link> */}
                  <MenuItem
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "lightSecondary.main",
                      py: 1.3,
                      px: 1.4,
                      "&:hover": {
                        backgroundColor: "extraLTheme.main",
                      },
                      "&.active": {
                        backgroundColor: "primary.main",
                        color: "#fff",
                        borderRadius: "10px",
                      },
                    }}
                    onClick={() => {
                      handleProfileMenuClick(3);
                      handleClose();
                      handleChangePwd();
                    }}
                    className={activeProfileItem === 3 ? "active" : ""}
                  >
                    Change Password
                  </MenuItem>
                  <Divider
                    sx={{
                      borderColor: "ltheme.main",
                    }}
                  />
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      handleLogOut();
                    }}
                    sx={{
                      justifyContent: "space-between",
                      pt: 1.3,
                      px: 1.4,
                      "&:hover": {
                        backgroundColor: "extraLTheme.main",
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        lineHeight: "20px",
                        color: "red.main",
                      }}
                    >
                      Log Out
                    </Typography>
                    <LogoutOutlinedIcon
                      sx={{
                        color: "red.main",
                      }}
                    />
                  </MenuItem>
                </Paper>
              </Menu>
            </div>
          </Toolbar>
          {loaderVisible && <CustomLoader />}
        </AppBar>
        <Box
          sx={{
            mt: 9,
            ...getBoxStyles(),
          }}
        >
          {children}
        </Box>
      </Box>
      <Modals
        open={openChangePassword}
        handleClose={closeChangePwd}
        modalWidth={600}
      >
        {/* <NewFolderModal handleClose={closeNewFolder}/> */}
        <ChangePassword
          handleClose={closeChangePwd}
          handleLogOut={handleLogOut}
        />
      </Modals>
      <UpgradeModal
        open={openSubscribeModal}
        handleClose={handleCloseSubscribeModal}
        modalWidth={750}
      >
        <SubscribeModal
          handleClose={handleCloseSubscribeModal}
          // setOpenPaymentSuccess = {setOpenPaymentSuccess}
          showToastMessage={showToastMessage}
          // limitMessage={limitMessage}
        />
      </UpgradeModal>
    </Box>
  );
}
