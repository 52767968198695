// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
projectName : "",
isActive : false,
selectedBoardId : "",
selectedSolutionId : "",
selectedBusinessUserId: "",
selectedTeamMembers: [],
};

const editProjectSlice = createSlice({
  name: "editProject",
  initialState,
  reducers: {
    setProjectData(state , action){
     const data = action.payload;
     state.projectName = data.projectName || "";
     state.isActive = data.isActive || "";
     state.selectedBoardId = data.selectedBoardId || "";
     state.selectedSolutionId = data.selectedSolutionId || "";
     state.selectedBusinessUserId = data.selectedBusinessUserId || "";
     state.selectedTeamMembers = data.selectedTeamMembers || "";
    },
   setProjectName(state , action ) {
   state.projectName = action.payload;
   },
   setIsActive (state, action) {
    state.isActive = action.payload; 
  },
   setSelectedBoardId(state, action){
    state.selectedBoardId = action.payload;
   },
   setSelectedSolutionId(state , action){
    state.selectedSolutionId = action.payload;
   },
   setSelectedBusinessUserId(state , action){
    state.selectedBusinessUserId = action.payload;
   },
   setSelectedTeamMembers(state , action){
    state.selectedTeamMembers = action.payload;
   }
  }
});

export const {
    setProjectData,
    setProjectName,
    setIsActive,
    setSelectedBoardId,
    setSelectedSolutionId , 
    setSelectedBusinessUserId,
    setSelectedTeamMembers,
} = editProjectSlice.actions;
export default editProjectSlice.reducer;
