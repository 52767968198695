import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  Card,
  CardContent,
  IconButton,
  Select,
  FormControl,
  TextField,
  Stack,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setMilestoneDescription,
  setMilestoneStatus,
  setMilestoneTitle,
} from "../../redux/features/MyProjects/editMilestoneSlice";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { setRefetchProjectDetail } from "../../redux/features/refetch";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Modals from "../../commonComponents/Modals";
import AttachDocument from "./AttachDocument";
import DeleteIcon from "@mui/icons-material/Delete";
import shortid from "shortid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEditMilestoneMutation } from "../../redux/api/MyProjects";

const EditMileStone = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  //  States
  const { milestoneTitle, milestoneDescription, milestoneStatus } = useSelector(
    (state) => state.editMilestone
  );
  const { projectId, milestoneId } = useSelector((state) => state.projectSlice);
  const userId = localStorage.getItem("userId");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  // validation state
  const [errors, setErrors] = useState({
    milestoneTitle: false,
    milestoneDescription: false,
  });

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(newFiles);
    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({
        id: shortid.generate(),
        filename: file.name,
        filetype: file.type,
        fileimage: URL.createObjectURL(file),
        datetime: file.lastModifiedDate.toLocaleString("en-IN"),
        filesize: filesizes(file.size),
      })),
    ]);
  };

  const handleDeleteFile = (id, filename) => {
    // if (window.confirm("Are you sure you want to delete this file?")) {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    setFiles((prevFiles) =>
      Array.from(prevFiles).filter((file) => file.name !== filename)
    );
    // }
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // api's

  // create milestone
  const [
    editMilestone,
    { isLoading: editMilestoneLoading, isError: editMilestoneError },
  ] = useEditMilestoneMutation();

  // functions
  const handleMilestoneTitle = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length > 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        milestoneTitle: false,
      }));
      return;
    }
    dispatch(setMilestoneTitle(inputValue)); // Assuming setMilestoneTitle takes a value
    if (inputValue !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, milestoneTitle: false }));
    }
  };

  const handleMilestoneDescription = (inputValue) => {
    if (inputValue.length > 350) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        milestoneDescription: true,
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        milestoneDescription: false,
      }));
    }
    dispatch(setMilestoneDescription(inputValue));
  };

  const handleEditMilestone = async () => {
    let hasError = false;
    const newErrors = { milestoneTitle: false, milestoneDescription: false };

    // Validation
    if (milestoneTitle === "") {
      newErrors.milestoneTitle = true;
      hasError = true;
    }
    if (milestoneDescription.length > 250) {
      newErrors.milestoneDescription = true;
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }

    // Build the milestoneData array
    const formData = new FormData();
    formData.append("milestone_id", milestoneId);
    formData.append("owner_id", userId);
    formData.append("name", milestoneTitle);
    formData.append("description", milestoneDescription);
    files.forEach((file) => formData.append("files", file));

    // API call
    try {
      const response = await editMilestone({
        projectId,
        formData,
      }).unwrap();
      const successMessage =
        response?.message || "Milestone edited successfully";
      if (response.statusCode === 200) {
        showToastMessage(successMessage, "success");
      }
      if (response?.statusCode === 500) {
        showToastMessage("Server error occurred", "error");
      }
      dispatch(setRefetchProjectDetail(true));
    } catch (editMilestoneError) {
      const errorMessage =
        editMilestoneError?.message || "Failed to edit milestone";
      showToastMessage(errorMessage, "error");
    } finally {
      navigate("/project-detail");
    }
  };

  const handleGoBack = () => {
    navigate("/project-detail");
  };

  useEffect(() => {
    if (editMilestoneLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [editMilestoneLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            py: 0,
            px: 0,
            borderBottom: "solid 1px #DBEBF8",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleGoBack}
            sx={{
              marginRight: "5px",
              display: "inline-flex",
              p: 0,
              cursor: "pointer",
            }}
          >
            <KeyboardBackspaceIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "32px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "20px",
                },
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: "1.4rem",
              fontWeight: "700",
              pr: 0.7,
              [theme.breakpoints.down("md")]: {
                fontSize: "1.1rem",
              },
            }}
          >
            Edit Milestone
          </Typography>
        </Stack>
      </Box>

      <Grid
        container
        mt={1}
        columnSpacing={2}
        sx={{
          // p:2,
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up("xl")]: {
            // p: 10,
            mt: 8,
          },
        }}
      >
        <Grid item xs={12} md={11} lg={11}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              mb: 3,
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                p: 4,
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
              }}
              style={{
                paddingBottom: "0",
              }}
            >
              <Box
                component="form"
                sx={{
                  mt: 0,
                  mb: 0,
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={5}
                  sx={{
                    mb: 1,
                  }}
                >
                  {/* Parent Grid for Solution Benefits and Key Features */}
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={5}
                    item
                    xs={12}
                    spacing={2}
                  >
                    {/* Milestone Column */}
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={5}
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Milestone Title*
                        </Typography>
                        <TextField
                          hiddenLabel
                          variant="filled"
                          value={milestoneTitle}
                          onChange={handleMilestoneTitle}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "500",
                              pt: 1.5,
                              pb: 1.5,
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                            },
                            "& .MuiInputBase-root.Mui-focused .MuiIconButton-root":
                              {
                                backgroundColor: "transparent !important", // Ensure it does not inherit focus styles
                              },
                          }}
                        />
                        {errors.milestoneTitle && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            This field is required.
                          </Typography>
                        )}
                      </Grid>
                      {/* Description  */}
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Description
                        </Typography>
                        {/* <TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          variant="filled"
                          value={milestoneDescription}
                          onChange={handleMilestoneDescription}
                          multiline
                          rows={3}
                          maxRows={3}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiFilledInput-root": {
                              backgroundColor: "extraLTheme.main", // Set main background color
                              borderRadius: "4px", // Ensure corners are aligned
                              "&:hover": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "&::before, &::after": {
                                borderBottom: "none", // Remove underline completely
                              },
                              "&.Mui-focused": {
                                backgroundColor: "extraLTheme.main", // Ensure consistency when focused
                                border: "1px solid transparent", // Retain border
                              },
                            },
                            "& .MuiFilledInput-underline:before": {
                              borderBottom: "none", // Remove default underline
                            },
                            "& .MuiFilledInput-underline:after": {
                              borderBottom: "none", // Remove default underline
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "600",
                              pb: 1.5,
                              backgroundColor: "extraLTheme.main",
                              "&::-webkit-scrollbar": {
                                width: "6px",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#9C9C9C",
                                borderRadius: "4px",
                              },
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                                backgroundColor: "extraLTheme.main",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                              backgroundColor: "extraLTheme.main",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                              backgroundColor: "extraLTheme.main",
                            },
                          }}
                        /> */}
                        <Box
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            border: "solid 1px #DBEBF8",
                            backgroundColor: "extraLTheme.main",
                            marginBottom: 10,
                          }}
                          sx={{
                            "& .ql-editor": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "500",
                              width: "100%",
                              maxWidth: 1000,
                            },
                            "& .ql-toolbar": {
                              width: "100%",
                              // borderRadius: "4px",
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .ql-container": {
                              width: "100%",
                              // borderRadius: "4px",
                              backgroundColor: "extraLTheme.main",
                              border: "solid 1px #DBEBF8",
                              maxWidth: "100%",
                            },
                          }}
                        >
                          <ReactQuill
                            value={milestoneDescription}
                            onChange={handleMilestoneDescription}
                            placeholder={"Write something here..."}
                            theme="snow"
                            style={{
                              height: "150px",
                              backgroundColor: "extraLTheme.main",
                              fontSize: "1.9rem",
                              fontWeight: "600",
                              color: "lightSecondary.main",
                              borderRadius: "4px",
                              width: "100%",
                              maxWidth: "100%",
                            }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }],
                              ],
                            }}
                            formats={[
                              "bold",
                              "italic",
                              "underline",
                              "list",
                              "bullet",
                            ]}
                          />
                        </Box>
                        {errors.milestoneDescription && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: "12px", mt: 0.5 }}
                          >
                            Title cannot exceed 100 characters
                          </Typography>
                        )}
                      </Grid>
                      {/* Status */}
                      {/* <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "secondary.main",
                            fontWeight: "bold",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Status*
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            value={milestoneStatus}
                            onChange={handleMilestoneStatus}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value={"Todo"}>To do</MenuItem>
                            <MenuItem value={"In Progress"}>
                              In Progress
                            </MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Completed"}>Completed</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  {/* Attach Document */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      mt: 10,
                      mb: 2,
                    }}
                  >
                    <ListItemButton
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                      sx={{
                        border: "solid 1px #A2C2F1",
                        background: "#ECF6FF",
                        padding: "14px 20px",
                        borderRadius: "10px",
                        minWidth: "10rem",
                        maxWidth: "15rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "lightSecondary.main",
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FileUploadOutlinedIcon
                          sx={{
                            mr: 1.2,
                          }}
                        />
                        <Typography
                          variant="h6"
                          noWrap
                          sx={{
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "1rem",
                          }}
                        >
                          Attach Document
                        </Typography>
                      </Box>
                    </ListItemButton>
                    {/* Hidden File Input */}
                    <input
                      id="fileInput"
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Grid>
                  {/* Selected Files Grid */}
                  {selectedFiles.length > 0 && (
                    <>
                      {selectedFiles.map(
                        ({ id, filename, fileimage, filesize, datetime }) => (
                          <Grid item xs={12} md={6} lg={4} key={id}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 50,
                                  height: 50,
                                  mr: 2,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: theme.palette.grey[200],
                                }}
                              >
                                {filename.match(
                                  /\.(jpg|jpeg|png|gif|svg)$/i
                                ) ? (
                                  <img
                                    src={fileimage}
                                    alt={filename}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                    }}
                                  />
                                ) : (
                                  <Typography variant="body2">File</Typography>
                                )}
                              </Box>
                              <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="body2" noWrap>
                                  {filename}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Size: {filesize} | Modified: {datetime}
                                </Typography>
                              </Box>
                              <Tooltip title="Delete">
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeleteFile(id, filename)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        )
                      )}
                    </>
                  )}
                  {/* Submit Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      textAlign: "center",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={editMilestoneLoading}
                      sx={{
                        textTransform: "inherit",
                        p: "10px 20px",
                        boxShadow: "none",
                        fontWeight: "600",
                        width: "200px",
                      }}
                      onClick={handleEditMilestone}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EditMileStone;
