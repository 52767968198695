// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";
import shortid from "shortid";

const initialState = {
  selectedSolutionId: null,
  solutionName: "",
  solutionTagline: "",
  solutionWebUrl: "",
  headquarter: "",
  industry: [], // Expecting an array of industry objects
  tech: [], // Expecting an array of tech IDs or objects
  useCase: [], // Expecting an array of use case IDs or objects
  logo: null,
  selectedLogo: null,
  priceRange: "",
  solutionBenefits: [], // Expecting an array
  solutionKeyFeatures: [], // Expecting an array
  shortOverview: '',
  overview: '',
  trailAvailability: '',
  prizeValue:"",
  solutionNameError: null,
  checkSolutionName: "",
  dataLoaded: false,
};

const editSolutionSlice = createSlice({
  name: "editSolution",
  initialState,
  reducers: {
    setSelectedSolutionId(state, action) {
      state.selectedSolutionId = action.payload;
    },
    setSolutionData: (state, action) => {
        const data = action.payload;
        state.logo = data.logo || [];
        state.solutionName = data.solution_name || "";
        state.checkSolutionName = data.solution_name || "";
        state.solutionTagline = data.tagline || "";
        state.solutionWebUrl = data.source_link || "";
        state.headquarter = data.head_quarters || "";
        
        // Assuming industry_ids is an array of objects
        state.industry = data.industry_ids || [];
  
        // Assuming tech_ids is an array of IDs
        state.tech = data.tech_ids || []; 
  
        // Assuming use_case_ids is an array of use case IDs or objects
        state.useCase = data.use_case_ids || []; // Modify if you want to map it to use case objects
  
        state.priceRange = data.price_range || "";
        state.solutionBenefits = (data.benefits || []).map(benefit => ({
          id: shortid.generate(),
          value: benefit
      }));
      state.solutionKeyFeatures = (data.key_features || []).map(feature => ({
        id: shortid.generate(),
        value: feature
    }));
        state.shortOverview = data.short_overview || "";
        state.overview = data.overview || "";
        state.trailAvailability = data.trial || "";
        state.dataLoaded = true; 
      },
      setLogo(state, action) {
        state.logo = action.payload;
      },
      setSelectedLogo(state, action) {
        state.selectedLogo = action.payload; // Replace with the new file metadata
      },
      setNewSolutionName: (state, action) => {
        state.solutionName = action.payload;
      },
      setSolutionTagline: (state, action) => {
        state.solutionTagline = action.payload;
      },
      setSolutionWebUrl: (state, action) => {
        state.solutionWebUrl = action.payload;
      },
      setHeadquarter: (state, action) => {
        state.headquarter = action.payload;
      },
      setIndustry: (state, action) => {
        state.industry = action.payload;
      },
      setTech: (state, action) => {
        state.tech = action.payload;
      },
      setUseCase: (state, action) => {
        state.useCase = action.payload;
      },
      setPriceRange: (state, action) => {
        // New reducer to set price range
        state.priceRange = action.payload;
      },
  
      setShortOverview: (state, action) => {
        state.shortOverview = action.payload;
      },
      setOverview: (state, action) => {
        state.overview = action.payload;
      },
      setTrialAvailability: (state, action) => {
        state.trailAvailability = action.payload;
      },
      // Add a new benefit to the array
      addSolutionBenefit: (state) => {
        if (!state.solutionBenefits) {
          state.solutionBenefits = []; // Ensure it's an array
        }
        state.solutionBenefits.push({ id: shortid.generate(), value: "" });
      },
      removeSolutionBenefit: (state, action) => {
        state.solutionBenefits = state.solutionBenefits.filter(
          (benefit) => benefit.id !== action.payload
        );
      },
      updateSolutionBenefit: (state, action) => {
        const { id, value } = action.payload;
        const index = state.solutionBenefits.findIndex((benefit) => benefit.id === id);
        if (index !== -1) state.solutionBenefits[index].value = value;
      },
       // Add a new feature to the array
       addSolutionKeyFeature: (state) => {
        if (!state.solutionKeyFeatures) {
          state.solutionKeyFeatures = []; // Ensure it's an array
        }
        state.solutionKeyFeatures.push({ id: shortid.generate(), value: "" });
      },
      removeSolutionKeyFeature: (state, action) => {
        state.solutionKeyFeatures = state.solutionKeyFeatures.filter(
          (feature) => feature.id !== action.payload
        );
      },
      // Update a specific feature based on its id
      updateSolutionKeyFeature: (state, action) => {
        const { id, value } = action.payload;
        const index = state.solutionKeyFeatures.findIndex((feature) => feature.id === id);
        if (index !== -1) state.solutionKeyFeatures[index].value = value;
      },
      setPrizeValue:(state, action)=>{
       state.prizeValue = action.payload
      },
      setSolutionNameError: (state, action) => {
        state.solutionNameError = action.payload;
      },
      setCheckSolutionName: (state, action) => {
        state.checkSolutionName = action.payload;
      },
      resetDataLoaded: (state) => {
        state.dataLoaded = false; // Reset flag if you need to refetch
      },
      // If you want to reset all fields
      resetProfile: (state) => {
        state.solutionName= "",
        state.solutionTagline= "" ,
        state.solutionWebUrl= "",
        state.headquarter= "",
        state.industry= [],
        state.tech= [],
        state.useCase= [],
        state.logo= null,
        state.selectedLogo= null,
        state.priceRange= "",
        state.solutionBenefits= [],
        state.solutionKeyFeatures= [],
        state.shortOverview = '',
        state.overview= '' ,
        state.trailAvailability= '',
        state.solutionNameError=null,
        state.checkSolutionName = "",
        state.dataLoaded = false; 
      }
  },
});

export const {
    setSelectedSolutionId,
    setSolutionData,
    setLogo,
    setSelectedLogo,
    setNewSolutionName,
    setSolutionTagline,
    setSolutionWebUrl,
    setHeadquarter,
    setIndustry,
    setTech,
    setUseCase,
    setPriceRange,
    resetProfile,
    setShortOverview,
    setOverview,
    setTrialAvailability,
    addSolutionBenefit,
    updateSolutionBenefit,
    removeSolutionBenefit,
    addSolutionKeyFeature,
    removeSolutionKeyFeature,
    updateSolutionKeyFeature,
    setPrizeValue,
    setSolutionNameError,
    setCheckSolutionName,
    resetDataLoaded
} = editSolutionSlice.actions;
export default editSolutionSlice.reducer;
