import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';
export const InboxRequirementBoardList = createApi({
    reducerPath: 'inboxRequirementBoardList',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints:(builder)=>({
        fetchInboxRequirementBoardDetails: builder.query({
        query:({searchParam})=>({
            url:`inbox/boards?search_board_name=${searchParam}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
             // Extract relevant data from the response
            const { statusCode, status, message, data } = response;

            // Return the extracted data
            return {
                statusCode,
                status,
                message,
                boards: data
            };
        },
        
        refetchOnWindowFocus: true,
        keepUnusedDataFor: 0,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
        }),

    })
})

export const{useFetchInboxRequirementBoardDetailsQuery} =  InboxRequirementBoardList;
export default  InboxRequirementBoardList;