import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  styled,
  InputBase,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import ProjectSkeletonCard from "../../commonComponents/Skeleton/projectCardSkull";
import {
  setEncodedProjectId,
  setProjectId,
  setProjectName,
} from "../../redux/features/MyProjects/projectSlice";
import { resetRefetchProjectList } from "../../redux/features/refetch";
import { hideLoader, showLoader } from "../../redux/features/loader";
import Modals from "../../commonComponents/Modals";
import SubscribeModal from "../../commonComponents/SubscribeModal";
import PaymentSuccess from "../MySubscription/paymentSuccess";
import LimitExceededModal from "../../commonComponents/LimitExceededModal";
import NoProjectScreen from "../../commonComponents/NoDataScreens/noProjectScreen";
import UpgradeModal from "../../commonComponents/upgradeModal";
import { useFetchProjectListQuery } from "../../redux/api/MyProjects";
// Custom styles for the circular progress bar
const CircleProgressWrapper = styled(Box)({
  position: "relative",
  display: "inline-flex",
});

const CircleProgressText = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  color: "#3B82F6",
});

const HighPriorityBadge = styled(Box)({
  display: "inline-block",
  backgroundColor: "#FFCDD2",
  color: "#D32F2F",
  fontSize: "12px",
  fontWeight: "bold",
  borderRadius: "8px",
  padding: "2px 6px",
});
const MediumPriorityBadge = styled(Box)({
  display: "inline-block",
  backgroundColor: "rgba(243, 194, 0, 0.1)",
  color: "#CFA135",
  fontSize: "12px",
  fontWeight: "bold",
  borderRadius: "8px",
  padding: "2px 6px",
});
const LowPriorityBadge = styled(Box)({
  display: "inline-block",
  backgroundColor: "rgba(53, 207, 150, 0.1)",
  color: "#35CF96",
  fontSize: "12px",
  fontWeight: "bold",
  borderRadius: "8px",
  padding: "2px 6px",
});
const MyProjects = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  // Function to get initials

  const [openSort, setOpenSort] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [industry, setIndustry] = useState("");
  const [tech, setTech] = useState("");
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [boardId, setBoardId] = useState("");
  const [boardName, setBoardName] = useState("");
  const planName = useSelector((state) => state.user.planName);
  const createProjectPermission = useSelector(
    (state) => state.user?.businessUserPlanDetail?.bsi_colab_project_megement
  );

  const limitMessage = `Your current plan do not support project plan creation. Please upgrade to higher plan to create project`;
  // limitExceeed modal
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const handleOpenUpgradeModal = () => {
    setOpenUpgradeModal(true);
  };
  const handleCloseUpgradeModal = () => {
    setOpenUpgradeModal(false);
  };
  // Subscribe modal
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const handleOpenSubscribeModal = () => {
    setOpenSubscribeModal(true);
  };
  const handleCloseSubscribeModal = () => {
    setOpenSubscribeModal(false);
  };
  //  PaymentSuccess Modal
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);

  const handleOpenPaymentSuccessModal = () => {
    setOpenPaymentSuccess(true);
  };
  const handleClosePaymentSuccessModal = () => {
    setOpenPaymentSuccess(false);
  };

  // api

  // project listing api
  const {
    data: projectList,
    isError: projectListError,
    isLoading: projectLoading,
    refetch: refetchProjectList,
  } = useFetchProjectListQuery(searchValue);

  const refetchProjectsList = useSelector(
    (state) => state.refetch.refetchProjectList
  );
  useEffect(() => {
    if (refetchProjectsList) {
      refetchProjectList().finally(() => {
        dispatch(resetRefetchProjectList());
      });
    }
  }, [refetchProjectsList, refetchProjectList, dispatch]);

  // Searching logic
  const handleInputChange = (event) => {
    setSearchParams(event.target.value.trim());
  };

  const handleProjectSearch = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue("");
        await refetchProjectList();
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        await refetchProjectList();
        if (projectListError) {
          const errorMessage = projectList?.message || "No Data Found";
          showToastMessage(errorMessage, "error");
          return;
        }
      } catch (projectListError) {
        showToastMessage("An error occurred during search", "error");
      } finally {
        dispatch(hideLoader());
      }
    }
  };
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue("");
    refetchProjectList();
  };

  const handleCreateProject = () => {
    if (createProjectPermission === "Yes") {
      navigate("/add-project");
    } else {
      setOpenUpgradeModal(true);
    }
  };

  const handleProject = (projectId, projectName) => {
    const encodedProjectId = btoa(projectId.toString());
    dispatch(setEncodedProjectId(encodedProjectId));
    dispatch(setProjectId(projectId));
    dispatch(setProjectName(projectName));
    navigate(`/project-detail?projectId=${encodedProjectId}`);
  };

  useEffect(() => {
    if (projectLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [projectLoading, dispatch]);

  return (
    <>
      <Grid container rowSpacing={{ md: 3.5, xs: 1 }} columnSpacing={2.75}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Button
            variant="contained"
            onClick={handleCreateProject}
            sx={{
              textTransform: "inherit",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
              p: "10px 25px",
              boxShadow: "none",
              fontFamily: "'Poppins', sans-serif",
              "&:hover": {
                boxShadow: "none",
              },
              [theme.breakpoints.down("lg")]: {
                p: isDrawerOpen ? "10px 7px" : "10px 7px",
              },
              [theme.breakpoints.down("md")]: {
                p: isDrawerOpen ? "10px 0px" : "10px 7px",
              },
              [theme.breakpoints.down("sm")]: {
                p: "10px 25px",
                width: "100%",
              },
              [theme.breakpoints.down("xs")]: {
                p: "10px 25px",
                width: "100%",
              },
              "@media (max-width:900px) and (min-width:748px)": {
                p: isDrawerOpen ? "10px 7px" : "9px 7px",
              },
            }}
          >
            <AddOutlinedIcon />
            Create New Project
          </Button>
          {/* </Link> */}
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              [theme.breakpoints.down("sm")]: {
                flexWrap: "wrap",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                width: "45%",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  mr: 0,
                },
              }}
            >
              <Search>
                <StyledInputBase
                  placeholder="Search Projects"
                  inputProps={{ "aria-label": "search" }}
                  value={searchParams}
                  onChange={handleInputChange}
                  onKeyDown={handleProjectSearch}
                  autoFocus
                />
                {searchParams ? (
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ position: "absolute", right: 0 }}
                  >
                    <CancelIcon sx={{ color: "lightSecondary.main" }} />
                  </IconButton>
                ) : (
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "lightSecondary.main" }} />
                  </SearchIconWrapper>
                )}
              </Search>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid mb={2} container rowSpacing={2} columnSpacing={2}>
        {/* High */}
        {projectList && projectList.length > 0 ? (
          projectList?.map((project, index) => (
            <Grid item key={project._id} xs={12} md={6} lg={4} xl={4}>
              <Card
                sx={{
                  borderRadius: "12px",
                  boxShadow: 3,
                  mt: 2,
                  cursor: "pointer",
                }}
                onClick={() => handleProject(project._id, project.plan_name)}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="secondary.main"
                      sx={{
                        whiteSpace: "nowrap", // Prevent wrapping
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis
                      }}
                    >
                      <Tooltip title={project.plan_name}>
                        {/* {project.plan_name} */}
                        {project.plan_name.charAt(0).toUpperCase() +
                          project.plan_name.slice(1)}
                      </Tooltip>
                    </Typography>
                    {userType === "business_user" ? (
                      project?.solution_id?.logo ? (
                        <img
                          src={`${logoBaseUrl}${project?.solution_id?.logo}`}
                          alt={project?.solution_id?.solution_name}
                          width="100"
                          height="30"
                        />
                      ) : (
                        <Avatar
                          sx={{
                            color: "#fff",
                            bgcolor: "lightSecondary.main",
                            width: "32px",
                            height: "32px",
                          }}
                          alt="initials"
                        >
                          {project?.solution_id?.solution_name
                            .charAt(0)
                            .toUpperCase() +
                            project?.solution_id?.solution_name.slice(1)}
                        </Avatar>
                      )
                    ) : project?.userProfile?.business_logo ? (
                      <Avatar
                        src={project?.userProfile?.business_logo}
                        alt={project?.userProfile?.business_name}
                        sx={{
                          color: "success.main",
                          bgcolor: "success.lighter",
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          color: "#fff",
                          bgcolor: "lightSecondary.main",
                          width: "40px",
                          height: "40px",
                        }}
                        alt="initials"
                      >
                        {project?.userProfile?.business_name
                          ? project?.userProfile?.business_name
                              .slice(0, 2)
                              .toUpperCase()
                          : ""}
                      </Avatar>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: "5px", mb: 1 }}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color="secondary.main"
                    >
                      Solution:
                    </Typography>
                    <Tooltip title={project?.solution_id?.solution_name}>
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        color="ltheme.main"
                        sx={{
                          maxWidth: "15rem", // Maximum width
                          whiteSpace: "nowrap", // Prevent wrapping
                          overflow: "hidden", // Hide overflow
                          textOverflow: "ellipsis", // Add ellipsis
                        }}
                      >
                        {project?.solution_id?.solution_name}
                      </Typography>
                    </Tooltip>
                  </Box>
                  {userType === "business_user" ? (
                    <Box sx={{ display: "flex", gap: "5px", mb: 1 }}>
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        color="secondary.main"
                        mb={1}
                        display="block"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        Requirement Board:
                      </Typography>
                      <Tooltip
                        title={project?.requirement_board_id?.board_name}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="ltheme.main"
                          mb={1}
                          display="block"
                          sx={{
                            maxWidth: "15rem", // Maximum width
                            whiteSpace: "nowrap", // Prevent wrapping
                            overflow: "hidden", // Hide overflow
                            textOverflow: "ellipsis", // Add ellipsis
                          }}
                        >
                          {project?.requirement_board_id?.board_name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: "5px", mb: 1 }}>
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        color="secondary.main"
                        mb={1}
                        display="block"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        Business Name:
                      </Typography>
                      <Tooltip
                        title={project?.userProfile?.business_name || ""}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="ltheme.main"
                          mb={1}
                          display="block"
                          sx={{
                            maxWidth: "15rem", // Maximum width
                            whiteSpace: "nowrap", // Prevent wrapping
                            overflow: "hidden", // Hide overflow
                            textOverflow: "ellipsis", // Add ellipsis
                          }}
                        >
                          {project?.userProfile?.business_name || ""}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: "calc(100% + 18px)", // Adjust width to cancel out the padding effect
                      mt: 2,
                      mb: "-20px",
                      ml: "-10px",
                      mr: "-16px",
                      borderTop: "solid 1px rgba(162, 194, 241, 1)",
                      backgroundColor: "rgba(219, 235, 248, 0.2)",
                      py: 1,
                      // px:2
                    }}
                  >
                    {/* Progress Circle */}

                    {project.totalTasks !== 0 ? (
                      <Grid item>
                        <CircleProgressWrapper>
                          {/* Background CircularProgress for the grey color */}
                          <CircularProgress
                            variant="determinate"
                            value={100}
                            size={45}
                            thickness={2}
                            sx={{
                              color: "rgba(219, 235, 248, 0.2)", // Use a grey color for the background
                              position: "absolute",
                            }}
                          />
                          {/* Foreground CircularProgress for the actual progress */}
                          <CircularProgress
                            variant="determinate"
                            value={
                              (project.completedTasks / project.totalTasks) *
                              100
                            }
                            size={45}
                            thickness={2}
                            sx={{
                              color: "secondary.main", // The color of the progress
                              // position: "absolute",
                            }}
                          />
                          {/* Display the percentage text */}
                          <CircleProgressText sx={{ fontSize: "12px" }}>
                            {((project.completedTasks / project.totalTasks) *
                              100) %
                              1 ===
                            0
                              ? `${Math.round(
                                  (project.completedTasks /
                                    project.totalTasks) *
                                    100
                                )}%`
                              : `${(
                                  (project.completedTasks /
                                    project.totalTasks) *
                                  100
                                ).toFixed(1)}%`}
                          </CircleProgressText>
                        </CircleProgressWrapper>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CircleProgressWrapper>
                          {/* Show grey CircularProgress for 0% */}
                          <CircularProgress
                            variant="determinate"
                            value={100}
                            size={45}
                            thickness={2}
                            sx={{ color: "grey.400" }}
                          />
                          <CircleProgressText sx={{ fontSize: "12px" }}>
                            {0}%
                          </CircleProgressText>
                        </CircleProgressWrapper>
                      </Grid>
                    )}
                    <Grid item>
                      {project?.team_members
                        ?.slice(0, 4)
                        .map((teamMember, index) => {
                          if (!teamMember) return null;

                          // Extract first name, last name, and avatar
                          const { first_name, last_name, avatar } = teamMember;

                          // Generate initials
                          const initials =
                            (first_name?.[0] || "").toUpperCase() +
                            (last_name?.[0] || "").toUpperCase();

                          return (
                            <AvatarGroup
                              max={4}
                              spacing={-6} // Adjust spacing to prevent overlap
                              key={teamMember?._id}
                              sx={{
                                display: "inline-flex",
                                justifyContent: "center",
                                "& .MuiAvatar-root:last-of-type": {
                                  marginLeft: "-9px", // Adjust margin for the last avatar
                                },
                                "& .MuiAvatar-root": {
                                  width: 40, // Adjust avatar size
                                  height: 40,
                                  fontSize: 14,
                                },
                              }}
                            >
                              <Tooltip
                                title={`${first_name} ${last_name}`}
                                arrow
                              >
                                <Avatar
                                  alt={`${first_name} ${last_name}`}
                                  src={avatar}
                                  sx={
                                    !avatar
                                      ? {
                                          bgcolor: "secondary.main",
                                          color: "#fff",
                                        }
                                      : {}
                                  }
                                >
                                  {!avatar && initials}
                                </Avatar>
                              </Tooltip>
                            </AvatarGroup>
                          );
                        })}
                    </Grid>
                    {/* Priority Badge */}
                    {project?.is_active === true ? (
                      <Grid item>
                        <LowPriorityBadge>Active</LowPriorityBadge>
                      </Grid>
                    ) : (
                      <Grid item>
                        <HighPriorityBadge>In-Active</HighPriorityBadge>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : projectLoading ? (
          <>
            <ProjectSkeletonCard />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              width: "100%",
            }}
          >
            <NoProjectScreen handleCreateProject={handleCreateProject} />
          </Box>
        )}
      </Grid>
      <Modals
        open={openUpgradeModal}
        handleClose={handleCloseUpgradeModal}
        modalWidth={400}
      >
        <LimitExceededModal
          limitMessage={limitMessage}
          handleClose={handleCloseUpgradeModal}
          showToastMessage={showToastMessage}
          setOpenSubscribeModal={setOpenSubscribeModal}
        />
      </Modals>
      {/* Subscribe Modal */}
      <UpgradeModal
        open={openSubscribeModal}
        handleClose={handleCloseSubscribeModal}
        modalWidth={650}
      >
        <SubscribeModal
          limitMessage={limitMessage}
          handleClose={handleCloseSubscribeModal}
          setOpenPaymentSuccess={setOpenPaymentSuccess}
          showToastMessage={showToastMessage}
        />
      </UpgradeModal>
      <Modals
        open={openPaymentSuccess}
        handleClose={handleClosePaymentSuccessModal}
        modalWidth={450}
      >
        <PaymentSuccess handleClose={handleClosePaymentSuccessModal} />
      </Modals>
    </>
  );
};

export default MyProjects;
