import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;
export const userBaseApis = createApi({
  reducerPath: "userBaseApis",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = store.getState().user.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // ─── GET ───

    //   Subscription Detail
    fetchAllSubscription: builder.query({
      query: () => ({
        url: `user/all_subscription`,
        method: "GET",
      }),
      transformErrorResponse: (response) => {
        return response.data ? response.data : [];
      },
      refetchOnWindowFocus: true,
      onError: (error) => {
        console.error("Fetch user subscription details error:", error);
      },
    }),

    //  All City List
    fetchCityList: builder.query({
      query: () => ({
        url: `city`,
        method: "GET",
      }),
      transformErrorResponse: (response) => {
        return response.data ? response.data : [];
      },
      refetchOnWindowFocus: true,
      onError: (error) => {
        console.error("Fetch user details error:", error);
      },
    }),

    //  All City List By Id
    fetchCityListById: builder.query({
      query: ({ cityId }) => ({
        url: `city/country/${cityId}`,
        method: "GET",
      }),
      transformErrorResponse: (response) => {
        return response.data ? response.data : [];
      },
      refetchOnWindowFocus: true,
      onError: (error) => {
        console.error("Fetch user details error:", error);
      },
    }),

    //  All Country List
    fetchCountryList: builder.query({
      query: () => ({
        url: `country`,
        method: "GET",
      }),
      transformErrorResponse: (response) => {
        return response.data ? response.data : [];
      },
      refetchOnWindowFocus: true,
      onError: (error) => {
        console.error("Fetch user details error:", error);
      },
    }),

    //  User Detail
    fetchUserDetails: builder.query({
      query: () => ({
        url: `user/token`,
        method: "GET",
      }),
      transformResponse: (response) => {
        const savedData = response.data;
        // Helper function to get the latest active transaction
        const getRelevantTransaction = (transactions) => {
          if (!transactions || transactions.length === 0) return null;

          // Example: Find the most recent active transaction
          return (
            transactions
              .filter((transaction) => transaction.status === "active")
              .sort(
                (a, b) => new Date(b.expireDate) - new Date(a.expireDate)
              )[0] || null
          );
        };

        // Extract the relevant transaction
        const relevantTransaction = getRelevantTransaction(
          savedData?.subscription?.transactions
        );

        // Define the subscription object based on the available data
        const subscription = relevantTransaction
          ? {
              ...relevantTransaction,
            }
          : savedData?.subscription
          ? {
              subscriptionPlanId: savedData.subscription._id || null,
              planType: savedData.subscription.plan_type || null,
              planName: savedData.subscription.plan_name || null,
              planSlug: savedData.subscription.plan_slug || null,
              planDescription: savedData.subscription.plan_description || null,
              planValues: Array.isArray(savedData.subscription.plan_values)
                ? savedData.subscription.plan_values.map((item) => ({
                    id: item._id,
                    key: item.key,
                    name: item.name,
                    value: item.value,
                    type: item.type,
                  }))
                : [],
              isTrialAvailable:
                savedData.subscription.is_trial_available || null,
              isActive: savedData.subscription.is_active || null,
              trialPeriodDays: savedData.subscription.trial_period_days || null,
            }
          : null;

        // Construct the final transformed response
        return {
          focusIndustry: savedData.focus_industry || [],
          id: savedData._id,
          salutation: savedData.salutation,
          firstName: savedData.first_name,
          status: savedData.status,
          lastName: savedData.last_name,
          email: savedData.email,
          countryId: savedData.country_id._id,
          countryName: savedData.country_id?.name,
          phoneCode: savedData.phone_code,
          countryShortCode: savedData.country_short_code,
          phoneNumber: savedData.phone_number,
          isVerified: savedData.isVerified,
          updatedAt: savedData.updated_at,
          createdAt: savedData.created_at,
          userType: savedData.user_type,
          logo: savedData.avatar ? savedData.avatar : "",
          thumbnailAvatar: savedData?.thumbnailAvatar,
          subscription, // Include only the relevant subscription object
          customerId:
            savedData?.result?.map(
              (subscribeCustomer, index) =>
                subscribeCustomer?.firstTransaction?.customer
            ) || "",
          planStartDate:
            savedData?.result?.map(
              (subscribeCustomer, index) =>
                subscribeCustomer?.firstTransaction?.current_period_start
            ) || "",
          planEndDate:
            savedData?.result?.map(
              (subscribeCustomer, index) =>
                subscribeCustomer?.firstTransaction?.current_period_end
            ) || "",
          trialStartDate:
            savedData?.result?.map(
              (subscribeCustomer, index) =>
                subscribeCustomer?.firstTransaction?.trial_start
            ) || "",
          trialEndDate:
            savedData?.result?.map(
              (subscribeCustomer, index) =>
                subscribeCustomer?.firstTransaction?.trial_end
            ) || "",
          subScriptionStatus: savedData?.result?.map(
            (subscribeCustomer, index) =>
              subscribeCustomer?.firstTransaction?.status
          ),
          selectedPlanId:
            savedData?.result?.map((subscribeCustomer, index) =>
              subscribeCustomer?.firstTransaction?.items?.data?.map(
                (plan, index) => plan?.plan?.id
              )
            ) || "",
        };
      },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error("Fetch user details error:", error);
      },
    }),

    // userOverview
    fetchUserOverview: builder.query({
      query: () => ({
        url: `user/overview`,
        method: "GET",
      }),
      transformErrorResponse: (response) => {
        return response.data ? response.data : [];
      },
      refetchOnWindowFocus: true,
      onError: (error) => {
        console.error("Fetch user details error:", error);
      },
    }),

    // businessProfile
    fetchBusinessUserDetails: builder.query({
      query: (userId) => ({
        url: `user/profile`,
        method: "GET",
      }),
      transformResponse: (response) => {
        return response;
      },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error("Fetch user details error:", error);
        // Handle error cases as per your application's requirements
      },
    }),

    // MasterData
    fetchMasterDetails: builder.query({
      query: () => ({
        url: `master/master-options`,
        method: "GET",
      }),
      transformResponse: (response) => {
        // Extract relevant data from the response
        const { statusCode, status, message, data } = response;

        // Return the extracted data
        return {
          statusCode,
          status,
          message,
          masterData: data,
        };
      },
      refetchOnWindowFocus: true,
      // retry: RetryOnError,
      onError: (error) => {
        console.error("Fetch master  details error:", error);
        // Handle error cases as per your application's requirements
      },
    }),
   
    // IndustryList
    fetchIndustryList: builder.query({
        query: () => ({
            url: 'industry',
            method: 'GET',
        }),
        transformErrorResponse: (response) => {
            return response;
        },
        refetchOnWindowFocus: true,
        onError: (error) => {
            console.error('Fetch user details error:', error);

        },
    }),

    // UseCaseList
    fetchUseCaseList: builder.query({
        query: () => ({
            url: `usecase`,
            method: 'GET',
        }),
        transformErrorResponse: (response) => {
            return response.data;
        },
        refetchOnWindowFocus: true,
        onError: (error) => {
            console.error('Fetch user details error:', error);

        },
    }),

    // ─── POST ───

    // Create Business User Profile
    createBusinessUserProfile: builder.mutation({
      query: ({
        userId,
        userType,
        businessName,
        businessUrl,
        phoneCode,
        phoneNumber,
        role,
        countryId,
        techType,
        industryType,
        useCaseType,
        companySize,
        companyRevenue,
        linkedinUrl,
      }) => ({
        url: "user/business/create-business-user-profile-first-form",
        method: "POST",
        body: {
          user_id: userId,
          user_type: userType,
          business_name: businessName,
          business_url: businessUrl,
          phone_code: phoneCode,
          phone_number: phoneNumber,
          company_role: role,
          country_id: countryId,
          tech_type: techType,
          industry_type: industryType,
          usecase_type: useCaseType,
          company_size: companySize,
          company_revenue_level: companyRevenue,
          linkedin_url: linkedinUrl,
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),

    // ─── PUT ───

    // update user Profile
    updateUserProfile: builder.mutation({
      query: ({ userId, formData }) => {
        return {
          url: `user/update`,
          method: "PUT",
          body: formData,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),

    // updateBusiness Profile
    updateBusinessUserProfile: builder.mutation({
      query: ({ profileId, formData }) => ({
        url: `user/business/update-business-user-profile-first-form/${profileId}`,
        method: "PUT",
        body: formData,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),

    // update Solution Provider Profile
    updateSolutionProviderProfile: builder.mutation({
      query: ({
        profileId,
        userId,
        userType,
        cityId,
        entityType,
        businessName,
        companyFoundedData,
        businessUrl,
        phoneCode,
        phoneNumber,
        role,
        countryId,
        companySize,
        companyRevenue,
        fundingAmount,
        investmentStage,
        lookingForFunding,
      }) => ({
        url: `/user/solution-provider/update-solution-provider-profile-form/${profileId}`,
        method: "PUT",
        body: {
          user_id: userId,
          user_type: userType,
          business_name: businessName,
          business_url: businessUrl,
          phone_code: phoneCode,
          phone_number: phoneNumber,
          company_role: role,
          country_id: countryId,
          city_id: cityId,
          entity_type: entityType,
          company_founded_date: companyFoundedData,
          funding_amount: fundingAmount,
          company_size: companySize,
          company_revenue_level: companyRevenue,
          investment_stage: investmentStage,
          looking_for_startup_funding: lookingForFunding,
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),

    // update user password
    updateUserPassword: builder.mutation({
      query: ({ userId, currentPassword, newPassword, confirmPassword }) => ({
        url: `api/user/update-password`,
        method: "POST",
        body: {
          user_id: userId,
          current_password: currentPassword,
          new_password: newPassword,
          new_confirm_password: confirmPassword,
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const {
  useFetchAllSubscriptionQuery,
  useFetchCityListQuery,
  useFetchCityListByIdQuery,
  useFetchCountryListQuery,
  useFetchUserDetailsQuery,
  useCreateBusinessUserProfileMutation,
  useFetchUserOverviewQuery,
  useFetchBusinessUserDetailsQuery,
  useFetchMasterDetailsQuery,
  useUpdateBusinessUserProfileMutation,
  useUpdateSolutionProviderProfileMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserProfileMutation,
  useFetchIndustryListQuery,
  useFetchUseCaseListQuery,
} = userBaseApis;

export default userBaseApis;
