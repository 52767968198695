import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  Avatar,
} from "@mui/material";

const SkeletonCard = () => {
  return (
    <Grid item xs={12} md={4} lg={4} xl={4} sx={{ mt: 1 }}>
     <Card
    sx={{
      borderRadius: "12px",
      boxShadow: 3,
      mt: 2,
      cursor: "pointer",
    }}
  >
    <CardContent>
      {/* Title Skeleton */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Skeleton
          variant="text"
          width="40%"
          height={25}
          sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }} // Lighter grey
        />
        <Skeleton
          variant="circular"
          width={30}
          height={30}
          sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
        />
      </Box>

      {/* Subtitle Skeleton */}
      <Skeleton
        variant="text"
        width="60%"
        height={20}
        mb={2}
        sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
      />
      <Skeleton
        variant="text"
        width="80%"
        height={15}
        mb={2}
        mt={2}
        sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
      />

      {/* Bottom Section Skeleton */}
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
        {/* Progress Circle Skeleton */}
        <Grid item>
          <Skeleton
            variant="circular"
            width={50}
            height={50}
            sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
          />
        </Grid>

        {/* Avatar Group Skeleton */}
        <Grid item>
          <Box display="flex" gap={1}>
            {Array.from({ length: 3 }).map((_, idx) => (
              <Skeleton
                key={idx}
                variant="circular"
                width={30}
                height={30}
                sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
              />
            ))}
          </Box>
        </Grid>

        {/* Priority Badge Skeleton */}
        <Grid item>
          <Skeleton
            variant="rectangular"
            width={60}
            height={20}
            sx={{ bgcolor: "rgba(0, 0, 0, 0.05)" }}
          />
        </Grid>
      </Grid>
    </CardContent>
     </Card>
    </Grid>

  );
};

const ProjectSkeletonCard = () => {
  return (
    <Grid container spacing={2}>
      {Array.from({ length: 3 }).map((_, idx) => (
        <SkeletonCard key={idx} />
      ))}
    </Grid>
  );
};

export default ProjectSkeletonCard;
