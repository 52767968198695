import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CardActions,
  Divider,
  IconButton,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  keyframes,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRefetchUserData } from "../../redux/features/refetch";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../redux/features/loader";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PlanCardSkull from "../../commonComponents/Skeleton/PlanCardSkull";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@emotion/react";
import Modals from "../../commonComponents/Modals";
import PaymentSuccess from "./paymentSuccess";
import StripePayment from "./stripePaymentModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getImages } from "../../commonComponents/const";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useFetchAllSubscriptionQuery } from "../../redux/api/Users";
import { useFetchPricingTableDetailQuery, useManageAllSubscriptionPlanMutation, useManageSubscriptionPlanMutation, useUpgradeSubscriptionPlanMutation } from "../../redux/api/MySubscription";

const MySubscription = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector((state) => state.drawer.open);

  const invoices = [
    { date: "2024-11-01", plan: "Plus", amount: "$999", status: "Paid" },
    { date: "2024-10-01", plan: "Pro", amount: "$4999", status: "Paid" },
    { date: "2024-09-01", plan: "Pro", amount: "$999", status: "Paid" },
  ];
  const solutionProviderFeatures = [
    { name: "Number of Users", lite: true, plus: true, pro: true },
    { name: "Featured Listing", lite: true, plus: true, pro: true },
    { name: "Number of Solutions", lite: false, plus: true, pro: true },
    {
      name: "BSI Colab - Requirements (leads)",
      lite: true,
      plus: true,
      pro: true,
    },
    { name: "BSI Colab - Messaging", lite: false, plus: true, pro: true },
    { name: "BSI Colab - Drive", lite: true, plus: true, pro: true },
    { name: "Support", lite: false, plus: true, pro: true },
    { name: "Product Video", lite: false, plus: true, pro: true },
  ];
  const businessUserFeatures = [
    { name: "Number of Users", lite: true, plus: true, pro: true },
    { name: "Featured Listing", lite: true, plus: true, pro: true },
    {
      name: "Discover & Compare AI Solutions",
      lite: true,
      plus: true,
      pro: true,
    },
    { name: "Requirement Creation", lite: true, plus: true, pro: true },
    { name: "BSI Colab - Messaging", lite: false, plus: true, pro: true },
    { name: "BSI Colab - Drive", lite: true, plus: true, pro: true },
    { name: "Support", lite: false, plus: true, pro: true },
  ];

  const fadeInOut = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;
  const shineAnimation = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;

  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // State
  const laravelPricingPage = process.env.REACT_APP_LARAVEL_WEB_PRICING_URL;
  const businessUserPricingTableId = process.env.REACT_APP_BUSINESS_USER_STRIPE_PRICING_TABLE_ID;
  const solutionProviderPricingTableId = process.env.REACT_APP_Solution_Provider_STRIPE_PRICING_TABLE_ID;
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const queryParams = new URLSearchParams(window.location.search);
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const userEmail = useSelector((state) => state.user.data.email);
  const userData = useSelector((state) => state.user.data);
  const customerId = useSelector((state) => state.user.data.customerId[0]) ?? "";
  const selectedPlanId =  useSelector((state) => state.user.data.selectedPlanId) ?? "";
  const planStartDate = useSelector((state) => state.user.data.planStartDate[0]) ?? "";
  const planEndDate = useSelector((state) => state.user.data.planEndDate[0]) ?? "";
  const trialStartDate = useSelector((state) => state.user.data.trialStartDate[0]) ?? "";
  const trialEndDate = useSelector((state) => state.user.data.trialEndDate[0]) ?? "";
  const subscriptionStatus = useSelector((state) => state.user.data.subScriptionStatus) ?? "";
  const planName = useSelector((state) => state.user.planName);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState(false);
  const [openStripePaymentModal, setOpenStripePaymentModal] = useState(false);
  const [newPlanName, setNewPlanName] = useState("");
  const [animate, setAnimate] = useState(false);
  const navigate = useNavigate();

  const selectedPricingTableId = userType === "solution_provider" ? solutionProviderPricingTableId : businessUserPricingTableId;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    // Format the date
    let formattedDate = date.toLocaleDateString("en-US", {
      month: "short", // "Jan"
      day: "2-digit", // "02"
      year: "numeric", // "2025"
    });

    // Replace the first comma (after month) with a period
    return formattedDate.replace(".", ",");
  };

  // Check if trial has ended
  const currentTimestamp = Math.floor(Date.now() / 1000); // Get current time in seconds
  const isTrialActive = trialEndDate && trialEndDate > currentTimestamp;


  const handleOpenPaymentSuccessModal = () => {
    setOpenPaymentSuccess(true);
  };
  const handleClosePaymentSuccessModal = () => {
    setOpenPaymentSuccess(false);
  };

  const handleOpenStripePaymentModal = (latestPlanName) => {
    setNewPlanName(latestPlanName);
    setOpenStripePaymentModal(true);
  };
  const handleCloseStripePaymentModal = () => {
    setOpenStripePaymentModal(false);
  };

  const handleToggle = (event, newCycle) => {
    if (newCycle) {
      setAnimate(true);
      setTimeout(() => {
        setBillingCycle(newCycle);
        setAnimate(false);
      }, 400); // Animation duration
    }
  };



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paymentConfirmation = queryParams.get("type");
    
    if (paymentConfirmation === "success") {
      setOpenPaymentSuccess(true);
    }
  }, []);

  // Api's

  const {
    data: subscriptionData = { data: [] },
    isError: subscriptionError,
    isLoading: subscriptionLoading,
    refetch: refetchSubscription,
  } = useFetchAllSubscriptionQuery();

  const {
    data: pricingTableData,
    isError: pricingTableError,
    isLoading: pricingTableLoading,
    refetch: refetchPricingTableData,
  } = useFetchPricingTableDetailQuery();


  // Api Call
  const [upgradeSubscriptionPlan, { isLoading: upgradePlanLoading }] =
    useUpgradeSubscriptionPlanMutation();
  const [manageSubscriptionPlan, { isLoading: managePlanLoading }] =
    useManageSubscriptionPlanMutation();
  const [manageAllSubscriptionPlan, { isLoading: manageAllPlanLoading }] =
  useManageAllSubscriptionPlanMutation();

  const handleManageSubscription = async (newPlanName) => {
    if (newPlanName !== "Lite") {
      try {
        // Call the API to upgrade the subscription
        const response = await manageSubscriptionPlan({
          customerId: customerId,
        }).unwrap();

        if (response.statusCode === 200) {
          const customerPortalUrl = response?.data;
          window.location.href = customerPortalUrl;
        } else {
          showToastMessage("Plan upgrade failed", "error");
        }
      } catch (error) {
        showToastMessage(error?.message || "Plan Upgrade Failed", "error");
      }
    }
  };
  const handleManageAllSubscription = async () => {
    if (planName !== "Lite") {
      try {
        // Call the API to upgrade the subscription
        const response = await manageAllSubscriptionPlan({
          customerId: customerId,
        }).unwrap();

        if (response.statusCode === 200) {
          const customerPortalUrl = response?.data;
          window.location.href = customerPortalUrl;
        } else {
          showToastMessage("Plan upgrade failed", "error");
        }
      } catch (error) {
        showToastMessage(error?.message || "Plan Upgrade Failed", "error");
      }
    }
  };

  const upgradeSubscription = async (productId , planTenure , planPrize) => {
      try {
        // Call the API to upgrade the subscription
        const response = await upgradeSubscriptionPlan({
          productId: productId,
          planType: planTenure,
          planPrize: planPrize,
          isTrial: customerId ? false : true,
        }).unwrap();

        if (response.statusCode === 200) {
          window.location.href = response.data;
        } else {
          showToastMessage("Plan upgrade failed", "error");
        }
      } catch (error) {
        showToastMessage(error?.message || "Plan Upgrade Failed", "error");
      }
  };


  useEffect(() => {
    if (managePlanLoading || upgradePlanLoading || manageAllPlanLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [managePlanLoading, upgradePlanLoading,manageAllPlanLoading, dispatch]);

  return (
    <>

      <Box sx={{ mt: 3 }}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{
              fontSize: "1.4em",
              fontWeight: "700",
              color: "secondary.main",
            }}
          >
            Choose the Right Plan for Your Business Goals
          </Typography>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{
              fontSize: "1.2em",
              fontWeight: "500",
              color: "#106eed",
            }}
          >
            Empower your solutions with curated visibility, efficient lead
            management, and streamlined collaboration tools. Find the plan that
            fits your business needs and unlock new opportunities.
          </Typography>
          {
            customerId ? 
            (
   <Box sx={{ mt: 6, p: 1 }}>
            <Card
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                p: 3,
                boxShadow: 3,
                backgroundColor: "#ecf6ff",
                border: "1px solid #001a8c",
                borderRadius: "10px",
                transition: "all 0.5s ease-in-out 0s",
                "&:hover": {
                  background: "#ffffff",
                  boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                >
                  Current Plan
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ mt: 1, color: "secondary.main", fontWeight: 600 }}
                >
                  {planName}
                </Typography>
              </CardContent>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                {isTrialActive ? (
                  <>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        sx={{ color: "secondary.main", fontWeight: 600 }}
                      >
                        Trial Start Date:
                      </Typography>
                      {
                        trialStartDate ? 
                        (
                          <Typography
                          sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                        >
                          {formatDate(trialStartDate)} 
                        </Typography>
                        ):
                        (
                          <Typography
                          sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                        >
                          - - - - 
                        </Typography>
                        )
                      }
                     
                    </Box>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        sx={{ color: "secondary.main", fontWeight: 600 }}
                      >
                        Trial End Date:
                      </Typography>
                      
                      {
                        trialEndDate ? 
                        (
                          <Typography
                        sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                      >
                        {formatDate(trialEndDate)}
                      </Typography>
                        ):
                        (
                          <Typography
                          sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                        >
                          - - - - 
                        </Typography>
                        )
                      }
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        sx={{ color: "secondary.main", fontWeight: 600 }}
                      >
                        Plan Start Date:
                      </Typography>
                      {
                        planStartDate ? 
                        (
                          <Typography
                        sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                      >
                        {formatDate(planStartDate)}
                      </Typography>
                        ):
                        (
                          <Typography
                          sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                        >
                          - - - - 
                        </Typography>
                        )
                      }
                    </Box>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        sx={{ color: "secondary.main", fontWeight: 600 }}
                      >
                        Plan End Date:
                      </Typography>
                      {
                        planEndDate ? 
                        (
                          <Typography
                        sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                      >
                        {formatDate(planEndDate)}
                      </Typography>
                        ):
                        (
                          <Typography
                          sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                        >
                          - - - - 
                        </Typography>
                        )
                      }
                    </Box>
                  </>
                )}
                {/* <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
                    Subscription Status:
                  </Typography>
                  {subscriptionStatus == "active" ? (
                    <Typography
                      sx={{
                        display: "inline-block",
                        backgroundColor: "rgba(53, 207, 150, 0.1)",
                        color: "#35CF96",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "2px 6px",
                      }}
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        display: "inline-block",
                        backgroundColor: "#FFCDD2",
                        color: "#D32F2F",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "2px 6px",
                      }}
                    >
                      In-Active
                    </Typography>
                  )}
                </Box> */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography sx={{ color: "secondary.main", fontWeight: 600 }}>
                    Subscription Owner:
                  </Typography>
                  <Typography
                    sx={{ color: "lightSecondary.main", fontWeight: 400 }}
                  >
                    {userEmail}
                  </Typography>
                </Box>
              </CardContent>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: 2 , justifyContent:'center' , alignItems:"center" }}
              >
                <Box
  component="img"
  src={getImages("upgradeCrown.png")}
  sx={{
    width: "60px",
    height: "70px",
    // filter:   "brightness(0) saturate(100%) invert(21%) sepia(98%) saturate(1800%) hue-rotate(202deg) brightness(80%) contrast(80%)",
    mixBlendMode: "multiply",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  }}
/>
                <Button
                  onClick={handleManageAllSubscription}
                  variant="contained"
                  disabled={manageAllPlanLoading}
                  sx={{
                    textTransform: "inherit",
                    display: "flex",
                    gap: "0.3125rem", // 5px → rem
                    alignItems: "center",
                    justifyContent: "center",
                    p: "0.625rem 1.5625rem", // 10px 25px → rem
                    boxShadow: "none",
                    fontFamily: "'Poppins', sans-serif",
                    "&:hover": {
                      boxShadow: "none",
                    },
                    [theme.breakpoints.down("lg")]: {
                      p: isDrawerOpen ? "0.625rem 0.4375rem" : "0.625rem 0.4375rem", // 10px 7px
                    },
                    [theme.breakpoints.down("md")]: {
                      p: isDrawerOpen ? "0.625rem 0rem" : "0.625rem 0.4375rem", // 10px 0px / 10px 7px
                    },
                    [theme.breakpoints.down("sm")]: {
                      p: "0.3125rem 1.5625rem", // 5px 25px
                      width: "100%",
                    },
                    [theme.breakpoints.down("xs")]: {
                      p: "0.625rem 1.5625rem", // 10px 25px
                      width: "100%",
                    },
                    "@media (max-width:900px) and (min-width:748px)": {
                      p: isDrawerOpen ? "0.625rem 0.4375rem" : "0.5625rem 0.4375rem", // 10px 7px / 9px 7px
                    },
                  }}
                >
                  Manage Subscription
                </Button>
              </CardContent>
            </Card>
          </Box>
            ):
            (
              <>
              </>
            )
          }
          
        </Box>
        {/* Toggle Button */}
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Box sx={{ textAlign: "center", marginBottom: 3, mt: 2 ,}}>
            <ToggleButtonGroup
              value={billingCycle}
              exclusive
              onChange={handleToggle}
            >
              <ToggleButton
                value="monthly"
                sx={(theme) => ({
                  borderRadius: "8px",
                  textTransform: "none",
                  width: "110px",
                  height: "40px",
                  backgroundColor:
                    billingCycle === "monthly"
                      ? "#106EED  !important"
                      : "hsla(0,0%, 10%, 0.07)  !important",
                  color:
                    billingCycle === "monthly"
                      ? "#fff !important"
                      : "lightSecondary.main !important",

                  "&:hover": {
                    backgroundColor:
                      billingCycle === "monthly"
                        ? "#106EED  !important"
                        : "hsla(0,0%, 10%, 0.07)  !important",
                    color:
                      billingCycle === "monthly"
                        ? "#fff !important"
                        : "lightSecondary.main !important",
                  },
                })}
              >
                Monthly
              </ToggleButton>
              <ToggleButton
                value="yearly"
                sx={(theme) => ({
                  borderRadius: "8px",
                  textTransform: "none",
                  width: "110px",
                  height: "40px",
                  backgroundColor:
                    billingCycle === "yearly"
                      ? "#106EED  !important"
                      : "hsla(0,0%, 10%, 0.07) !important",
                  color:
                    billingCycle === "yearly"
                      ? "#fff !important"
                      : "lightSecondary.main !important",

                  "&:hover": {
                    backgroundColor:
                      billingCycle === "yearly"
                        ? "#106EED !important"
                        : "hsla(0,0%, 10%, 0.07)  !important",
                    color:
                      billingCycle === "yearly"
                        ? "#fff !important"
                        : "lightSecondary.main !important",
                  },
                })}
              >
                Yearly
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
       <SwitchTransition>
            <CSSTransition
              key={billingCycle}
              timeout={300}
              classNames="fade"
            >
          <Grid
            container
            spacing={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {pricingTableLoading ? (
              <>
                <PlanCardSkull />
              </>
            ) : userType == "solution_provider" ? (
              pricingTableData?.solutionProviderPlans[billingCycle]
                // .slice()
                // .reverse()
                .map((plan, index) => (
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    key={index}
                    sx={{
                      mt: 2,
                      padding: "16px",
                      backgroundColor:
                        plan.title == "Plus"
                          ? "hsla(0,0%, 10%, 0.06)"
                          : "#dbebf8",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "none",
                        borderRadius: "10px",
                        display: "flex",
                        maxWidth: "250px",
                        justifyContent: "center",
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            fontSize: "0.8em",
                            fontWeight: 600,
                            lineHeight: "1.2em",
                            color: "hsla(0,0%, 10%, 1.1)",
                            backgroundColor: "#ffffff",
                            padding: "2px 8px",
                            textTransform: "none",
                            boxShadow: "none",
                            minWidth: "auto",
                            visibility:
                              plan.title == "Plus" ? "visible" : "hidden",
                            textTransform: "none",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background:
                                "linear-gradient(150deg, rgba(245, 235, 235, 0.5) 30%, rgba(245, 245, 245, 0.8) 50%, rgba(235, 235, 235, 0.5) 70%)",
                              backgroundSize: "200% 100%",
                              animation: `${shineAnimation} 3s linear infinite`,
                              borderRadius: "inherit", // To match the parent's border-radius
                              zIndex: 1,
                              pointerEvents: "none", // Makes the shimmer non-interactive
                            },
                          }}
                        >
                          Most popular
                        </Button>
                        <Typography
                          sx={{
                            fontSize: "1.4em",
                            fontWeight: 500,
                            lineHeight: "1.2em",
                            // color: "secondary.main",
                            color: "black",
                            mb: 1,
                            mt: 2,
                          }}
                        >
                          {plan.title}
                        </Typography>
                        <Box
                          sx={{
                            mb: 1,
                            mt: 5,
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "2.6em",
                              fontWeight: 600,
                              lineHeight: "1.4em",
                              // color: "lightSecondary.main",
                            }}
                          >
                            <span style={{ marginRight: "2px" }}>$</span>
                            {plan.price}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8em",
                              fontWeight: 600,
                              lineHeight: "1.3em",
                              color: "hsla(0,0%, 10%, 0.5)",
                              // color: "lightSecondary.main",
                              // mb: 1,
                              mt: 1,
                            }}
                          >
                            per <br />
                            {plan.tenure}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          color={
                            planName === plan.planName ? "secondary" : "primary"
                          }
                          sx={{
                            textTransform: "none",
                            mb: 2,
                            width: "230px",
                            height: "40px",
                            border: "8px",
                          }}
                          disabled={
                            planName === plan.planName || plan.planName === "Lite" || managePlanLoading || upgradePlanLoading
                          }
                          // onClick={()=>upgradeSubscription(plan.planName)}
                          onClick={() => {
                            if (customerId) {
                              handleManageSubscription(plan.planName); // Call this if customerId is present
                            } else {
                              upgradeSubscription(plan.productId, plan.tenure ,plan.price); // Otherwise, call this
                            }
                          }}
                        >
                            {
                             !customerId && plan.planName !== "Lite" ? "Start Free Trial" 
                            :planName === plan.planName
                            ? "Current"
                            : plan.planName === "Lite"
                            ? "- - - -"
                            : plan.buttonText}
                        </Button>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 450,
                              textAlign: "left",
                              fontSize: "0.9em",
                              color: "hsla(0,0%, 10%, 0.8)",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            This Includes:
                          </Typography>
                          <Box sx={{ textAlign: "left" }}>
                            {plan.features.map((feature, i) => (
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  // alignItems: "center",
                                  mb: 2,
                                  mt: 1,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: "hsla(0,0%, 10%, 0.6)",
                                    fontSize: "14px",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                    lineHeight: "1.5em",
                                    color: "hsla(0,0%, 10%, 0.8)",
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
            ) : (
              pricingTableData?.businessUserPlans[billingCycle]
              // .slice()
              // .reverse()
              .map(
                (plan, index) => (
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    key={index}
                    sx={{
                      mt: 2,
                      padding: "16px",
                      backgroundColor:
                        plan.title == "Plus"
                          ? "hsla(0,0%, 10%, 0.06)"
                          : "#dbebf8",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "none",
                        borderRadius: "10px",
                        display: "flex",
                        width: "250px",
                        justifyContent: "center",
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            fontSize: "0.8em",
                            fontWeight: 600,
                            lineHeight: "1.2em",
                            color: "hsla(0,0%, 10%, 1.1)",
                            backgroundColor: "#ffffff",
                            padding: "2px 8px",
                            // borderRadius: "2px",
                            textTransform: "none",
                            boxShadow: "none",
                            minWidth: "auto",
                            visibility:
                              plan.title == "Plus" ? "visible" : "hidden",
                            textTransform: "none",
                            // background: "linear-gradient(120deg, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.1) 70%)",
                            // background: "linear-gradient(120deg, rgba(7, 57, 133, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(7, 57, 133, 0.2) 70%)",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background:
                                "linear-gradient(150deg, rgba(245, 235, 235, 0.5) 30%, rgba(245, 245, 245, 0.8) 50%, rgba(235, 235, 235, 0.5) 70%)",
                              backgroundSize: "200% 100%",
                              animation: `${shineAnimation} 3s linear infinite`,
                              borderRadius: "inherit", // To match the parent's border-radius
                              zIndex: 1,
                              pointerEvents: "none", // Makes the shimmer non-interactive
                            },
                          }}
                        >
                          Most popular
                        </Button>
                        <Typography
                          sx={{
                            fontSize: "1.4em",
                            fontWeight: 500,
                            lineHeight: "1.2em",
                            // color: "secondary.main",
                            color: "black",
                            mb: 1,
                            mt: 2,
                          }}
                        >
                          {plan.title}
                        </Typography>
                        <Box
                          sx={{
                            mb: 1,
                            mt: 5,
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "2.6em",
                              fontWeight: 600,
                              lineHeight: "1.4em",
                              // color: "lightSecondary.main",
                            }}
                          >
                            <span style={{ marginRight: "2px" }}>$</span>
                            {plan.price}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "0.8em",
                              fontWeight: 600,
                              lineHeight: "1.3em",
                              color: "hsla(0,0%, 10%, 0.5)",
                              // color: "lightSecondary.main",
                              // mb: 1,
                              mt: 1,
                            }}
                          >
                            per <br />
                            {plan.tenure}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          color={
                            planName === plan.planName ? "secondary" : "primary"
                          }
                          sx={{
                            textTransform: "none",
                            mb: 2,
                            width: "230px",
                            height: "40px",
                            border: "8px",
                          }}
                          disabled={
                            planName === plan.planName || plan.planName === "Lite" || managePlanLoading || upgradePlanLoading
                          }
                          // onClick={()=>upgradeSubscription(plan.planName)}
                          onClick={() => {
                            if (customerId) {
                              handleManageSubscription(plan.planName); // Call this if customerId is present
                            } else {
                              upgradeSubscription(plan.productId, plan.tenure , plan.price); // Otherwise, call this
                            }
                          }}
                        >
                          {
                             !customerId && plan.planName !== "Lite" ? "Start Free Trial" 
                            :planName === plan.planName
                            ? "Current"
                            : plan.planName === "Lite"
                            ? "- - - -"
                            : plan.buttonText}
                        </Button>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 450,
                              textAlign: "left",
                              fontSize: "0.9em",
                              color: "hsla(0,0%, 10%, 0.8)",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            This Includes:
                          </Typography>
                          <Box sx={{ textAlign: "left" }}>
                            {plan.features.map((feature, i) => (
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  // alignItems: "center",
                                  mb: 2,
                                  mt: 1,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: "hsla(0,0%, 10%, 0.6)",
                                    fontSize: "14px",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                    lineHeight: "1.5em",
                                    color: "hsla(0,0%, 10%, 0.8)",
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )
              )
            )}
          </Grid>
          </CSSTransition>
        </SwitchTransition>
          {/* <Box sx={{ mt: 6 }}>
            <stripe-pricing-table
              pricing-table-id={selectedPricingTableId} // Replace with your Pricing Table ID
              publishable-key={stripePublishableKey} // Replace with your Stripe Publishable Key
              client-reference-id={userId}
              customer-email={userEmail}
              class="pricing-table"
            ></stripe-pricing-table>
          </Box> */}
        </Box>
        {/* ) : ( */}
      
        {/* )} */}
        {/* <Box style={{ marginTop: "2rem" }} sx={{ padding: 3 }}>
          <Typography
            variant="h6"
            align="center" 
            gutterBottom
            sx={{
              fontSize: "1.3rem",
              fontWeight: "600",
              color: "secondary.main",
            }}
          >
            Invoice History
          </Typography>
          <TableContainer sx={{ mt: 3 }} component={Paper}>
            <Table>
              <TableHead sx={{ bgcolor: "secondary.main" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>Date</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Plan</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Amount</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.date}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.plan}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.amount}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>  */}
        {/* Comparison Table */}
        {/* {userType == "solution_provider" ? (
          <Box sx={{ padding: 3 }}>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontSize: "1.4em",
                fontWeight: "700",
                color: "secondary.main",
              }}
            >
              Compare Plans
            </Typography>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontSize: "1.2em",
                fontWeight: "500",
                color: "#106eed",
              }}
            >
              Empower your solutions with curated visibility, efficient lead
              management, and streamlined collaboration tools.
              <br /> Please find the plan that fits your business needs and
              unlock new opportunities.
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "#ecf6ff",
                border: "1px solid #001a8c",
                borderRadius: "10px",
                mt: 2,
                transition: "all 0.5s ease-in-out 0s",
                "&:hover": {
                  background: "#ffffff",
                  boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                },
              }}
            >
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Features
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Lite
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Plus
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Pro
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {solutionProviderFeatures.map((feature, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          border: "1px solid #ddd",
                          fontWeight: 600,
                          border: "1px solid #ddd",
                          width: "25%",
                          fontSize: "0.9em",
                          lineHeight: "1.2em",
                          color: "secondary.main",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7em",
                            lineHeight: "1.2em",
                          },
                        }}
                      >
                        {feature.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #ddd" }}
                      >
                        {feature.lite ? (
                          <CheckIcon
                            sx={{ color: "green.main", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CloseIcon
                            sx={{ color: "secondary.main", fontSize: "1.2em" }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #ddd" }}
                      >
                        {feature.plus ? (
                          <CheckIcon
                            sx={{ color: "green.main", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CloseIcon
                            sx={{ color: "secondary.main", fontSize: "1.2em" }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #ddd" }}
                      >
                        {feature.pro ? (
                          <CheckIcon
                            sx={{ color: "green.main", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CloseIcon
                            sx={{ color: "secondary.main", fontSize: "1.2em" }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box sx={{ padding: 3 }}>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontSize: "1.4em",
                fontWeight: "700",
                color: "secondary.main",
              }}
            >
              Compare Plans
            </Typography>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontSize: "1.2em",
                fontWeight: "500",
                color: "#106eed",
              }}
            >
              Empower your solutions with curated visibility, efficient lead
              management, and streamlined collaboration tools.
              <br /> Please find the plan that fits your business needs and
              unlock new opportunities.
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "#ecf6ff",
                border: "1px solid #001a8c",
                borderRadius: "10px",
                mt: 2,
                transition: "all 0.5s ease-in-out 0s",
                "&:hover": {
                  background: "#ffffff",
                  boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                },
              }}
            >
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Features
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Lite
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Plus
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize: "1.2em",
                        lineHeight: "1.2em",
                        color: "secondary.main",
                      }}
                    >
                      Pro
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessUserFeatures.map((feature, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          border: "1px solid #ddd",
                          fontWeight: 600,
                          border: "1px solid #ddd",
                          width: "25%",
                          fontSize: "0.9em",
                          lineHeight: "1.2em",
                          color: "secondary.main",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7em",
                            lineHeight: "1.2em",
                          },
                        }}
                      >
                        {feature.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #ddd" }}
                      >
                        {feature.lite ? (
                          <CheckIcon
                            sx={{ color: "green.main", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CloseIcon
                            sx={{ color: "secondary.main", fontSize: "1.2em" }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #ddd" }}
                      >
                        {feature.plus ? (
                          <CheckIcon
                            sx={{ color: "green.main", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CloseIcon
                            sx={{ color: "secondary.main", fontSize: "1.2em" }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid #ddd" }}
                      >
                        {feature.pro ? (
                          <CheckIcon
                            sx={{ color: "green.main", fontSize: "1.2em" }}
                          />
                        ) : (
                          <CloseIcon
                            sx={{ color: "secondary.main", fontSize: "1.2em" }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )} */}

        {/* Invoice History Section */}
        {/* <Box style={{ marginTop: "2rem" }}>
          <Typography
            variant="h6"
            align="center" 
            gutterBottom
            sx={{
              fontSize: "1.3rem",
              fontWeight: "600",
              color: "secondary.main",
            }}
          >
            Invoice History
          </Typography>
          <TableContainer sx={{ mt: 3 }} component={Paper}>
            <Table>
              <TableHead sx={{ bgcolor: "secondary.main" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>Date</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Plan</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Amount</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.date}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.plan}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.amount}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box> */}

        {/* FAQ Section */}
        <Box
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
          style={{ padding: "22px" }}
        >
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{
              fontSize: "1.4rem",
              fontWeight: "700",
              color: "secondary.main",
              mb: 2,
            }}
          >
            Frequently Asked Questions
          </Typography>
          {/* Question1 */}
          <Accordion
            sx={{
              mt: 3,
              borderRadius: "15px",
              overflow: "hidden",
              border: "1px solid #001a8c",
              "&:first-of-type": {
                marginTop: 0,
                borderRadius: "10px 10px 10px 10px", // Adjust for specific border-radius
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                What is the difference between LITE, PLUS, and PRO plans?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                The LITE plan is free and ideal for smaller teams looking to
                test the platform with limited features. PLUS and PRO plans
                offer advanced features like increased lead management, project
                collaboration tools, and enhanced visibility with featured
                listings.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question2 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                {" "}
                Can I upgrade or downgrade my plan at any time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                Yes, you can easily upgrade or downgrade your plan based on your
                business needs. Upgrades are immediate, while downgrades will
                take effect at the end of the current billing cycle.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question3 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                How does the featured listing work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                Featured listings improve your solution's visibility in
                searches. Silver and Gold tiers ensure prioritized placement,
                driving more inquiries and potential leads.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question4 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                Is there a free trial for the PLUS or PRO plans?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                Currently, we do not offer a free trial for the PLUS or PRO
                plans. However, the LITE plan allows you to explore the
                platform’s basic features for free..
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question5 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                {" "}
                What payment methods do you accept?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                We accept payments via credit/debit cards, and all transactions
                are securely processed through the Stripe payment gateway.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question6 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                How does BSI Co-lab help with lead management?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                BSI Co-lab simplifies lead management by allowing you to view
                and respond to inquiries, track the status of leads, and
                collaborate with potential business partners—all in one place.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question7 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                {" "}
                What happens to my data if I downgrade or cancel my plan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                Your data remains secure. If you downgrade or cancel, your
                stored files and information will still be accessible but
                subject to the limits of your new plan.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question8 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                {" "}
                Can I add more users to my account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                Yes, you can add users to your account based on the limits of
                your plan. The PRO plan supports up to 15 users, while PLUS
                allows up to 5.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question9 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                Is my data secure on BSDA?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                Absolutely! We use AWS cloud services to ensure the highest
                levels of security for all your documents, communication, and
                business data.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question10 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "15px",
              overflow: "hidden",
              borderRadius: "10px 10px 10px 10px",
              border: "1px solid #001a8c",
              "&:last-of-type": {
                marginTop: 2,
                borderRadius: "10px 10px 10px 10px", // Adjust for specific border-radius
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500", color: "secondary.main" }}>
                Who can I contact for support or further questions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: "400", fontSize: "0.9em", color: "#106eed" }}
              >
                You can reach out to our support team via email or through the
                contact form in the dashboard. Our team is happy to assist you
                with any questions or issues.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Modals
        open={openPaymentSuccess}
        handleClose={handleClosePaymentSuccessModal}
        modalWidth={450}
      >
        <PaymentSuccess handleClose={handleClosePaymentSuccessModal} />
      </Modals>
      {/* <Modals
        open={openStripePaymentModal}
        handleClose={handleCloseStripePaymentModal}
        modalWidth={550}
      >
        <StripePayment
          handleClose={handleCloseStripePaymentModal}
          newPlanName={newPlanName}
          showToastMessage={showToastMessage}
          handleOpenPaymentSuccessModal={handleOpenPaymentSuccessModal}
          handleClosePaymentSuccessModal={handleClosePaymentSuccessModal}
        />
      </Modals> */}
    </>
  );
};

export default MySubscription;
