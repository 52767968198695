import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Add, Create } from "@mui/icons-material";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { getImages } from "../const";

const NoRecentResponsesScreen = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const theme = useTheme();
  const handleRecentResponses = () => {
    navigate("/bsi-co-lab");
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100% - 164px)"
      textAlign="center"
      // padding={3}
    >
      <img
        src={getImages("no_responses.png")} // Replace with your image path
        // alt="No Data"
        style={{
          width:'150px',
          height:'130px',
          // marginBottom: "20px",
          background: "gray",
          borderRadius: 8,
          mixBlendMode: "multiply",
        }} // Adjust the size as needed
      />
      <Typography
      variant="h6"
        sx={{
          fontSize:'1rem',
          color: "secondary.main",
          fontWeight: 600,
        }}
        gutterBottom
      >
        No recent responses available.
      </Typography>
      {userType == "solution_provider" ? (
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize:'0.9rem',
            color: "lightSecondary.main",
            fontWeight: 500,
          }}
        >
          You can start by reviewing and accepting your first invitation to take
          your business to the next level !
        </Typography>
      ) : (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            fontSize:'0.9rem',
            color: "lightSecondary.main",
            fontWeight: 500,
          }}
        >
          You can start by inviting your first solution provider to propel your
          business forward!
        </Typography>
      )}
      {userType == "solution_provider" ? (
        <Button
          onClick={handleRecentResponses}
          variant="contained"
          sx={{
            textTransform: "inherit",
            mt: 1,
            py: "9px",
            boxShadow: "none",
            px: 5.5,
            "&:hover": {
              boxShadow: "none",
            },
            [theme.breakpoints.down("md")]: {
              px: 2.5,
              width: "100%",
            },
          }}
        >
          Review Invitations
        </Button>
      ) : (
        <Button
          onClick={handleRecentResponses}
          variant="contained"
          sx={{
            textTransform: "inherit",
            mt: 1,
            py: "9px",
            boxShadow: "none",
            px: 5.5,
            "&:hover": {
              boxShadow: "none",
            },
            [theme.breakpoints.down("md")]: {
              px: 2.5,
              width: "100%",
            },
          }}
        >
          Invite Solution Provider
        </Button>
      )}
    </Box>
  );
};

export default NoRecentResponsesScreen;
