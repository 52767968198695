import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { store } from '../../store/store';

const baseUrl = process.env.REACT_APP_base_URL;

export const inviteTeamMember = createApi({
  reducerPath: 'inviteTeamMember',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // const token = useSelector((state)=> state.user.token)
      const token = store.getState().user.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
  },
  }),
  endpoints: (builder) => ({
    sendMemberInvitation: builder.mutation({
      query: (body) => ({
        url: 'users-collaborator/send-member-invitation-for-business-user',
        method: 'POST',
        body: {
          email: body.email,
          first_name: body.first_name,
          last_name: body.last_name,
          id: body.id,
          requirement_id: body.requirement_id,
          solution_id: body.solution_id,
        },
      }),
      transformResponse: (response) => {
        return response;
        
      },
      onError: (error) => {
        console.error('Error sending member invitation:', error);
      },
    }),
  }),
});

export const { useSendMemberInvitationMutation } = inviteTeamMember;
export default inviteTeamMember;
