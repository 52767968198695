// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
milestoneTitle : "",
milestoneDescription: "",
milestoneStatus : "",
};

const createMilestoneSlice = createSlice({
  name: "createMilestone",
  initialState,
  reducers: {
   setMilestoneTitle(state , action ) {
   state.milestoneTitle = action.payload;
   },
   setMilestoneDescription(state , action ) {
   state.milestoneDescription = action.payload;
   },
   setMilestoneStatus(state , action ) {
   state.milestoneStatus = action.payload;
   },
   resetCreateMilestoneState(state) {
    Object.assign(state, initialState);
  },
  }
});

export const {
  setMilestoneTitle,
  setMilestoneDescription,
  setMilestoneStatus,
  resetCreateMilestoneState,
} = createMilestoneSlice.actions;
export default createMilestoneSlice.reducer;
