import React from "react";
import { Box, Stack, Typography, Skeleton, Grid } from "@mui/material";
import { useSelector } from "react-redux";

const OverViewSkull = () => {
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  return (
    <Box
      sx={{
        backgroundColor: "#ECF6FF",
        borderRadius: "10px",
        // padding: "15px 25px",
        // minHeight: "420px",
      }}
    >
      <Stack spacing={2} direction="row" justifyContent="space-between" >
        <Skeleton
          variant="text"
          width={120}
          height={30}
          sx={{
             bgcolor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={100}
          height={30}
          sx={{
             bgcolor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "5px",
          }}
        />
      </Stack>
      <Box
        sx={{
          textAlign: "center",
          mt: 3,
        }}
      >
        <Skeleton
          variant="circular"
          width={160}
          height={160}
          sx={{
             bgcolor: "rgba(0, 0, 0, 0.05)",
            margin: "0 auto",
          }}
        />

      </Box>
      <Stack spacing={1} mt={3} sx={{}}>
      <Grid container spacing={2}>
        {Array.from({ length: 5 }).map((_, index) => (
            <Grid item xs={12} md={12} lg={isDrawerOpen? 12 :6} xl={6} key={index}>
          <Box key={index} 
           sx={{
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            margin: "0 auto",
            "@media (max-width:1200px) and (min-width:450px)": {
                  justifyContent:'space-between',
                 },
          }}
          >
            <Skeleton
              variant="rectangular"
              width={10}
              height={10}
              sx={{
                bgcolor: "rgba(0, 0, 0, 0.05)",
                borderRadius: "5px",
                marginRight: "10px",
                
              }}
            />
            <Skeleton
              variant="text"
              width={120}
              height={20}
              sx={{
                 bgcolor: "rgba(0, 0, 0, 0.05)",
              }}
            />
          </Box>
          </Grid>
        ))}
          </Grid>
      </Stack>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        sx={{
           bgcolor: "rgba(0, 0, 0, 0.05)",
          borderRadius: "5px",
          mt: 2,
        }}
      />
    </Box>
  );
};

export default OverViewSkull;
