import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  ListItemButton,
  List,
  ListItemAvatar,
  Stack,
  Skeleton,
} from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import { useMoveDocumentMutation } from "../../redux/api/BsiDrive/moveFolder";
import { useDispatch, useSelector } from "react-redux";
import { useFetchDriveItemListQuery } from "../../redux/api/BsiDrive/getDriveFolderList";
import {
    resetRefetchDocument,
  setRefetchDocument,
  setRefetchSubFolder,
} from "../../redux/features/refetch";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getImages } from "../const";
import { useTheme } from "@emotion/react";

const MoveModal = ({
  documentId,
  documentName,
  handleClose,
  showToastMessage,
}) => {
  const userId = localStorage.getItem("userId");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [btnActive, setBtnActive] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [isMoving, setIsMoving] = useState(false);
  const [parentId, setParentId] = useState("");
  const [folderName, setFolderName] = useState("");
  const [history, setHistory] = useState([{ id: parentId, name: folderName }]);
  const {
    data: driveItemData,
    error: isDriveFolderError,
    isLoading: isDriveFolderLoading,
    refetch,
  } = useFetchDriveItemListQuery({
    userId: userId,
    parentId: parentId,
    searchParams: "",
    sortOption: "",
  });


  const [moveDriveFolder, { isLoading, isError, error }] =
    useMoveDocumentMutation();
  const handleFolderClick = (folderId) => {
    setSelectedFolderId(folderId);
    setBtnActive(true);
  };

  const handleFolderOpen = (parentId, folderName) => {
    setParentId(parentId);
    setFolderName(folderName);
    setHistory((prev) => [...prev, { id: parentId, name: folderName }]);
  };
  const handlePreviousClick = (item, index) => {
    setHistory((prev) => prev.slice(0, index + 1));
    setParentId(item.id);
    refetch();
  };

  const handleMoveItem = async () => {
    setIsMoving(true);
    try {
      let id = documentId;
      const response = await moveDriveFolder({
        movedFolderId: id,
        selectedFolderId: selectedFolderId,
        userId: userId,
      }).unwrap();
      const successMessage = response?.message || "Item moved";
      showToastMessage(successMessage, "success");
      handleClose();
      dispatch(setRefetchDocument(true));
      dispatch(setRefetchDocument(true));
      dispatch(setRefetchSubFolder(true));
    } catch (error) {
      const errorMessage = error?.message || "Failed to move item";
      showToastMessage(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (isDriveFolderLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isDriveFolderLoading, dispatch]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.3rem",
            color: "lightSecondary.main",
            fontWeight: "700",
            maxWidth: "100%", // Set maximum width to the width of the modal
            whiteSpace: "nowrap", // Prevents text from wrapping onto a new line
            overflow: "hidden", // Hides any overflowed text
            textOverflow: "ellipsis", // Adds "..." at the end of the truncated text
          }}
        >
          Move {documentName}
        </Typography>
      </Box>
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "0.9rem",
          color: "secondary.main",
          fontWeight: "700",
          flexWrap: "wrap",
        }}
      >
        {history.length > 0 && (
          <Link
            to="/bsi-drive"
            style={{
              textDecoration: "none",
              color: theme.palette.secondary.main, // Use the theme color
            }}
            className="ud-link"
            onClick={(e) => {
              handlePreviousClick({ id: "", name: "All Folders" }, 0);
            }}
          >
            All Folders
          </Link>
        )}
        {history.map((item, pos) => {
          const isCurrent = pos === history.length - 1; // Check if it's the current folder
          return (
            <React.Fragment key={item.id}>
              {pos > 0 && <KeyboardArrowRight />}
              <Typography
                style={{
                  textDecoration: "none",
                  color: isCurrent
                    ? "secondary.main" // Highlight color for the current folder
                    : "secondary.main", // Regular color
                  fontWeight: isCurrent ? 500 : 700, // Optional: Bold text for current folder
                }}
                className={isCurrent ? "ud-link-current" : "ud-link"} // Optional: Apply a class for custom styling
                onClick={(e) => !isCurrent && handlePreviousClick(item, pos)}
              >
                {item.name}
              </Typography>
            </React.Fragment>
          );
        })}
      </Typography>
      {/* <ListItemButton
                sx={{
                    fontSize: "0.95rem",
                    color: "primary.main",
                    fontWeight: "600",
                    px: 0
                }}
            >
                <CreateNewFolderIcon
                    sx={{
                        fontSize: '28px',
                        verticalAlign: 'middle',
                        mr: 1.3,
                    }}
                /> New Folder
            </ListItemButton> */}
      <List
        component="nav"
        sx={{
          px: 0,
          py: 0,
          mt: "10px",
          "& .MuiListItemButton-root": {
            py: 1.5,
          },
          maxHeight: "180px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
        }}
      >
        {driveItemData && driveItemData?.data?.folders?.length > 0 ? (
          driveItemData.data.folders
            .filter(
              (folder) =>
                folder.isDocument === true && folder._id !== documentId
            ) // Filter out items that are not documents
            .map((folder, index) => (
              <ListItemButton
                key={folder._id}
                selected={selectedFolderId === folder._id}
                onClick={() => handleFolderClick(folder._id)}
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  background:
                    selectedFolderId === folder._id
                      ? "lightPrimary.main"
                      : "#FFFFFF",
                  borderRadius: "10px",

                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&:hover .MuiSvgIcon-root": {
                    display: "block",
                  },
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "inherit",
                    alignItems: "center",
                  }}
                >
                  <FolderIcon
                    sx={{
                      fontSize: "26px",
                      color: "lightSecondary.main",
                      mr: 1.5,
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontSize: "0.95rem",
                      color: "lightSecondary.main",
                      fontWeight: "600",
                      lineHeight: "20px",
                    }}
                  >
                    {folder.name}
                  </Typography>
                </Stack>
                <ArrowForwardIosOutlinedIcon
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent ListItemButton click
                    handleFolderOpen(folder._id, folder.name); // Separate function for arrow click
                  }}
                  sx={{
                    color: "lightSecondary.main",
                    width: "18px",
                    height: "18px",
                    display: "none",
                  }}
                />
              </ListItemButton>
            ))
        ) : (
            isDriveFolderLoading ? (
                // Show Skeletons while loading
                Array.from({ length: 3 }).map((_, index) => (
                  <ListItemButton
                    key={index}
                    disabled
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "10px",
                      background: "#f5f5f5", // Light gray background for skeleton
                      marginBottom: "8px",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "inherit",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Skeleton
                        variant="circular"
                        width={26}
                        height={26}
                        sx={{ mr: 1.5 }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "0.95rem", width: "70%" }}
                      />
                    </Stack>
                  </ListItemButton>
                ))
              ) :
              ( 
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100% - 164px)"
            textAlign="center"
            // padding={3}
            sx={{
              mt: 2,
            }}
          >
            <img
              src={getImages("EmptyFolder.png")} // Replace with your image path
              // alt="No Data"
              style={{
                width: "150px",
                height: "130px",
                // marginBottom: "20px",
                mixBlendMode: "multiply",
              }} // Adjust the size as needed
            />
            <Typography
              variant="h6"
              sx={{
                fontSize: "0.9rem",
                color: "secondary.main",
                fontWeight: 600,
              }}
              gutterBottom
            >
              This folder is empty
            </Typography>
          </Box>
              )
        )}
      </List>
      <Box
        sx={{
          mt: 3,
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "inherit",
            p: "12px 25px",
            width: "50%",
            boxShadow: "none",
            fontWeight: "600",
            backgroundColor: btnActive ? "primary.main" : "extraLTheme.main",
            pointerEvents: btnActive ? "normal" : "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
          onClick={handleMoveItem}
          disabled={isMoving}
        >
          Move Here
        </Button>
      </Box>
    </Box>
  );
};

export default MoveModal;
