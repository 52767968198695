import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { store } from '../../store/store';


const baseUrl = process.env.REACT_APP_base_URL;

export const teamMemberList  = createApi({
    reducerPath: 'teamMemberList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchTeamMemberList: builder.query({
        query: ({ searchParams}) => ({
          url: `users-collaborator/team-members?search=${searchParams}`,
          method: 'GET',
        }),
        transformResponse: (response) => {

          if(response?.data?.length > 0){
            return response.data.map(savedData => ({
              _id:savedData?._id,
              user_id:savedData?.invite_to?._id,
              first_name: savedData?.invite_to?.first_name || "",
              last_name: savedData?.invite_to?.last_name|| "",
              avatar: savedData?.invite_to?.avatar || "",
              email: savedData?.invite_to?.email|| "",
              isAdmin: savedData?.isAdmin || false,
            }));
          }else{
            return  response = [];
          }
     
        },
        refetchOnWindowFocus: true,
        onError: (error) => {
          console.error('Fetch user details error:', error);
        },
      })
    })
})


export const { useFetchTeamMemberListQuery } = teamMemberList;
export default teamMemberList;